import Checklist from "components/Button/Checklist"
import { GlobalHeader } from "components/Datatables/GlobalHeader"
import DefaultInput from "components/Input/DefaultInput"

export default function FreeRadiusCredentialForm({
    radiusServerSecret, setRadiusServerSecret,
    radiusServerAuthPort, setRadiusServerAuthPort,
    radiusServerAccountingPort, setRadiusServerAccountingPort,
    passwordShow, setPasswordShow,
    header = 'FreeRadius Server Credential',
    radiusServerSecretLabel = 'FreeRadius Secret',
    radiusServerAuthPortLabel = 'FreeRadius Authentication Port',
    radiusServerAccountingPortLabel = 'FreeRadius Accounting Port',
    bgColor = 'bg-red-200'
}) {
    return (
        <div className={`${bgColor} mt-10 mb-10 shadow-sm`}>
          <div className="title">
            <GlobalHeader
                textSize = "text-md text-center"
                header = {header}
            />
          </div>
          <div className="body m-5">
            <DefaultInput
              label = {radiusServerSecretLabel}
              placeHolder = {radiusServerSecretLabel}
              value = {radiusServerSecret}
              onChange={setRadiusServerSecret}
              type={passwordShow ? "text" : "password"}
            />
            <Checklist
              text = 'Tampilkan Radius Server Secret'
              value={passwordShow}
              onChange={(e) => {setPasswordShow(e.target.checked)}}
            />
            <DefaultInput
                label= {radiusServerAuthPortLabel}
                placeHolder = {radiusServerAuthPortLabel}
                value = {radiusServerAuthPort}
                type="number"
                onChange={setRadiusServerAuthPort}
            />
            <DefaultInput
                label= {radiusServerAccountingPortLabel}
                placeHolder = {radiusServerAccountingPortLabel}
                value = {radiusServerAccountingPort}
                type="number"
                onChange={setRadiusServerAccountingPort}
            />
            <div className="py-3 px-5"></div>
          </div>
        </div>
    )
}