export const invoiceColumnsSuperUser = [
                        {
                          label: "Invoice ID",
                          key: "id",
                          maxWidth: 100
                        },
                        {
                          label: "Customer ID",
                          key: "customerId",
                           maxWidth: 150
                        },
                        {
                          label: "Customer",
                          key: "customer",
                          maxWidth: 300
                        },
                        {
                          label: "Mitra",
                          key: "owner",
                        },
                        {
                          label: "Periode",
                          key: "period",
                          maxWidth: 150
                        },
                        {
                          label: "Status Invoice",
                          key: "invoiceStatus",
                          sortable: false,
                          filterable: false,
                        },
                        {
                          label: "Kirim Invoice",
                          key: "actionSend",
                          sortable: false,
                          filterable: false,
                        },
                        {
                          label: "Actions",
                          key: "actions",
                          sortable: false,
                          filterable: false,
                          maxWidth: 120
                        },
                      ]

export const invoiceColumnsUser = [
                        {
                          label: "Invoice ID",
                          key: "id",
                          maxWidth: 100
                        },
                        {
                          label: "Customer ID",
                          key: "customerId",
                           maxWidth: 150
                        },
                        {
                          label: "Customer",
                          key: "customer",
                          maxWidth: 300
                        },
                        {
                          label: "Owner",
                          key: "owner",
                        },
                        {
                          label: "Period",
                          key: "period",
                          maxWidth: 150
                        },
                        {
                          label: "Status Invoice",
                          key: "invoiceStatusUser",
                          sortable: false,
                          filterable: false,
                        },
                        {
                          label: "Kirim Invoice",
                          key: "actionSend",
                          sortable: false,
                          filterable: false,
                        },
                        {
                          label: "Actions",
                          key: "actions",
                          sortable: false,
                          filterable: false,
                          maxWidth: 120
                        },
                      ]