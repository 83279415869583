import React, { useEffect, useState } from "react";
import Navbar from "../../../components/Navbar/Index";
import { useNavigate, useOutletContext } from "react-router-dom";
import DefaultInput from "../../../components/Input/DefaultInput";
// import IconInput from "../../../components/Input/IconInput";
import SelecttWithUserTier from "../../../components/Input/SelectWithUserTier";
import { PriceFormat } from "data/Variable/Common";
import Checklist from "components/Button/Checklist";
import { calculatePriceDiscount } from "data/Variable/Utils";
import SelectBasic from "components/Input/SelectBasic";
import { payloadGenerateHostpotUser } from "data/Variable/HostpotVoucher/HostpotUser";
import swal from "sweetalert";
import { CatchError } from "data/Handler/Exceptions/exceptions";
import HostpotUserService from "data/Handler/HostpotVoucher/HostpotUserService";
import HostpotProfileService from "data/Handler/HostpotVoucher/HostpotProfileService";
import { verifySuperAdmin } from "data/Variable/Utils";
import UserService from "data/Handler/UserService";
// import { getRefreshToken } from "data/Variable/Common";
import RouterNasService from "data/Handler/RouterNasService";
import { getOwner } from "data/Variable/UserVariable";
import PrimaryIconButton from "components/Button/PrimaryIconButton";
import LoadingScreen from "components/Loader/LoadingScreen";

function HostpotUserGenerate(props) {
  const [sidebarToggle] = useOutletContext();
  const navigate = useNavigate()
  // const location = useLocation()
  // const { state } = location
  const hostpotUserService =  new HostpotUserService()
  const hostpotProfileService = new HostpotProfileService()
  const routerService = new RouterNasService()
  const { mitraSuper, organization, id: userId, userAdmin } = verifySuperAdmin()
  const userService = new UserService()
  const ownerData = getOwner()
  const [mitra, setMitra] = useState(ownerData.find(own => own.value === organization))
  const [loading, loadingOnChange] = useState(false)
  const [changeMitra, changeMitraOnChange] = useState(false)

  const [hostpotProfileId, setHostpotProfileId] = useState([])
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [discount, setDiscount] = useState(0)
  const [isPaid, setIsPaid] = useState(false)
  const [paidAmmount, setPaidAmmount] = useState(0)
  const [description, setDescription ] = useState('')
  const [resellerOption, setResellerOption] = useState([]) // getUserSelect())
  const [hostpotProfileOption, setHostpotProfileOption] = useState([])
  const [reseller, setReseller] = useState(resellerOption.find((data) => data.userId === userId))
  const [routerSelectOption, routerSelectOptionOnChange] = useState([])
  const [router, setRouter] = useState('')

  const [qty, setQty] = useState(1)
  const [prefix, setPrefix] = useState('')
  const [autogenUsername, setAutoGenUsername] = useState(true)
  const [autogenPass, setAutoGenPass] = useState(true)
  const [maxDigitUsername, setMaxDigitUsername] = useState(6)
  const [maxdigitPass, setMaxDigitPass] = useState(6)

  const getRouter = async(mitraid) => {
    const dat = await routerService.getRouterSelect(mitraid)
    const result = dat.data
    setReseller('')
    setHostpotProfileId('')
    routerSelectOptionOnChange(result)
    return result
  }

  async function GetReseller(mitra) {
    const resellerList = await userService.getUserSelect(mitra)
    const resellerData = resellerList.data
    console.log(resellerData)
    setReseller('')
    setResellerOption(resellerData)

    const defaultReseller = resellerData?.find((data) => data.userId === userId)
    const finalSelection = !defaultReseller ? resellerData[0] : defaultReseller
    setReseller(finalSelection)
    return finalSelection.userId
  }

  async function GetHostpotProfile(routerId) {
    const resellerList = await hostpotProfileService.getProfileSelect(routerId)
    const resellerData = resellerList.data

    setHostpotProfileOption(resellerData)
    return resellerData
  }

  useEffect(() => {
    getRouter(mitra?.mitraid)
    GetReseller(mitra?.mitraid)
  }, [])

  const submit = async(e) => {
    e.preventDefault()
    try {
      loadingOnChange(true)
      const payload = payloadGenerateHostpotUser(qty, prefix, autogenUsername, autogenPass, maxDigitUsername, maxdigitPass, mitra.mitraid, hostpotProfileId.value, reseller.userId, username, password, discount, isPaid, paidAmmount, description)

      const result = await hostpotUserService.generateVoucher(payload)
      await swal(result.status, result.message, "success")
      loadingOnChange(false)
      return navigate('/hostpot/user')
    } catch (error) {
      loadingOnChange(false)
      return await CatchError(error, props)
  }}

  const child = (
    <>
        {/* Main Content */}
        <div className="mainCard">
          <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
            {/* <form> */}
               <DefaultInput
                    key={"1"}
                    label = {'QTY Generate'}
                    value = {qty}
                    onChange = {(e) => {
                      if (e.target.value >= 0 && e.target.value <= 100) {
                        setQty(e.target.value)
                      }
                    }}
                    type = {'number'}
                  />
                  <DefaultInput
                    key={"2"}
                    label = {'Username'}
                    value = {username}
                    disabled = {autogenUsername}
                    onChange = {(e) => setUsername(e.target.value)}
                    type
                  />
                  <DefaultInput
                    key={"3"}
                    label = {'Jumlah Digit Username'}
                    value = {maxDigitUsername}
                    disabled = {!autogenUsername}
                    onChange = {(e) => {
                      if (e.target.value >= 6 && e.target.value <= 16) {
                        setMaxDigitUsername(e.target.value)
                        setMaxDigitPass(e.target.value)
                      }
                    }}
                    type = {'number'}
                  />
                  <Checklist
                    key={"4"}
                    text = {"Auto-Generate Username"}
                    value = {autogenUsername}
                    checked = {autogenUsername}
                    onChange = {(e) => {
                      setAutoGenUsername(e.target.checked)
                      setAutoGenPass(e.target.checked)
                    }}
                  />
                  <DefaultInput
                    key={"5"}
                    label = {'Password'}
                    value = {password}
                    disabled = {autogenPass}
                    onChange = {(e) => setPassword(e.target.value)}
                    type
                  />
                  <DefaultInput
                    key={"6"}
                    label = {'Jumlah Digit Password'}
                    value = {maxdigitPass}
                    disabled = {!autogenPass}
                    onChange = {(e) => {
                      if (e.target.value >= 6 && e.target.value <= 16) {
                        setMaxDigitPass(e.target.value)
                      }
                    }}
                    type = {'number'}
                  />
                  <Checklist
                    key={"7"}
                    text = {"Auto-Generate Password"}
                    value = {autogenPass}
                    checked = {autogenPass}
                    onChange = {(e) => {
                      setAutoGenPass(e.target.checked)
                    }}
                  />
                  <SelectBasic
                    key={"8"}
                    label = {'Prefix Username dan Password'}
                    value = {prefix}
                    disabled = {!autogenUsername && !autogenPass}
                    onChange = {(e) => {
                      setPrefix(e)
                    }}
                    option = {hostpotProfileOption}
                  />
                  <SelecttWithUserTier
                    key={"9"}
                    label = {'Mitra'}
                    value = {mitra}
                    onChange = {setMitra}
                    disabled = {changeMitra}
                    subFunction = {async (e) => {
                      routerSelectOptionOnChange([])
                      setResellerOption([])
                      setHostpotProfileOption([])
                      setReseller('')
                      setHostpotProfileId('')
                      setRouter('')
                      await getRouter(e?.mitraid)
                      await GetReseller(e?.mitraid)
                    }}
                    options = {ownerData}
                    checkText = {'Change Mitra'}
                    checkValue = {changeMitra}
                    checkValueOnChange = {changeMitraOnChange}
                  />
                  <div>
                    <SelectBasic
                      label="Lokasi Router"
                      name="singleSelect"
                      value = {router}
                      option={routerSelectOption}
                      onChange={ async (e) => {
                          setRouter(e)
                          await GetHostpotProfile(e?.value)
                        }
                      }
                    />
                    {!router || !router.status ? (
                      <label className="error">
                        Router Offline
                      </label>
                    ) : <label className="success">
                        Router Online
                      </label>}
                  </div>
                  <SelectBasic
                    key={"10"}
                    label = {'Hostpot Profile'}
                    value = {hostpotProfileId}
                    onChange = {(e) => {
                      setHostpotProfileId(e)
                    }}
                    option = {hostpotProfileOption}
                  />
                  <SelectBasic
                    key={"11"}
                    label = {"Reseller"}
                    value = {reseller}
                    option = {resellerOption}
                    isDisabled = {!mitraSuper && !userAdmin}
                    onChange = {async (e) => {
                      setReseller(e)
                    }}
                  />
                  <DefaultInput
                    key={"12"}
                    label = {'Discount'}
                    value = {discount}
                    onChange = {(e) => {
                      if (e.target.value >= 0 && e.target.value <= 100) {
                        setDiscount(e.target.value)
                        setPaidAmmount(calculatePriceDiscount(hostpotProfileId.price, e.target.value))
                      }
                    }}
                    type = {'number'}
                  />
                  {!mitraSuper ? null : (
                  <Checklist
                    key={"13"}
                    text = {"Lunas"}
                    value = {isPaid}
                    onChange = {(e) => {
                      setIsPaid(e.target.checked)
                      if (e.target.checked === true && !!hostpotProfileId) {
                        setPaidAmmount(calculatePriceDiscount(hostpotProfileId.price, discount))
                      } else {
                        setPaidAmmount(0)
                      }
                    }}
                  />)
                  }
                  <DefaultInput
                    key={"14"}
                    label = {'Nilai Bayar'}
                    value = {paidAmmount}
                    onChange = {(e) => setPaidAmmount(e.target.value)}
                    disabled = {!isPaid}
                    type = {'number'}
                  />
                  <DefaultInput
                    key={"15"}
                    label = {'Price Display'}
                    value = {PriceFormat(paidAmmount, 'idr')}
                    onChange={() => {}}
                  />
                  <DefaultInput
                    key={"16"}
                    label = {'Deskripsi'}
                    value = {description}
                    onChange = {(e) => setDescription(e.target.value)}
                    type = {'textarea'}
                  />
              <div className="mt-6 flex flex-row gap-4">
                <PrimaryIconButton
                  title="Generate"
                  onClick = {submit}
                />
              </div>
            {/* </form> */}
          </div>
        </div>
    </>
  )
  return (
    <>
      <main className="h-full">
        <Navbar toggle={sidebarToggle} />
        <LoadingScreen
          // key = {RandomTextGenerator()}
          children={child}
          loading = {loading}
          text={"Saving..."}
        />
      </main>
    </>
  );
}

export default HostpotUserGenerate;
