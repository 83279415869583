import swal from "sweetalert";
import React, { useState } from "react";
import Navbar from "../../components/Navbar/Index";
import { useNavigate, useOutletContext } from "react-router-dom";
import { verifyLength } from "data/Variable/Utils";
import Checklist from "components/Button/Checklist";
import { CatchError } from "data/Handler/Exceptions/exceptions";
import { getUserData } from "data/Variable/UserVariable";
import UserService from "data/Handler/UserService";
import { PriceFormat } from "data/Variable/Common";
import { getUserType } from "data/Variable/UserVariable";
import { addMitraList } from "data/Variable/UserVariable";
import { Mitra } from "data/Variable/UserVariable";
import DefaultInput from "components/Input/DefaultInput";
import DateIncrementInput from "components/Input/DateIncrementInput";
import InputCoordinate from "components/Input/InputCoordinate";
import { getProvince } from "data/Variable/Common";
import SelectBasic from "components/Input/SelectBasic";
import PrimaryIconButton from "components/Button/PrimaryIconButton";
import LoadingScreen from "components/Loader/LoadingScreen";
import FreeRadiusDatabaseForm from "components/Other/FreeRadiusForm/FreeRadiusDatabaseForm";
import { verifyNetworkPortRange } from "data/Variable/Utils";
import { GlobalHeader } from "components/Datatables/GlobalHeader";

function ResellerForm(props) {
  const [sidebarToggle] = useOutletContext();
  const currency = 'idr'
  const navigate = useNavigate()
  const userService = new UserService()
  const userData = getUserData()
  const [minLengthState, setminLengthState] = React.useState("");
  const [minLengthNpwpState, setminLengthNpwpState] = React.useState("");
  const [minLengthPhoneState, setminLengthPhoneState] = React.useState("");
  // const [minLengthPhone, setminLengthPhone] = React.useState("");
  // const [minLengthNPWP, setminLengthNPWP] = React.useState("");
  const owner = userData.organization
  const [name, setName] = useState('')
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [ktpNumber, ktpNumberOnChange] = useState('')
  const [MobileNo, mobileNoOnChange] = useState('')
  const [enable, enableOnChange] = useState(false)
  const [npwp, npwpOnChange] = useState('')
  const [Type, TypeOnChange] = useState({})
  const [due, dueOnChange] = useState()
  const [ppn, ppnOnChange] = useState(11)
  const [province, setProvince] = useState('')
  const provinceOption = getProvince()
  const [beforeDue, beforeDueOnChange] = useState()
  const TypeOptions = getUserType()
  const optionsFilter = TypeOptions.filter((type) => type.value !== 'SUPERADMIN')
  const [administrationFee, setAdministrationFee] = useState(0)
  const [latitude, setLatitude] = useState(0.0)
  const [longitude, setLongitude] = useState(0.0)
  const [instalationFee, setInstalationFee] = useState(0)
  const [memberFee, setMemberFee] = useState(0)
  const [mysqlHost, setMysqlHost] = useState('')
  const [mysqlPort, setMysqlPort] = useState(3306)
  const [mysqlDatabase, setMysqlDatabase] = useState('')
  const [mysqlUsername, setMysqlUsername] = useState('')
  const [mysqlPassword, setMysqlPassword] = useState('')
  const [passwordShow, setPasswordShow] = useState(false)

  const getMitra =  async(len = 'ALL') => {
    try {
      const dat =  await userService.getAllMitra(len)
      const result = dat.data
        addMitraList(result)
      return result
    } catch (error) {
      return await CatchError(error, props)
    }
  }

  const submitMitra = async() => {
    try {
      setLoading(true)
      const payload = Mitra(name, email, firstname, lastname,
                    MobileNo, ktpNumber, npwp, enable, Type.value, due, ppn,
                    beforeDue, administrationFee, province.value, latitude,
                    longitude, instalationFee, memberFee, mysqlHost,
                    mysqlDatabase, mysqlPort, mysqlUsername, mysqlPassword)

      const result = await userService.addMitra(payload)
      await swal("success", result.message, result.status)
      await getMitra()
      setLoading(false)
      navigate('/reseller')
    } catch (error) {
      setLoading(false)
      return await CatchError(error, props)
  }}

  const child = (
    <>
      {/* Main Content */}
        <div className="mainCard">
          <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
            {/* <form> */}
              <GlobalHeader
                header = {'Tambah Reseller'}
              />
                <SelectBasic
                  key = {1}
                  label = {'Provinsi *'}
                  value = {province}
                  onChange = {setProvince}
                  option = {provinceOption}
                />
                <InputCoordinate
                  // key = {2}
                  latitude = {latitude}
                  setLatitude = {setLatitude}
                  longitude = {longitude}
                  setLongitude = {setLongitude}
                />
                <DefaultInput
                  label="Nama *"
                  value = {name}
                  onChange = {(e) => setName(e.target.value)}
                  type="text"
                />
                <DefaultInput
                  label="Created By"
                  value = {owner}
                  disabled
                  onChange = {() => {}}
                  type="text"
                />
                <DefaultInput
                  label="Nama Depan"
                  value = {firstname}
                  onChange = {(e) => setFirstname(e.target.value)}
                  type="text"
                />
                <DefaultInput
                  label="Nama Belakang"
                  value = {lastname}
                  onChange = {(e) => setLastname(e.target.value)}
                  type="text"
                />
                <DefaultInput
                  label="Nama Lengkap"
                  value = {`${firstname} ${lastname}`}
                  type="text"
                />
                <div>
                <DefaultInput
                  label="Email *"
                  name="email"
                  type="email"
                  value = {email}
                  onChange = {(e) => setEmail(e.target.value)}
                />
                <DefaultInput
                  name="min_length_ktp"
                  type="number"
                  label="KTP Number *"
                  max={16}
                  value = {ktpNumber}
                  onChange={(e) => {
                    if (!verifyLength(e.target.value, 17)) {
                      ktpNumberOnChange(e.target.value)
                    if (!verifyLength(e.target.value, 16)) {
                      setminLengthState("has-danger");
                    } else {
                      setminLengthState("has-success");
                    }
                    }
                  }}
                />
                     {minLengthState === "has-danger" ? (
                       <label className="error">
                         KTP harus 16 Karakter.
                       </label>
                     ) : null}
                    </div>
                    <DefaultInput
                      name="min_length_ktp"
                      type="number"
                      label="NPWP"
                      max={16}
                      value = {npwp}
                      onChange={(e) => {
                        if (!verifyLength(e.target.value, 16)) {
                          npwpOnChange(e.target.value)
                          if (!verifyLength(e.target.value, 15)) {
                            setminLengthNpwpState("has-danger");
                          } else {
                            setminLengthNpwpState("has-success");
                          }
                        }
                        // setminLengthNPWP(e.target.value);
                      }}
                    />
                    {minLengthNpwpState === "has-danger" ? (
                      <label className="error">
                        NPWP Harus 15 Karakter.
                      </label>
                    ) : null}
                    <div>
                    <DefaultInput
                      name="min_length_phone"
                      type="number"
                      label="Nomor Telepon *"
                      max={12}
                      value={MobileNo}
                      onChange={(e) => {
                        if (!verifyLength(e.target.value, 13)) {
                          mobileNoOnChange(e.target.value)
                        if (!verifyLength(e.target.value, 11)) {
                          setminLengthPhoneState("has-danger");
                        } else {
                          setminLengthPhoneState("has-success");
                        }
                      }      // setminLengthPhone(e.target.value);
                      }}
                        />
                        {minLengthPhoneState === "has-danger" ? (
                          <label className="error">
                            Nomor Telepon Minimal 8 Karakter dan Maksimal 13 Karakter.
                          </label>
                        ) : null}
                       </div>
                        <SelectBasic
                            label="Type *"
                            name="singleSelect"
                            value = {Type}
                            option = {optionsFilter}
                            onChange={e => TypeOnChange(e)}
                          />
                          <DateIncrementInput
                            key = {3}
                            labelInput = {"Jatuh Tempo (MIN -30 MAX 120 Day)"}
                            labelOutput = {'Tanggal Tertampil'}
                            value = {due}
                            onChange={(e) => {
                              if (e.target.value >= -30 && e.target.value <= 120) {
                                dueOnChange(e.target.value)
                              }
                            }}
                          />
                          <DateIncrementInput
                            key = {4}
                            labelInput = {"Notifikasi Sebelum Jatuh Tempo (MAX 30 Day)"}
                            labelOutput = {'Tanggal Tertampil'}
                            value = {beforeDue}
                            dueDisplayValue = {due}
                            decr = {true}
                            onChange={(e) => {
                              if (e.target.value >= 0 && e.target.value <= 30) {
                                beforeDueOnChange(e.target.value)
                              }
                            }}
                          />
                          <DefaultInput
                            value = {ppn}
                            label="PPN (Tax Percentage)"
                            type="number"
                            onChange={(e) => {
                              if (e.target.value <= 100) {
                                ppnOnChange(e.target.value)
                              }
                            }}
                          />
                          <FreeRadiusDatabaseForm
                            mysqlHost = {mysqlHost}
                            setMysqlHost = {(e) => {
                              setMysqlHost(e.target.value)
                            }}
                            mysqlPort = {mysqlPort}
                            setMysqlPort = {(e) => {
                              if (verifyNetworkPortRange(e)) {
                                setMysqlPort(e.target.value)
                              }
                            }}
                            mysqlDatabase = {mysqlDatabase}
                            setMysqlDatabase = {(e) => {
                              setMysqlDatabase(e.target.value)
                            }}
                            mysqlUsername = {mysqlUsername}
                            setMysqlUsername = {(e) => {
                              setMysqlUsername(e.target.value)
                            }}
                            mysqlPassword = {mysqlPassword}
                            setMysqlPassword = {(e) => {
                              setMysqlPassword(e.target.value)
                            }}
                            passwordShow = {passwordShow}
                            setPasswordShow = {setPasswordShow}
                            header = {'Default Radius Mysql Connection'}
                            mysqlHostLabel = {'Default Radius MSQL Database Host'}
                            mysqlPortLabel = {'Radius MSQL Database Port'}
                            mysqlDatabaseNameLabel = {'Radius MSQL Database Name'}
                            mysqlDatabaseUsernameLabel = {'Radius MSQL Database Username'}
                            mysqlDatabasePasswordLabel = {'Radius MSQL Database Password'}
                            bgColor = {'bg-amber-200'}
                          />
                          <div className="row">
                            <div className="col md-6">
                              <DefaultInput
                                key = {5}
                                label = {'Biaya Admin'}
                                value = {administrationFee}
                                type = {"number"}
                                onChange = {(e) => setAdministrationFee(e.target.value)}
                              />
                            </div>
                            <div className="col md-6">
                              <DefaultInput
                                key = {6}
                                value = {PriceFormat(administrationFee, currency)}
                                onChange = {()=>{}}
                                disabled
                                label="Harga Tertampil"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col md-6">
                              <DefaultInput
                                key = {7}
                                label = {'Biaya Instalasi Jaringan'}
                                value = {instalationFee}
                                type = {"number"}
                                onChange = {(e) => setInstalationFee(e.target.value)}
                              />
                            </div>
                            <div className="col md-6">
                              <DefaultInput
                                key = {8}
                                value = {PriceFormat(instalationFee, currency)}
                                onChange = {()=>{}}
                                disabled
                                label={"Harga Tertampil"}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col md-6">
                              <DefaultInput
                                key = {9}
                                label = {'Biaya Member Reseller'}
                                value = {memberFee}
                                type = {"number"}
                                onChange = {(e) => setMemberFee(e.target.value)}
                              />
                            </div>
                            <div className="col md-6">
                              <DefaultInput
                                key = {10}
                                value = {PriceFormat(memberFee, currency)}
                                onChange = {()=>{}}
                                disabled
                                label = {"Harga Tertampil"}
                              />
                            </div>
                          </div>
                          <Checklist
                              text = {'Activate Mitra'}
                              value = {enable}
                              onChange = {e => enableOnChange(e.target.checked)}
                          />
              <div className="mt-6 flex flex-row gap-4">
                <PrimaryIconButton
                  title="Save"
                  onClick = {submitMitra}
                />
              </div>
            {/* </form> */}
          </div>
        </div>
    </>
  )
  return (
    <>
      <main className="h-full">
        <Navbar toggle={sidebarToggle} />
        <LoadingScreen
          // key = {RandomTextGenerator()}
          children={child}
          loading = {loading}
          text={"Saving..."}
        />
      </main>
    </>
  );
}

export default ResellerForm;
