import swal from "sweetalert"
import UserService from "./../UserService"
import { getRefreshToken } from "data/Variable/Common"
import { removeUserSession } from "data/Variable/Common"
const userService = new UserService()

 export const CatchError = async (error, props) => {
  const refreshtoken = getRefreshToken()
  const response =  error.response
  if (!response) {
    swal("Error", `Unxepected Error\n\n ${error.message}`,"warning" )
  } else {
    if (response.status === 401 && refreshtoken) {
        try {
          await userService.refreshUser()
        } catch (error) {
          console.log(error.message)
          await removeUserSession()
          props.history.push('/auth/login')
        }
    } else if (response.status === 401 && !refreshtoken) {
        props.history.push('/auth/')
    } else if(response.status === 403){
      props.history.push('/admin/forbidden')
    } else if(response.status === 500){
      props.history.push('/admin/server-error')
    } else if(response.status === 400 || response.status === 404 || response.status === 422){
      swal(`${response.status} ${response.statusText}`, `${response.data.message}`,"warning" )
    } else {
      swal("Error", `Unxepected Error\n\n ${error.message}`,"warning" )
  }
  }
}

export const CatchErrorFlushData = async (error, props) => {
  const refreshtoken = getRefreshToken()
  const response =  error.response
  if (!response) {
    await swal("Error", `Unxepected Error\n\n ${error.message}`,"warning" )
  } else {
    if (response.status === 401 && refreshtoken) {
        try {
          await userService.refreshUser(refreshtoken)
        } catch (error) {
          await swal('Warning', error.message, 'warning')
          props.history.push('/auth/')
        }
    } else if (response.status === 401 && !refreshtoken) {
      props.history.push('/auth/')
    } else if(response.status === 403){
      props.history.push('/forbidden')
    } else if(response.status === 500){
      props.history.push('/server-error')
    } else if(response.status === 400 || response.status === 404 || response.status === 422){
      const option = await swal(`${response.status} ${response.statusText}`, `${response.data.message}`,"warning", {
            buttons: {
            cancel: 'Kembali',
            delete: {text: "Flush Data", value: 'del' }
          }
        }
      )
      switch (option) {
        case 'del':
          window.location.reload()
          break
        default:
          break
      }
    } else {
      await swal("Error", `Unxepected Error\n\n ${error.message}`,"warning" )
    }
  }
}

  export const CatchLoginError = async (error) => {
    const response =  error.response
    if (!response) {
      await swal("Error", `Server Offline \n\n ${error.message}`,"warning" )
    } else {
      if (!response.status) {
        await swal("Error", `Unxepected Error\n\n ${error.message}`,"warning" )
      } else {
        await swal(`${response.status} ${response.statusText}`, `${response.data.message}`,"warning" )
      }
    }
  }