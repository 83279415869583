import APIconsume from './ConsumeAPI'
import { decodeToken,
    getAccessToken,
    getRefreshToken,
    removeUserSession,
    setAccessToken, 
    setUserSession} from "./../../data/Variable/Common"
import AuthService from "./AuthService"
import { addUserData } from 'data/Variable/UserVariable'
import { addUserPermission } from 'data/Variable/UserVariable'


class UserService extends AuthService {
    constructor() {
        super()
        const accessToken = getAccessToken()
        this._url = 'User'
        this._path = 'auth/'
        this._consume = new APIconsume(accessToken)
    }

    async getUser(name) {
        if (!name) {
            const {username} = decodeToken(this.accessToken)
            const result = await this._consume.getData(`${this._url}/${username}`)
            return result.data
        }
        const result = await this._consume.getData(`${this._url}/${name}`)
        return result.data
    }

    async getUserSelect(mitra = '') {
        const result = await this._consume.getData(`${this._url}-select?mitra=${mitra}`)
        return result.data
    }

    async getAllUser(line = 'ALL', page) {
        let url = `${this._url}/view?line=${line}&page=${page}`
        if (!page) {
            url = `${this._url}/view?line=${line}`
        }
        const result = await this._consume.getData(url)
        return result.data
    }

    async getAllPermission(name) {
        const url = `${this._url}/Permission/view?user=${name}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async addUser(payload) {
        const url = this._url
        const result = await this._consume.postData(payload, url)
        return result.data
    }

    async addPermission(payload) {
        const url = `${this._url}/Permission`
        const result = await this._consume.postData(payload, url)
        return result.data
    }

    async putUser(name, payload) {
        const url = `${this._url}/${name}`
        const result = await this._consume.putData(payload, url)
        return result.data
    }

    async putPermission(id, payload) {
        const url = `${this._url}/Permission/${id}`
        const result = await this._consume.putData(payload, url)
        return result.data
    }

    async setUserStatus(id, payload) {
        const url = `${this._url}/Status/${id}`
        const result = await this._consume.patchData(payload, url)
        return result.data
    }

    async deleteUser(name) {
        const url = `${this._url}/${name}`
        const result = await this._consume.deleteData(url)
        return result.data
    }

    async deletePermission(name) {
        const url = `${this._url}/Permission/${name}`
        const result = await this._consume.deleteData(url)
        return result.data
    }


    async addMitra(payload) {
        const url = `${this._url}/Mitra`
        const result = await this._consume.postData(payload, url)
        return result.data
    }

    async getMitraData(name) {
        const url = `${this._url}/Mitra/${name}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getAllMitra(len = 'ALL', page) {
        let url = `${this._url}/Mitra/view?line=${len}&page=${page}`
        if (!page) {
            url = `${this._url}/Mitra/view?line=${len}`
        }
        const result = await this._consume.getData(url)
        return result.data
    }

    async getAllCustomerByMitra (name = '', len = 'ALL', trash = false, page = '') {
        let url = `Mitra/Customer/view?name=${name}&line=${len}&page=${page}&deleted=${trash}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async putMitra(id, payload) {
        const url = `${this._url}/Mitra/${id}`
        const result = await this._consume.putData(payload, url)
        return result.data
    }

    async setMitraStatus(id, payload) {
        const url = `${this._url}/Mitra/Status/${id}`
        const result = await this._consume.patchData(payload, url)
        return result.data
    }

    async setMitraInvoiceGenerate(id, payload) {
        const url = `${this._url}/Mitra/generate-time/${id}`
        const result = await this._consume.patchData(payload, url)
        return result.data
    }

    async deleteMitra(name) {
        const url = `${this._url}/Mitra/${name}`
        const result = await this._consume.deleteData(url)
        return result.data
    }

    async LogOut (refreshtoken) {
        const url = `${this._path}logout`
        const payload = {
            refreshToken: refreshtoken
        }
        const result = await this._consume.postData(payload, url)
        return result.data
    }

    async refreshUser (navigate, pathLogin = '/auth/login') {
        try {
            const refreshToken = getRefreshToken()
            const url = `${this._path}Refresh`
            const payload = { refreshToken }
            const result = await this._consume.putData(payload, url)
            const {accesstoken, user, permission } = result?.data?.data
            setUserSession(accesstoken, refreshToken, {id: user.id, username: user.username, email: user.email});
            addUserData(user)
            addUserPermission(permission)
            setAccessToken(accesstoken)
        } catch (error) {
            const response = error?.response
            console.log('refresh error', response)
            if (!!navigate && response?.status === 401) {
                removeUserSession()
                navigate(pathLogin)
                return error.message
            }
            return error.message
        }
    }

    async getDatalog(page = 1, limit = 100, method = '%', code = '%') {
        const url = `${this._url}/log?method=${method}&code=${code}&page=${page}&limit=${limit}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getDatalogParam() {
        const url = `res/log-param`
        const result = await this._consume.getData(url)
        return result.data
    }

    async deleteDatalog(id) {
        const url = `${this._url}/log/${id}`
        const result = await this._consume.deleteData(url)
        return result.data
    }
}

export default UserService