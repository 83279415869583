import React, { useEffect, useState } from "react";
import Navbar from "./../../../components/Navbar/Index";
import { useNavigate, useOutletContext } from "react-router-dom";
import { EditButton } from "components/Button/EditButton";
import { DeleteButton } from "components/Button/DeleteButton";
import MainTable from "components/Table/MainTable";
import PrimaryIconButton from "components/Button/PrimaryIconButton";
import { ColumnsAdmin, ColumnsTrashAdmin, ColumnsTrashUser, ColumnsUser } from "./HostpotProfileHeader";
import { RandomTextGenerator } from "data/Variable/Utils";
import HostpotProfileService from "data/Handler/HostpotVoucher/HostpotProfileService";
import swal from "sweetalert";
import { getUserPermission } from "data/Variable/UserVariable";
import { getOwner } from "data/Variable/UserVariable";
import { verifySuperAdmin } from "data/Variable/Utils";
import { CatchError } from "data/Handler/Exceptions/exceptions";
import { validatePermission } from "data/Variable/Utils";
import { payloadHostpotProfileTrash } from "data/Variable/HostpotVoucher/HostpotProfile";
import { PriceFormat } from "data/Variable/Common";
import NoLabelSelect from "components/Input/NoLabelSelect";
import { addHostpotProfileData } from "data/Variable/HostpotVoucher/HostpotProfile";
// import Checklist from "components/Button/Checklist";
import PrimaryButton from "components/Button/PrimaryButton";

function HostpotProfileList(props) {
  const page = 'voucherhostpot'
  const [sidebarToggle] = useOutletContext();
  const navigate = useNavigate()
  const hostpotProfileService = new HostpotProfileService()
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role.toLowerCase() === page) || ''
  const [load, setDataLoad] = useState(true)
  const [Table, setDataTable] = useState([])
  const [viewTrashPage, viewTrashPageOnChange] = useState(false)
  const { mitraSuper, organization } = verifySuperAdmin()
  const Columns = mitraSuper ? ColumnsAdmin : ColumnsUser
  const ColumnsTrash = mitraSuper ? ColumnsTrashAdmin : ColumnsTrashUser
  const ownerOption = getOwner()
  const [mitra, setMitra] = useState(ownerOption.find((data) => data.value === organization))

  const getAll =  async(mitra = '', trash = false) => {
    try {
      setDataLoad(true)
      const len = 'ALL'
      const dat =  await hostpotProfileService.getAll(len, trash, mitra)
      const result = dat.data

      setDataState(mapping(result))
      setDataTable(result)
      setDataLoad(false)
      return result
    } catch (error) {
      setDataLoad(false)
      return await CatchError(error, props)
    }
  }

  useEffect( () => {
    validatePermission(props, 0, 1)
    getAll(mitra?.mitraId, viewTrashPage)
  }, [])

  const deleteData = async (id) => {
      const selection = await swal("Delete", "Data Tidak Dapat di Recover", "info", {
        buttons: {
          cancel: 'Batal',
          delete: {text: "Hapus", value: 'del' }
        }}
      )
      switch (selection) {
          case 'del':
            try {
              setDataLoad(true)
              const result = await hostpotProfileService.delete(id)
              await swal(result.status, result.message, 'success')
              await getAll(mitra.mitraid, viewTrashPage)
              const newState = dataState.filter(data => data.id !== id)
              setDataState(newState)
              viewTrashPageOnChange(false)
              setDataLoad(false)
              return true
            } catch (error) {
              return await CatchError(error, props)
            }

          default:
            await getAll(mitra.mitraId, viewTrashPage)
            return false
        }
    }

    const sendTrash = async (id, trash) => {
      try {
          setDataLoad(true)
          const payload = payloadHostpotProfileTrash(trash)
          const result = await hostpotProfileService.setTrash(id, payload)
          await swal('Success', result.message, 'success')
          if (trash === true) {
            const state = await getAll(mitra.mitraid, viewTrashPage)
            const stateRaw = mapping(state)
            const newState = stateRaw.filter(data => data.id !== id)
            setDataState(newState)
          } else {
            await getAll(mitra.mitraid, viewTrashPage)
            viewTrashPageOnChange(false)
          }
          setDataLoad(false)
          return true
      } catch (error) {
        setDataLoad(false)
        return await CatchError(error, props)
      }
    }

    const sendToTrash = async (id) => {
      const setTrash = await swal("Trash", "Send To Trash", "info", {
        buttons: {
          cancel: 'Cancel',
          delete: {text: "Continue", value: 'del' }
        }}
      )
      switch (setTrash) {
          case 'del':
            const trash = true
            return await sendTrash(id, trash)
          default:
            await getAll(mitra.mitraid, trash)
            return false
        }
    }

    const recoverTrash = async (id) => {
      const setTrash = await swal("Recover", "Recover Data", "info", {
        buttons: {
          cancel: 'Cancel',
          delete: {text: "Continue", value: 'del' }
        }}
      )

      switch (setTrash) {
        case 'del':
          const trash = false
          return await sendTrash(id, trash)
        default:
          return false
      }
    }

    const viewData = async(id) => {
      try {
        const dat = await hostpotProfileService.get(id)
        const result = dat.data
        addHostpotProfileData(result)
        return navigate('/hostpot/profile/detail', { state: result })
      } catch (error) {
        return await CatchError(error, props)
      }
    }

    const mapping =  (Table) => {
      const currency = 'idr'
      return Table.map((Data, i) => {
        try {
            return {
              key: i,
              id: Data.id,
              name: Data.name,
              mitra: Data.mitra,
              router: Data.routerName,
              buyingPrice: PriceFormat(Data.buyingPrice, currency),
              sellingPrice: PriceFormat(Data.sellingPrice, currency),
              rateUp: `${Data.rateUp} ${Data.rateUpMeasurement}`,
              rateDown: `${Data.rateDown} ${Data.rateDownMeasurement}`,
              actionsUser: (
                <EditButton
                  onClick={ async () => {
                    await viewData(Data.id)
                  }}
                  className="btn-icon btn-round"
                  disabled = {!Permission.Read}
                  color="warning"
                  size="sm"
                />
              ),
              actions: (
                <>
                  <EditButton
                    onClick={async () => {
                      await viewData(Data.id);
                    } }
                    className="btn-icon btn-round"
                    id={`tooltip${Data.id}`}
                    disabled={!Permission.Read}
                  />
                  <DeleteButton
                    onClick={async () => {
                      await sendToTrash(Data.id);
                    } }
                    className="btn-icon btn-round"
                    id={`tooltip${Data.id}1`}
                    disabled={!Permission.Delete}
                    />
                  </>
              ),
              recover: (
                <PrimaryButton
                  onClick={ async () => {
                    await recoverTrash(Data.id)
                  }}
                  className="btn"
                  title = 'Recover'
                  key = {RandomTextGenerator()}
                  disabled = {!Permission.Update}
                />
              ),
              actionsTrash: (
                <div key={Data.id}>
                  <DeleteButton
                    onClick={ async () => {
                      await deleteData(Data.id)
                    }}
                    className="btn-icon"
                    key = {RandomTextGenerator()}
                    disabled = {!Permission.Delete}
                  />
                </div>
              ),
          };
          } catch (error) {
            console.log(error.message)
            return []
          }
        }
      )
  }

  const [dataState, setDataState] = useState(mapping(Table))

  // const viewTrash = async (trash) => {
  //   setDataLoad(true)
  //   const data = await getAll(mitra.mitraid, trash)
  //   setDataState(mapping(data))
  //   setDataLoad(false)
  // }

  function onClickAdd() {
    return navigate('/hostpot/profile/form')
  }

  const menu = (
    <>
    <div className="flex flex-row my-3">
      <div className="p-3 flex md:flex-col mr-10">
        <PrimaryIconButton
          key={RandomTextGenerator()}
          title = {'Add'}
          onClick = {onClickAdd}
        />
      </div>
      <div className="p-3 flex md:flex-col mr-10">
          <NoLabelSelect
            placeholder = {"Mitra"}
            value = {mitra}
            option = {ownerOption}
            isDisabled = {!mitraSuper}
            onChange = {async (e) => {
              setMitra(e)
              await getAll(e.mitraid, viewTrashPage)
            }}
          />
        </div>
        {/* <div className="flex md:flex-col mr-10">
          <Checklist
            text = 'View Trash'
            value = {viewTrashPage}
            checked = {viewTrashPage}
            onChange = {async (e) => {
              viewTrashPageOnChange(e.target.checked)
              await viewTrash(e.target.checked)
            }}
          />
        </div> */}
    </div>
    </>
  )

  return (
    <>
      <main className="h-full">
        <Navbar key={RandomTextGenerator()} toggle={sidebarToggle} />

        {/* Main Content */}
        <div className="mainCard">
          <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
            <MainTable
              key={RandomTextGenerator()}
              header = {!viewTrashPage ? 'Hostpot Profile Manager' : 'Trash'}
              menu={menu}
              loading={load}
              dataHeader={!viewTrashPage ? Columns : ColumnsTrash}
              dataState={dataState}
            />
          </div>
        </div>
      </main>
    </>
  );
}

export default HostpotProfileList;
