import React from 'react';
import { createRoot } from 'react-dom/client';
import "bootstrap/dist/css/bootstrap.css";
import './Assets/css/index.css';
import "rc-pagination/assets/index.css";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './Assets/css/react-tabs.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
