import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Index";
import { useNavigate, useOutletContext } from "react-router-dom";
import MainTable from "../../components/Table/MainTable";
import PrimaryIconButton from "components/Button/PrimaryIconButton";
import { RouterHeader } from "./RouterHeader";
import { RandomTextGenerator } from "data/Variable/Utils";
import { getUserPermission } from "data/Variable/UserVariable";
import RouterNasService from "data/Handler/RouterNasService";
import { useFormInput } from "data/Variable/Utils";
import { CatchError } from "data/Handler/Exceptions/exceptions";
import swal from "sweetalert";
// import { addItemRouterList } from "data/Variable/Common";
import { addRouterData } from "data/Variable/Common";
import { nullCompensation } from "data/Variable/Utils";
import { customTextFromBoolean } from "data/Variable/Utils";
import { EditButton } from "components/Button/EditButton";
import { DeleteButton } from "components/Button/DeleteButton";

function RouterList(props) {
  const [sidebarToggle] = useOutletContext();
  const navigate = useNavigate()
  const pages = 'resource'
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role === pages) || ''
  const routerService = new RouterNasService()
  const ItemLen = useFormInput('ALL')
  const [loading, setDataLoaded] = useState(true)
  const [dataTable, dataTableOnChange] = useState([])

    const getRouter =  async() => {
      try {
        setDataLoaded(true)
        const len = ItemLen.value
        const dat =  await routerService.getAllRouter(len)
        const result = dat.data

        setDataState(render(result))
        dataTableOnChange(result)
        setDataLoaded(false)
        return result
      } catch (error) {
        return await CatchError(error, props)
      }
    }

    useEffect(() => {
      getRouter()
     }, [])

    const deleteRouter = async (Id) => {
      const value = await swal("Delete", "Router Data Can't Recover", "info", {
        buttons: {
          cancel: 'Cancel',
          delete: { text: "Delete", value: 'del' }
        }
      }
      );
      switch (value) {
        case 'del':
          try {
            setDataLoaded(true)
            const respomse = await routerService.deleteRouter(Id);
            await swal(respomse.status, respomse.message, 'success')
            const state = await getRouter()
            const stateRaw = render(state)
            const newState = stateRaw.filter((data) => data.id !== Id)
            setDataState(newState);
            setDataLoaded(false)
            return true;
          } catch (error) {
            setDataLoaded(false)
            return await CatchError(error, props);
          }

        default:
          await getRouter();
          return false;
      }
    }

    const viewRouter = async(id)=> {
      try {
        const dat = await routerService.getRouter(id)
        const result = dat.data
        // const len = `ALL`
        // const datProfile =  await routerService.getAllItemByRouterid(id, len)
        // const resultProfile = datProfile.data
        // addItemRouterList(resultProfile)
        addRouterData(result)
        navigate('/router/detail', { state: result})
      } catch (error) {
        return await CatchError(error, props)
        }
    }

    // const testConnection = async (id) => {
    //   try {
    //     const result = await routerService.testRouter(id)
    //     await swal('connected', result.message, 'success')
    //     const table = await getRouter()
    //     const state = render(table)
    //     setDataState(state)
    //   } catch (error) {
    //     const err = error.response
    //     await swal('failed', err.data.message, 'warning')
    //     await getRouter()
    //     const state = render(dataTable)
    //     setDataState(state)
    //   }
    // }

    const render = (Table) => Table.map((Data) => {
        try {
            console.log(Data.connection)
            return {
              id: Data.id,
              username: Data.name,
              name: Data.name,
              owner: Data.mitra_name,
              host: Data.ip_address,
              apiPort: Data.port,
              location: Data.urban,
              radiusAuthPort: Data.radiusServerAuthPort,
              radiusAccountingPort: Data.radiusServerAccountingPort,
              routerName: nullCompensation(Data.routerName),
              connection: customTextFromBoolean(Data.connection, 'Connected', 'Disconnect'),
              actions: (
                <>
                  {/* <PrimaryIconButton
                    onClick={() => testConnection(Data.id)}
                    id={RandomTextGenerator()}
                    color="warning"
                    size="sm"
                  /> */}
                  <EditButton
                    disabled = {!Permission.Read}
                    onClick={() => {
                      viewRouter(Data.id)
                    }}
                    id={RandomTextGenerator()}
                    color="warning"
                    size="sm"
                  />
                  <DeleteButton
                    disabled = {!Permission.Delete}
                    onClick={async () => {
                        await deleteRouter(Data.id)
                    }}
                    id={RandomTextGenerator()}
                    color="danger"
                    size="sm"
                  />
                </>
              ),
          }
        } catch (error) {
          return []
        }
    }
  )

  const [dataState, setDataState] = React.useState(
    render(dataTable)
  );

  function onClickAdd() {
    return navigate('/router/form')
  }

  const menu = (
    <>
    <div className="flex flex-row my-3">
      <div className="flex md:flex-col mr-10">
        <PrimaryIconButton
          key = {RandomTextGenerator()}
          title = {'Add'}
          onClick = {onClickAdd}
        />
      </div>
      {/* <div className="flex md:flex-col mr-10">
        <PrimaryIconButton
          key = {RandomTextGenerator()}
          title = {'Export'}
        />
      </div> */}
    </div>
    </>
  )

  return (
    <>
      <main className="h-full">
        <Navbar key={RandomTextGenerator()} toggle={sidebarToggle} />

        {/* Main Content */}
        <div className="mainCard">
          <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
            <MainTable
              key={RandomTextGenerator()}
              header = {'Router NAS Manager'}
              menu={menu}
              loading={loading}
              dataHeader={RouterHeader}
              dataState={dataState}
            />
          </div>
        </div>
      </main>
    </>
    );
  }

export default RouterList;
