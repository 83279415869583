import LoginService from '../AuthService'
import APIconsume from '../ConsumeAPI'
import { getAccessToken } from "../../Variable/Common"
import { parseDatetimeToday } from './../../Variable/Utils'

class DashboardService extends LoginService {
    constructor() {
        super()
        const accessToken = getAccessToken()
        this._consume = new APIconsume(accessToken)
        this._url = 'hostpot'
    }

    async getDashboard (mitra = '', year = '', reseller = '') {
        const { year: years } = parseDatetimeToday()
        const url = `${this._url}/FinanceReport?year=${year || years}&mitra=${mitra}&reseller=${reseller}`

        const result = await this._consume.getData(url)
        return result.data
    }

    async getYearSelect (mitra = '') {
        const url = `${this._url}/FinanceReport/yearselect?mitra=${mitra}`

        const result = await this._consume.getData(url)
        return result.data
    }
}

export default DashboardService