import { faRefresh } from "@fortawesome/free-solid-svg-icons"
import Checklist from "components/Button/Checklist"
import PrimaryIconButton from "components/Button/PrimaryIconButton"
import { GlobalHeader } from "components/Datatables/GlobalHeader"
import DefaultInput from "components/Input/DefaultInput"
import SelectBasic from "components/Input/SelectBasic"
import LoadingScreen from "components/Loader/LoadingScreen"
import Navbar from "./../../../components/Navbar/Index";
import { CatchError } from "data/Handler/Exceptions/exceptions"
import SubscriptionAndHistoryService from "data/Handler/Pppoe/SubscriptionAndHistoryService"
import { invoice } from "data/Variable/Common"
import { addBillingOptions } from "data/Variable/Common"
import { getCustomerOptions } from "data/Variable/Common"
import { getRefreshToken } from "data/Variable/Common"
import { getUserData } from "data/Variable/UserVariable"
import { getOwner } from "data/Variable/UserVariable"
import { mapInvoicetoPeriod } from "data/Variable/Utils"
import { useFormInput } from "data/Variable/Utils"
import { useEffect, useState } from "react"
import { useNavigate, useOutletContext } from "react-router-dom"
import swal from "sweetalert"

function PppoeGenerateInvoice(props) {
  const [sidebarToggle] = useOutletContext()
  const navigate = useNavigate()
  const subscriptionAndHistoryService =  new SubscriptionAndHistoryService()
  const refreshToken = getRefreshToken()
  var [selectSubscriptionOptions, selectCustomerOptionsOnChange] = useState(getCustomerOptions())
  const ownerData = getOwner()
  const userData = getUserData()
  const userType = userData.userType
  const [changeOwner, changeOwnerOnChange] = useState(false)
  const [owner, ownerOnChange] = useState(ownerData.find(own => own.value === userData.organization))
  const description = useFormInput(`Manual Generated, Company ${owner.value} ${new Date()}`)
  const [Subscription , subscriptionOnChange] = useState('')
  const [period, periodOnChange] = useState([])
  const [selectPeriodOptions, selectPeriodOptionsOnChange] = useState([])
  const [selectPeriodOptionsExits, selectPeriodOptionsExitsOnChange] = useState([])
  const [usePeriod, PeriodOnChange] = useState(false)
  const [specificInvoice, specificInvoiceOnChange] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const option = specificInvoice ? (usePeriod ? "Generate Previous" :"Generate") : "Generate All"

  const getInvoice =  async() => {
    const len = 'ALL'
    const result =  await subscriptionAndHistoryService.getAllInvoiceGroup(len)
    // PppoeGenerateInvoiceList(result.data)
    return result.data
  }

  const getCustomer = async (mitraid) => {
    const customerOption = await subscriptionAndHistoryService.getCustomerSelect(mitraid)
    console.log(customerOption.data)
    selectCustomerOptionsOnChange(customerOption.data)
  }

  const getInvoicePeriod =  async(id) => {
    const result =  await subscriptionAndHistoryService.getInvoicePeriod(id)
    const invPeriodData = await filterDate(id, result.data)
    // console.log(invPeriodData)
    selectPeriodOptionsOnChange(invPeriodData.invPeriod)
    // console.log('View Period: ', selectPeriodOptionsExits)
    // console.log('INV Period: ', invPeriodData)
    selectPeriodOptionsExitsOnChange(invPeriodData.invPeriodExits)
  }

  useEffect(() => {
    const data = async () => {
      const select = await subscriptionAndHistoryService.getBillingSelect()
      addBillingOptions(select)
      }
    data()
    })

  const filterDate = async(customerid, invPeriodData) => {
    const invData = await getInvoice()
    // console.log('view inv data: ', invData)
    const invFilter = invData.filter((inv) => inv.customerId === customerid)
    const invMap = mapInvoicetoPeriod(invFilter)
    // console.log(invMap)
    // const periodData = period
    const invPeriod = invPeriodData.filter(function(item) {
      const inv = invMap.find(invDat => invDat.month.replaceAll(' ', '').toLowerCase() ===
      item.value[0].month.replaceAll(' ', '').toLowerCase() && invDat.year === item.value[0].year)
      // console.log(state1, state2, state1.length, state2.length)
      if (inv){
        return false;
      }
      return true;
    })

    const invPeriodExits = invPeriodData.filter(function(item) {
      const inv = invMap.find(invDat => invDat.month.replaceAll(' ', '').toLowerCase() ===
      item.value[0].month.replaceAll(' ', '').toLowerCase() && invDat.year === item.value[0].year)
      // console.log(state1, state2, state1.length, state2.length)
      if (!inv){
        return false;
      }
      return true;
    })

    return {invPeriod, invPeriodExits}
  }



  const PppoeGenerateInvoiceData = async() => {
  try {
    setLoading(true)
    let payload = invoice(owner.mitraid)
    if (!period) {
      payload = invoice( owner.mitraid, Subscription.value, description.value)
    }
    if (period && specificInvoice) {
      payload = invoice( owner.mitraid, Subscription.value, description.value, period.value)
    }
    console.log(payload)
    const result = await subscriptionAndHistoryService.addInvoice(payload)
    await getInvoice()
    const subsExits = result.data.subsExits[0]
    if (!subsExits) {
      await swal('No Subscription', 'Customer Tidak Memiliki Subscription', "info")
      setLoading(false)
      return
    }
    await swal(result.status, result.message, "success")
    if (!usePeriod) {
      navigate('/pppoe/invoice')
      setLoading(false)
      return
    }
    setLoading(false)
    return await getInvoicePeriod(Subscription.value)
  } catch (error) {
    setLoading(false)
    return await CatchError(error, props, refreshToken)
  }}

  function deleteSelect() {
    subscriptionOnChange([])
    periodOnChange([])
    selectPeriodOptionsOnChange([])
  }

  const children = (
      <>
      {/* Main Content */}
      <div className="mainCard">
        <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
            {/* <form> */}
              <GlobalHeader
                  header = {'Generate Invoice'}
                />
                {(userType === 'SUPERADMIN') ? (
                  <>
                  <SelectBasic
                    label = {"Mitra"}
                    // className="react-select"
                    // classNamePrefix="react-select"
                    placeholder="owner"
                    value={owner}
                    isDisabled = {!changeOwner}
                    option={ownerData}
                    onChange={ async(e) => {
                      ownerOnChange(e);
                      await getCustomer(e.mitraid)
                      deleteSelect()
                    } } />
                    <Checklist
                      text = {'Ganti Mitra'}
                      value = {changeOwner}
                      checked = {changeOwner}
                      onChange = {async (e) => {
                        ownerOnChange(ownerData.find(own => own.value === userData.organization))
                        changeOwnerOnChange(e.target.checked)
                        await getCustomer(owner.mitraid)
                        if (owner.value !== userData.organization) {
                          deleteSelect()
                          selectCustomerOptionsOnChange(getCustomerOptions())
                        }
                        }
                      }
                    />
                    </>) : (
                    <DefaultInput
                    placeholder="Mitra"
                    value={owner.value}
                    />
                    )
                }
              <SelectBasic
                // className="react-select"
                // classNamePrefix="react-select"
                label = {'Customer'}
                placeholder="Select Customer"
                name="singleSelect"
                isDisabled = {!specificInvoice}
                value = {Subscription}
                option = {selectSubscriptionOptions}
                onChange={async(value) => {
                  await getInvoicePeriod(value.value)
                  subscriptionOnChange(value)
                  periodOnChange([])
                }
                }
              />
              <Checklist
                text = {'Generate Specific Invoice'}
                value={specificInvoice}
                onChange={(e) => {specificInvoiceOnChange(e.target.checked)
                  subscriptionOnChange('')
                }}
              />
              <SelectBasic
                // className="react-select"
                // classNamePrefix="react-select"
                label = {'Periode Subscription'}
                placeholder="Periode Subscription"
                name="singleSelect"
                isDisabled= {!usePeriod}
                value = {period}
                option = {selectPeriodOptions}
                onChange={(value) => periodOnChange(value)}
              />
              {selectPeriodOptionsExits.length >= 1 ? (
                <label className="error">
                  {selectPeriodOptionsExits.length >= 1 && !selectPeriodOptions.length ? 'Semua' :
                  selectPeriodOptionsExits.length} Periode Invoice telah di generate, {selectPeriodOptions.length} belum di generate
                </label>
                ) : null}
            <Checklist
              text = {'Generate Invoice Bulan Sebelum-nya'}
              value={usePeriod}
              onChange={(e) => {PeriodOnChange(e.target.checked)
                periodOnChange([])
              }}
            />
            <div className="mt-6 flex flex-row gap-4">
              <PrimaryIconButton
                title= {option}
                icon = {faRefresh}
                onClick = {PppoeGenerateInvoiceData}
                color="info"
              />
            </div>
          </div>
          {/* </form> */}
        </div>
      </>
    )

  return (
      <>
        <main className="h-full">
          <Navbar toggle={sidebarToggle} />
          <LoadingScreen
            children={children}
            loading = {loading}
            text={"Generate Invoice"}
          />
        </main>
      </>
    );
}


export default PppoeGenerateInvoice;
