import React from "react";
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { MainChartDataProcessing } from "data/Variable/Utils";

Chart.register();

function Statistic({ ...props }) {
  const { data } = props
  const normalizeData = MainChartDataProcessing(data)

  const dataset = {
    labels: ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli"],
    datasets: [
      {
        label: "2023",
        data: normalizeData || ["0", "0", "0", "0", "0", "0", "0"],
      },
      // {
      //   label: "Target",
      //   data: data || ["0", "0", "0", "0", "0"],
      //   type: "line",
      // },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 2,
  };
  return (
    <div className={`widgetCard p-3 md:py-4 md:px-6 ${props.className}`}>
      <h1 className="text-medium font-semibold pb-4">Income Statement</h1>
      <div className="">
        <Bar data={dataset} options={options} />
      </div>
    </div>
  );
}

export default Statistic;
