import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export function EditButton({onClick}) {
    return (
        <Link
            //   to={`/auth/master/user/${id}/edit`}
            className={`text-sky-700 inline-flex py-2 px-2 rounded  text-sm`}
            onClick={onClick}
        >
          <FontAwesomeIcon icon={faPencil} />
        </Link>
    )
}