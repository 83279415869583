import { HandleGeolocation } from 'data/Geolocation/HandleGeolocation';
import BasicMapIframe from '../Maps/BasicMapIframe';
import React, { useState } from 'react';
import { Input, FormGroup, Label, Col, Row } from 'reactstrap';
import PrimaryIconButton from 'components/Button/PrimaryIconButton';
import { faLocationPin } from '@fortawesome/free-solid-svg-icons';
import { RandomTextGenerator } from 'data/Variable/Utils';

function InputCoordinate ({ latitude, setLatitude, longitude, setLongitude, disabledLat, disabledLog, disabledGeo, disabledGeoButton}) {
  const [accuracy, setAccuracy] = useState('');

  const handleGeolocation = async () => {
    const geoData = await HandleGeolocation()
    const { latitude, longitude, accuracy, message } = geoData
    setLatitude(latitude);
    setLongitude(longitude);
    const accMsg = `${accuracy.toFixed(3)} Meter`
    setAccuracy(message || accMsg);
  };

  return (
      <div>
        <BasicMapIframe
            id = {RandomTextGenerator()}
            lat = {latitude}
            long = {longitude}
        />
        <Row>
            <Col>
              <FormGroup>
                <Label for="latitudeInput" className="text-sm text-gray-600">Latitude:</Label>
                <Input
                  type="text"
                  id="latitudeInput"
                  // onChange={(e) => setEmail(e.target.value)}
                  className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full md:py-2 py-3 focus:outline-none focus:border-emerald-400 mt-1"
                  value={latitude}
                  onChange={e => setLatitude(e.target.value)}
                  disabled = {disabledLat}
                />
              </FormGroup>
            </Col>
            <Col>
                <FormGroup>
                  <Label for="longitudeInput" className="text-sm text-gray-600">Longitude:</Label>
                  <Input
                    type="text"
                    id="longitudeInput"
                    // onChange={(e) => setEmail(e.target.value)}
                    className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full md:py-2 py-3 focus:outline-none focus:border-emerald-400 mt-1"
                    value={longitude}
                    onChange={e => setLongitude(e.target.value)}
                    disabled = {disabledLog}
                  />
                </FormGroup>
            </Col>
        </Row>
          {
          !disabledGeo ? (
                <>
                <FormGroup>
                    <Label for="accuracyInput" className="text-sm text-gray-600">Akurasi:</Label>
                        <Input
                            type="text"
                            id="accuracyInput"
                            // onChange={(e) => setEmail(e.target.value)}
                            className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full md:py-2 py-3 focus:outline-none focus:border-emerald-400 mt-1"
                            value={accuracy} />
                </FormGroup>
                <PrimaryIconButton
                  title = {'Lokasi Saat Ini'}
                  onClick = {handleGeolocation}
                  disabled = {disabledGeoButton}
                  icon = {faLocationPin}
                />
                </>
            ) : null
          }
      </div>
  );
};

export default InputCoordinate;