export const RouterHeader = [
        {
          label: "Nama Router",
          key: "routerName",
        },
        {
          label: "Lokasi",
          key: "location",
        },
        {
          label: "Username",
          key: "name",
        },
        {
          label: "API Port",
          key: "apiPort",
        },
        {
          label: "NAS Host",
          key: "host",
        },
        {
          label: "Auth Port",
          key: "radiusAuthPort",
        },
        {
          label: "Accounting Port",
          key: "radiusAccountingPort",
        },
        {
          label: "API Status",
          key: "connection",
        },
        {
          label: "Action",
          key: "actions",
          sortable: false,
          filterable: false,
        },
    ]