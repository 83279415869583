export const ColumnsUser = [
    {
        label: "Username",
        key: "name",
    },
    {
        label: "Password",
        key: "password",
    },
    {
        label: "Mitra",
        key: "mitra",
    },
    {
        label: "Router",
        key: "router",
    },
    {
        label: "Rate Up",
        key: "rateUp"
    },
    {
        label: "Rate Down",
        key: "rateDown"
    },
    {
        label: "Status",
        key: "setPaidUser"
    },
    {
        label: "View",
        key: "actionsUser",
        sortable: false,
        filterable: false,
    }
]

export const ColumnsAdmin = [
    {
        label: "Username",
        key: "name",
    },
    {
        label: "Password",
        key: "password",
    },
    {
        label: "Mitra",
        key: "mitra",
    },
    {
        label: "Router",
        key: "router",
    },
    {
        label: "Rate Up",
        key: "rateUp"
    },
    {
        label: "Rate Down",
        key: "rateDown"
    },
    {
        label: "Status",
        key: "setPaidAdmin"
    },
    {
        label: "Action",
        key: "actions",
        sortable: false,
        filterable: false,
    },
]

export const ColumnsTrashAdmin = [
    {
        label: "Username",
        key: "name",
    },
    {
        label: "Password",
        key: "password",
    },
    {
        label: "Router",
        key: "router",
    },
    {
        label: "Rate Up",
        key: "rateUp"
    },
    {
        label: "Rate Down",
        key: "rateDown"
    },
    {
        label: "Recover",
        key: "recover",
        sortable: false,
        filterable: false,
    },
    {
        label: "Hapus Permanen",
        key: "actionsTrash",
        sortable: false,
        filterable: false,
    },
]

export const ColumnsTrashUser = [
    {
        label: "Username",
        key: "name",
    },
    {
        label: "Password",
        key: "password",
    },
    {
        label: "Mitra",
        key: "owner",
    },
    {
        label: "Rate Up",
        key: "rateUp"
    },
    {
        label: "Rate Down",
        key: "rateDown"
    },
    {
        label: "Status",
        key: "approvalUser",
    },
    {
        label: "File",
        key: "filename",
    },
]