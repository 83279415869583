import { PriceFormat } from "data/Variable/Common";
import { Datestr } from "data/Variable/Utils";
import React from "react";

function Achievement({profitability, date, profit}) {
  const currency = 'idr'
  return (
    <div className="widgetCard relative hidden md:flex col-span-2 px-6 py-4 text-center flex-col justify-between bg-cyan-700 text-slate-50">
      <div className="font-semibold text-slate-800 bg-white max-w-fit mx-auto pt-5 pb-2 px-6 absolute -top-3 rounded-lg left-1/2 -translate-x-1/2 whitespace-nowrap">
        Laba (YtD)
      </div>
      <div className="font-semibold m-auto pt-4">
        <span className="text-lg lg:text-[80px]">{profitability}</span>.%
      </div>
      <p className="text-sm font-semibold">{PriceFormat(profit, currency)} Year to Date</p>
      <p className="text-xs">{Datestr(date)}</p>
    </div>
  );
}

export default Achievement;
