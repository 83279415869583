import PrimaryButton from "components/Button/PrimaryButton"
import NoLabelSelect from "components/Input/NoLabelSelect"
import { CatchError } from "data/Handler/Exceptions/exceptions"
import SubscriptionAndHistoryService from "data/Handler/Pppoe/SubscriptionAndHistoryService"
import { putInvoice } from "data/Variable/Common"
import { getRefreshToken } from "data/Variable/Common"
import { getUserPermission } from "data/Variable/UserVariable"
import { getUserData } from "data/Variable/UserVariable"
import { customTextFromBoolean } from "data/Variable/Utils"
import { CleanStringSpace } from "data/Variable/Utils"
import { invoiceStatus } from "data/Variable/Utils"
import { forwardRef, useEffect, useRef, useState } from "react"
import swal from "sweetalert"
import { invoiceColumnsSuperUser, invoiceColumnsUser } from "./PppoeInvoiceColumns"
import Navbar from "./../../../components/Navbar/Index"
import { useNavigate, useOutletContext } from "react-router-dom"
import MainTable from "components/Table/MainTable"
import { DeleteButton } from "components/Button/DeleteButton"
import ModalView from "components/Modal/ModalView"
import PrimaryIconButton from "components/Button/PrimaryIconButton"
import { RandomTextGenerator } from "data/Variable/Utils"
import { useReactToPrint } from "react-to-print"
import { sendEmailPayload } from "data/Variable/Common"
import { DownloadButton } from "components/Button/DownloadButton"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import { Editor } from "react-draft-wysiwyg"
import { EditorState, ContentState,
  convertToRaw, convertFromHTML } from 'draft-js'
import draftToHtml from "draftjs-to-html"
import parse from "html-react-parser"

function PppoeInvoiceList(props) {
  const [sidebarToggle] = useOutletContext()
  const navigate = useNavigate()
  const pages = 'invoice'
  const userData = getUserData()
  const permission = getUserPermission()
  const { mitraType } = userData
  const Permission = permission?.find(data => data?.Role === pages) || ''
  const [Table, dataTableOnChange] = useState([])
  const subscriptionAndHistoryService =  new SubscriptionAndHistoryService()
  const refreshToken = getRefreshToken()
  const [loading, setDataLoaded] = useState(true)
  const [period, setPeriod] = useState({})
  const [selectPeriod, setSelectPeriod] = useState([])
  const superAdmin = (mitraType === 'SUPERADMIN')
  const [showMessageSender, setShowMessageSender] = useState(false)
  const [modalMessageSenderLoading] = useState(false)
  const [modalDataMessageSender, setModalDataMessageSender] = useState({})
  const [showPdfModal, setShowPdfModal] = useState(false)
  const [modalPdfModalLoading] = useState(false)
  const [getInvoiceData, setInvoiceData] = useState({})
  const [modalDataPdf, setModalDataPdfModal] = useState((<h1>No Data</h1>))
  const [modalDataPdfRaw, setModalDataPdfModalRaw] = useState('<label>No Data</label>')
  // const [download] = useState(false)

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });


  const handleCloseMessageSender = () => {
    setModalDataMessageSender(EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML('<h1>No Data</h1>'))))
    setModalDataPdfModal({})
    setModalDataPdfModalRaw('<label>No Data</label>')
    setShowMessageSender(false)
    setDataLoaded(false)
  }
  const handleShowMessageSender = (data) => {
    setModalDataMessageSender(EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(data))))
    setShowMessageSender(true)
  }

  const handleClosePdfModal = () => {
    setModalDataPdfModal({})
    setShowPdfModal(false)
  }
  const handleShowPdfModal = (data) => {
    // setModalDataPdfModal(EditorState.createWithContent(
    //   ContentState.createFromBlockArray(convertFromHTML(data))))
    setModalDataPdfModal(data)
    setShowPdfModal(true)
  }

  const updateMailTextDescription = (state) => {
      setModalDataMessageSender(state)
      convertToRaw(state.getCurrentContent())
      setModalDataPdfModalRaw(draftToHtml(convertToRaw(state.getCurrentContent())))
      // console.log(htmlDat)
    }


  const PrintDocument = forwardRef((props, ref) => {
    return (
      <div ref={ref} className="marginprint bg-light">
        <div className="modalDataPdf bg-primary">
          {/* <Editor
             wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            editorState = {modalDataPdf}
            disabled
            onEditorStateChange = {() => {}}
          /> */}
          {modalDataPdf}
        </div>
      </div>
    )
  })

  const SendDocument = forwardRef((props, ref) => {
    return (
      <div ref={ref} className="marginprint bg-light">
        <div className="modalDataPdf bg-green-100">
          <Editor
             wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            editorState = {modalDataMessageSender}
            onEditorStateChange = {updateMailTextDescription}
          />
          {/* {modalDataMessageSender.getCurrentContent()} */}
        </div>
      </div>
    )
  })

  // console.log('Invoice list')
  const fetchInvoice = async() => {
    const periodState = await fetchPeriod()
    // console.log('invoice list fetch', periodState?.value)
    return await getInvoice(periodState?.value)
  }

  const getInvoice =  async(periodSelected) => {
    // console.log('Get-Invoice')
    try {
      setDataLoaded(true)
      const len = 'ALL'
      const dat =  await subscriptionAndHistoryService.getAllInvoiceGroup(len, periodSelected)
      const result = dat.data || []
      dataTableOnChange(result)
      setDataState(renderTable(result, periodSelected))
      setDataLoaded(false)
      return result
    } catch (error) {
      setDataLoaded(false)
      return await CatchError(error, props, refreshToken)
    }
  }

  useEffect(() => {
    fetchInvoice(period?.value)
  }, [])

  const fetchPeriod =  async() => {
    try {
      setDataLoaded(true)
      const dat =  await subscriptionAndHistoryService.getInvoiceListPeriod()
      // console.log('fetch period', dat.data)
      const result = dat.data || []
      setSelectPeriod(result)
      const periodState = result[0]
      let basePeriode = period
      if (!period?.value) {
        setPeriod(periodState)
        basePeriode = periodState
      }
      setDataLoaded(false)
      return basePeriode
    } catch (error) {
      setDataLoaded(false)
      return await CatchError(error, props, refreshToken)
    }
  }

  const deleteInvoice = async(id, periode) => {
    swal("Hapus", "Invoice Tidak Dapat di Recover", "info", {
          buttons: {
          cancel: 'Cancel',
          delete: {text: "Delete", value: 'del' }
        }
      }
    ).then(async(value) => {
        switch (value) {
          case 'del':
            try {
                setDataLoaded(true)

                await subscriptionAndHistoryService.deleteInvoice(id)
                await swal('Success', 'Invoice Berhasil di Hapus', 'success')
                const state = await getInvoice(periode)
                const stateRaw = renderTable(state, periode)
                const table = stateRaw.filter((dat) => dat.id !== id)
                setDataState(table)
                setDataLoaded(false)
            } catch (error) {
              setDataLoaded(false)
              return await CatchError(error, props, refreshToken)
            }
            break;
            default:
              setDataLoaded(false)
        }
      })
  }

  const viewInvoice = async(id) => {
    try {
      const dat = await subscriptionAndHistoryService.getInvoice(id)
      const modalDataPdf = await subscriptionAndHistoryService.getPdfTemplate(id)
      const result = dat?.data

      const modalData = parse(modalDataPdf)
      setModalDataPdfModal(modalDataPdf)
      setInvoiceData(result)
      handleShowPdfModal(modalData)
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }

  const sendEmail = async(id) => {
    try {
      setDataLoaded(true)
      const dat = await subscriptionAndHistoryService.getInvoice(id)
      const result = dat?.data
      // addCustomerName(result)
      setInvoiceData(result)
      const modalDataPdf = await subscriptionAndHistoryService.getPdfTemplate(id)
      // console.log(modalDataPdf)
      // const modalData = parse(modalDataPdf)
      setModalDataMessageSender(modalDataPdf)
      setModalDataPdfModalRaw(modalDataPdf)
      setInvoiceData(result)
      handleShowMessageSender(modalDataPdf)
      setDataLoaded(false)
    } catch (error) {
      // console.error(error.message)
      setDataLoaded(false)
      return await CatchError(error, props, refreshToken)
    }
  }

   const SendEmail = async() => {
      swal("Send", "Notify Customer", "info", {
                  buttons: {
                  update: {text: "Send", value: 'up' },
                  cancel: 'Cancel'
                }
              }
        ).then(async(value) => {
          switch (value) {
            case 'up':
              setDataLoaded(true)
              const datPayload = modalDataPdfRaw
              const To = RandomTextGenerator()
              const title = 'Voucher Hostpot'
              const from = 'noreply@artamedianet.co.id'
              const subject = 'Invoice'
              const phone = getInvoiceData.customer.mobileNo
              const attachment = {}
              const payload = sendEmailPayload(title, from, To, subject, datPayload, phone, attachment)

              try {
                const result = await subscriptionAndHistoryService.sendEmail(payload)
                swal('Success', 'Email berhasil dikirim', 'success').then(() => {
                  // props.history.push('/admin/Invoice-list')
                  handleCloseMessageSender()
                })
                return result
              } catch (error) {
                try {
                  const result = await subscriptionAndHistoryService.sendEmailBack(payload)
                  swal('Success', 'Email berhasil dikirim dengan internal server', 'success').then(() => {
                    setDataLoaded(false)
                    // props.history.push('/admin/Invoice-list')
                    handleCloseMessageSender()
                  })
                  return result
                } catch (error) {
                  setDataLoaded(false)
                  handleCloseMessageSender()
                  return await CatchError(error, props, refreshToken)
                }
              }

            default:
              break;
          }
        })
      }

  const SetInvoicePay = async(status, id, periode) => {
    const description = ''
    setDataLoaded(true)
    // console.log('invoice status', status)
    const operation = invoiceStatus(!status)
    await swal(operation, "Closing Manual tidak akan mengirimkan Notifikasi Pembayaran", 'info', {
                buttons: {
                update: {text: "Apply", value: 'up' },
                cancel: 'Cancel'
              }
            }
      ).then(async(value) => {
        switch (value) {
          case 'up':
            try {
              // console.log(period)
              const payload = putInvoice(!status, description)
              const dat = await subscriptionAndHistoryService.patchInvoice(id, payload)
              await swal(operation, dat.message, dat.status)
              const table = await getInvoice(periode)
              setDataState(renderTable(table, periode))
              setDataLoaded(false)
            } catch (error) {
              setDataLoaded(false)
              return await CatchError(error, props, refreshToken)
            }
            break;
          default:
            setDataLoaded(false)
            break
        }
    })
  }

  const Add = () => {
    navigate('/pppoe/invoice/generate')
  }

  const Export = () => {
    navigate('/pppoe/invoice/export')
  }

  const MessageSenderModalContent = (refComponent) => (
    <>
      <div className="mainCard">
        <div>
          <SendDocument
            ref={refComponent}
          />
        </div>
      </div>
    </>
  )

  const InvoicePdfViewerModalContent = (refComponent) => (
    <>
      <div className="content p-6">
        <PrintDocument
          ref={refComponent}
        />
      </div>
    </>
  )

  const renderTable = (table, periodSelected) => table.map((Data, i) => {
      try {
        // console.log('list render', i)
        const periodeInv = CleanStringSpace(Data.period || '') + ' ' + Data.year
        return {
            key: i,
            id: Data.id,
            customerId: Data.customerId,
            customer: Data.customerName,
            period: periodeInv,
            owner: Data.mitra_name,
            email: Data.customerEmail,
            invoiceStatusUser: customTextFromBoolean(Data.paid, 'Lunas', 'Belum Bayar'),
            paid: Data.paid,
            // billingStatus: Data?.status,
            item: Data.itemName || '',
            invoiceStatus: (
              <div className="actions-center">
                <PrimaryButton
                  disabled = {!Permission.Update}
                  onClick = { async() => {
                    // console.log(period?.value)
                    await SetInvoicePay(Data.paid, Data.id, periodSelected)
                  }}
                  title = {!Data.paid ? 'Unpaid' : 'Paid'}
                />
              </div>
            ),
            actionSend: (
              <div className="actions-right" key={Data.netId}>
                <PrimaryIconButton
                  disabled = {!Permission.Read}
                  title = {'Notify'}
                  className="btn-icon"
                  color="info"
                  size="sm"
                  icon = {faWhatsapp}
                  type="button"
                  onClick={async () => {
                    await sendEmail(Data?.id)
                  }}
                />
              </div>
            ),
            actions: (
              // we've added some custom button actions
              <div className="actions-right" key={Data.netId}>
                <DownloadButton
                  disabled = {!Permission.Read}
                  onClick={ async () => {
                    await viewInvoice(Data?.id)
                  }}
                  label = {"Edit"}
                />
                <DeleteButton
                  disabled = {!Permission.Delete}
                  onClick={ async () => {
                      await deleteInvoice(Data?.id, periodSelected)
                  }}
                  label = {"Delete"}
                />
              </div>
            ),
          };
          } catch (error) {
            // console.log('list render error', error.message)
            return []
          }
        })

    const [dataState, setDataState] = useState(renderTable(Table, period?.value));

    const menu = (
      <div className="flex flex-row my-3">
        <div className="p-4 flex md:flex-col mr-5">
          <PrimaryIconButton
            className="font-weight-bold"
            onClick={Add}
            title = {"Generate"}
            color="info"
            disabled = {!Permission?.Create}
          />
        </div>

        <div className="p-4 flex md:flex-col mr-5">
          <NoLabelSelect
            placeholder = {"Periode Invoice"}
            value = {period}
            option = {selectPeriod}
            onChange = {async (e) => {
              setPeriod(e)
              await getInvoice(e?.value)
            }}
          />
        </div>
        <div className="p-4 flex md:flex-col mr-5">
          <PrimaryButton
            title = {"Export"}
            color="info"
            disabled = {!Permission?.Read}
            onClick={Export}
          />
        </div>
       </div>
    )

    return (
      <>
        <main className="h-full">
          <Navbar key={RandomTextGenerator()} toggle={sidebarToggle} />

          {/* Main Content */}
          <div className="mainCard">
            <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
              <MainTable
                key={RandomTextGenerator()}
                header = {'List Invoice'}
                menu={menu}
                loading={loading}
                dataHeader={!superAdmin ? invoiceColumnsUser : invoiceColumnsSuperUser}
                dataState={dataState}
              />
              <ModalView
                // key = {RandomTextGenerator()}
                open = {showMessageSender}
                close = {handleCloseMessageSender}
                child = {MessageSenderModalContent(componentRef)}
                loading = {modalMessageSenderLoading}
                handleClose = {handleCloseMessageSender}
                handleAction = {SendEmail}
                actionTitle = {'Kirim Invoice'}
                actionColor = {'success'}
              />
              <ModalView
                key = {RandomTextGenerator()}
                open = {showPdfModal}
                close = {handleClosePdfModal}
                child = {InvoicePdfViewerModalContent(componentRef)}
                loading = {modalPdfModalLoading}
                handleClose = {handleClosePdfModal}
                handleAction = {handlePrint}
              />
            </div>
          </div>
        </main>
      </>
    )
}

export default PppoeInvoiceList;
