import { getData, setData } from "../Common"

const identifier = ['PppoeUserData', 'PppoeUserList']
const [data, list] = identifier

export function addPppoeUserData(payload) {
  setData(data, payload)
}

export function getPppoeUserData() {
 return getData(data)
}

export function addPppoeUserList(payload) {
  setData(list, payload)
}

export function getPppoeUserList() {
 return getData(list)
}

export function payloadPppoeUser(mitra,
    province,
    city,
    subDistrict,
    urban,
    userResellerId,
    name,
    firstname,
    lastname,
    ktp,
    npwp,
    phone,
    pppoeUsername,
    pppoePassword,
    addressTitle,
    addressLine,
    discount,
    enabled,
    latitude,
    longitude,
    ppppProfileId,
    description) {
    return {
        mitra,
        province,
    city,
    subDistrict,
    urban,
        userResellerId,
        name,
        firstname,
        lastname,
        ktp,
        npwp,
        phone,
        pppoeUsername,
        pppoePassword,
        addressTitle,
        addressLine,
        discount,
        enabled,
        latitude,
        longitude,
        ppppProfileId,
        description
    }
}

export function payloadPppoeUserUpdate(
    province,
    city,
    subDistrict,
    urban,
    userResellerId,
    name,
    firstname,
    lastname,
    ktp,
    npwp,
    phone,
    pppoeUsername,
    pppoePassword,
    addressTitle,
    addressLine,
    discount,
    enabled,
    latitude,
    longitude,
    description ) {
    return {
    province,
    city,
    subDistrict,
    urban,
    userResellerId,
    name,
    firstname,
    lastname,
    ktp,
    npwp,
    phone,
    pppoeUsername,
    pppoePassword,
    addressTitle,
    addressLine,
    discount,
    enabled,
    latitude,
    longitude,
    description
    }
}

export function payloadPppoeUserTrash(trash) {
    return {
        trash
    }
}

export function payloadPppoeUserPaid(isEnabled) {
    return {
        isEnabled
    }
}
