import {
  faTachometer,
  faTable,
  faStore,
  faUser,
  faGlobe,
  faWifi,
  faRoute,
  faTableList,
  faMoneyBill,
  // faMoneyBill
} from "@fortawesome/free-solid-svg-icons";
import { verifySuperAdmin } from "./Variable/Utils";

const menuDashboard = [
  {
    label: "Dashboard",
    path: "/",
    icon: faTachometer,
  }
]

const menuReseller = [
    {
    label: "Reseller Manager",
    path: "/reseller",
    icon: faStore,
  },
]

const menuUser =   [
  {
    label: "User Manager",
    path: "/user",
    icon: faUser,
  },
]

const menuRouter = [
  {
    label: "Router Manager",
    path: "/router",
    icon: faGlobe,
  },
]

const menuUserResellerSuper = [
  {
    label: 'System Manager'
  },
  ...menuUser,
  ...menuReseller,
  ...menuRouter
]

const menuUserResellerAdmin = [
  {
    label: 'System Manager'
  },
  ...menuUser,
  ...menuReseller,
  ...menuRouter
]

const menuVoucherHostpot = [
  {
    label: 'Voucher Hostpot'
  },
  {
    label: "Profile Manager",
    path: "/hostpot/profile",
    icon: faTable,
  },
  {
    label: "Hostpot User Manager",
    path: "/hostpot/user",
    icon: faWifi,
  },
]

const menuPpppoe = [
  {
    label: 'PPPOE'
  },
  {
    label: "PPPOE Profile Manager",
    path: "/pppoe/profile",
    icon: faTableList,
  },
  {
    label: "PPPOE User Manager",
    path: "/pppoe/user",
    icon: faRoute,
  },
  {
    label: "PPPOE Invoice",
    path: "/pppoe/invoice",
    icon: faMoneyBill,
  },
]

const initMenuSuperAdmin = [
  ...menuDashboard,
  ...menuUserResellerSuper,
  ...menuPpppoe,
  ...menuVoucherHostpot
];

const initMenuAdmin = [
  ...menuDashboard,
  ...menuUserResellerAdmin,
  ...menuPpppoe,
  ...menuVoucherHostpot
];

const initMenuUser = [
  ...menuDashboard,
  ...menuPpppoe,
  ...menuVoucherHostpot
];

function initMenus () {
  const { userSuper, userAdmin } = verifySuperAdmin()
  if (userSuper === true) {
    return initMenuSuperAdmin
  }
  if (userAdmin === true) {
    return initMenuAdmin
  }
  return initMenuUser
}

export default initMenus