import LoginService from '../AuthService'
import APIconsume from '../ConsumeAPI'
import { MapToParamQuery } from 'data/Variable/Utils'
import { getAccessToken } from 'data/Variable/Common'

class SubscriptionAndHistoryService extends LoginService {
    constructor() {
        super()
        const accessToken = getAccessToken()
        this._consume = new APIconsume(accessToken)
        this._url = 'Billing'
        this._sub = 'Subscription'
        this._urlRes = 'res'
    }
    // Customer Resources
    async addCustomerGroup (payload) {
        const url = `${this._urlRes}/customer-group`
        const result = await this._consume.postData(payload, url)
        return result.data
    }

    async addItemCategory (payload) {
        const url = `${this._urlRes}/item-category`
        const result = await this._consume.postData(payload, url)
        return result.data
    }

    async addAddressType (payload) {
        const url = `${this._urlRes}/address-type`
        const result = await this._consume.postData(payload, url)
        return result.data
    }

    async addItemMeasurement (payload) {
        const url = `${this._urlRes}/item-measurement`
        const result = await this._consume.postData(payload, url)
        return result.data
    }

    async addItemCurrency (payload) {
        const url = `${this._urlRes}/item-currency`
        const result = await this._consume.postData(payload, url)
        return result.data
    }

    async addStatus (payload) {
        const url = `${this._urlRes}/status`
        const result = await this._consume.postData(payload, url)
        return result.data
    }

    async addBank (payload) {
        const url = `${this._urlRes}/bank`
        const result = await this._consume.postData(payload, url)
        return result.data
    }

    async addIndoorDevice (payload) {
        const url = `${this._urlRes}/indoordevice`
        const result = await this._consume.postData(payload, url)
        return result.data
    }

    async addOutdoorDevice (payload) {
        const url = `${this._urlRes}/outdoordevice`
        const result = await this._consume.postData(payload, url)
        return result.data
    }

    async deleteCustomerGroup (name) {
        const url = `${this._urlRes}/customer-group/${name}`
        const result = await this._consume.deleteData(url)
        return result.data
    }

    async deleteItemCategory (name) {
        const url = `${this._urlRes}/item-category/${name}`
        const result = await this._consume.deleteData(url)
        return result.data
    }

    async deleteAddressType (name) {
        const url = `${this._urlRes}/address-type/${name}`
        const result = await this._consume.deleteData(url)
        return result.data
    }

    async deleteItemMeasurement (name) {
        const url = `${this._urlRes}/item-measurement/${name}`
        const result = await this._consume.deleteData(url)
        return result.data
    }

    async deleteItemCurrency (name) {
        const url = `${this._urlRes}/item-currency/${name}`
        const result = await this._consume.deleteData(url)
        return result.data
    }

    async deleteStatus (name) {
        const url = `${this._urlRes}/status/${name}`
        const result = await this._consume.deleteData(url)
        return result.data
    }

    async deleteBank (name) {
        const url = `${this._urlRes}/bank/${name}`
        const result = await this._consume.deleteData(url)
        return result.data
    }

    async deleteIndoorDevice (name) {
        const url = `${this._urlRes}/indoordevice/${name}`
        const result = await this._consume.deleteData(url)
        return result.data
    }

    async deleteOutdoorDevice (name) {
        const url = `${this._urlRes}/outdoordevice/${name}`
        const result = await this._consume.deleteData(url)
        return result.data
    }
    // Resource
    async getProvince() {
        const url = `${this._urlRes}/province/`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getCity(name) {
        const url = `${this._urlRes}/city/${name}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getSubDistrict(name) {
        const url = `${this._urlRes}/sub-district/${name}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getUrban(name) {
        const url = `${this._urlRes}/urban/${name}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getCustomerGroup() {
        const url = `${this._urlRes}/customer-group`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getOwner() {
        try {
            const url = `${this._urlRes}/owner`
            const result = await this._consume.getData(url)
            return result.data
        } catch (error) {
            return []
        }
    }

    async getAddressType() {
        try {
            const url = `${this._urlRes}/address-type`
            const result = await this._consume.getData(url)
            return result.data
        } catch (error) {
            return []
        }
    }

    async getItemMeasurement() {
        try {
            const url = `${this._urlRes}/item-measurement`
            const result = await this._consume.getData(url)
            return result.data
        } catch (error) {
            return []
        }
    }

    async getItemCurrency() {
        try {
            const url = `${this._urlRes}/item-currency`
            const result = await this._consume.getData(url)
            return result.data
        } catch (error) {
            return []
        }
    }

    async getItemCategory() {
        try {
            const url = `${this._urlRes}/item-category`
            const result = await this._consume.getData(url)
            return result.data
        } catch (error) {
            return []
        }
    }

    async getCustomerSelect(mitraid = '') {
        try {
            const url = `${this._urlRes}/customer?mitraid=${mitraid}`
            const result = await this._consume.getData(url)
            return result.data
        } catch (error) {
            return []
        }
    }

    async getBillingSelect(mitraid = '') {
        try {
            const url = `${this._urlRes}/subscription?mitraid=${mitraid}`
            const result = await this._consume.getData(url)
            return result.data
        } catch (error) {
            return []
        }
    }

    async getAddressSelect(customerid = '', mitraid = '') {
        try {
            const url = `${this._urlRes}/address?customerId=${customerid}&mitraid=${mitraid}`
            const result = await this._consume.getData(url)
            return result.data
        } catch (error) {
            return []
        }
    }

    async getItemSelect(routerid = '', mitraid = '', category = '') {
        try {
            const url = `${this._urlRes}/item?id=${routerid}&category=${category}&mitraid=${mitraid}`
            // console.log(url, routerid, mitraid, category)
            const result = await this._consume.getData(url)
            return result.data
        } catch (error) {
            return []
        }
    }

    async getStatusSelect() {
        try {
            const url = `${this._urlRes}/status`
            const result = await this._consume.getData(url)
            return result.data
        } catch (error) {
            return []
        }
    }

    async getBankSelect() {
        try {
            const url = `${this._urlRes}/bank`
            const result = await this._consume.getData(url)
            return result.data
        } catch (error) {
            return []
        }
    }

    async getBillingStatusSelect() {
        try {
            const url = `${this._urlRes}/billing-status`
            const result = await this._consume.getData(url)
            return result.data
        } catch (error) {
            return []
        }
    }

     async getPaymentStatusSelect() {
        try {
            const url = `${this._urlRes}/payment-status`
            const result = await this._consume.getData(url)
            return result.data
        } catch (error) {
            return []
        }
    }

    async getUserTypeSelect() {
        try {
            const url = `${this._urlRes}/user-type`
            const result = await this._consume.getData(url)
            return result.data
        } catch (error) {
            return []
        }
    }

    async getRoleSelect() {
        try {
            const url = `${this._urlRes}/role`
            const result = await this._consume.getData(url)
            return result.data
        } catch (error) {
            return []
        }
    }

    async sendEmail(payload) {
        const url = 'Email/Send'
        const result = await this._consume.postData(payload, url)
        return result.data
    }

    async sendEmailBack(payload) {
        const url = 'Email/Send/in'
        const result = await this._consume.postData(payload, url)
        return result.data
    }

    async sendEmailAll(mitra, notify = false) {
        const payload = {mitra, override: notify}
        const url = 'Email/Send/all'
        const result = await this._consume.postData(payload, url)
        return result.data
    }

    async exportCSV (payload, filename) {
        const url = `export/csv`
        const result = await this._consume.GlobalDownloadPost(payload, url, filename)
        return result
    }

     async exportExcel (payload, filename) {
        const url = `export/excel`
        const result = await this._consume.GlobalDownloadPost(payload, url, filename)
        return result
    }

    async exportPDF (id, filename) {
        const url = `Billing/Invoice/pdf/`+ id
        const filenameFinal = filename || 'Invoice-Import'
        const result = await this._consume.GlobalDownload(url, filenameFinal)
        return result.data
    }

    async syncRouter () {
        const url = `Item/sync`

        const payload = {}
        const result = await this._consume.postData(payload, url)
        return result.data
    }

    async syncSubs () {
        const url = `Subscription/sync`

        const payload = {}
        const result = await this._consume.postData(payload, url)
        return result.data
    }

    async syncVa () {
        const url = `VirtualAccount/sync`

        const payload = {}
        const result = await this._consume.postData(payload, url)
        return result.data
    }

    async combineSync () {
        await this.syncRouter()
        await this.syncSubs()
    }

    async getIndoorDeviceSelect() {
        try {
            const url = `${this._urlRes}/indoordevice`
            const result = await this._consume.getData(url)
            return result.data
        } catch (error) {
            return []
        }
    }

    async getOutdoorDeviceSelect() {
        try {
            const url = `${this._urlRes}/outdoordevice`
            const result = await this._consume.getData(url)
            return result.data
        } catch (error) {
            return []
        }
    }

    async verifyCustomerIdAvailable(id) {
        try {
            const url = `${this._urlRes}/verifyCustomerId/${id}`
            const result = await this._consume.getData(url)
            return result.data
        } catch (error) {
            return {data: false}
        }
    }

    async verifySubsIdAvailable(id) {
        try {
            const url = `${this._urlRes}/verifySubsId/${id}`
            const result = await this._consume.getData(url)
            return result.data
        } catch (error) {
            return {data: false}
        }
    }

    async importCustomer (data) {
        const url = 'customer/import'
        const type = 'multipart/form-data;'
        const result = await this._consume.postData(data, url, type)
        return result.data
    }

    async addLogisticType (payload) {
        const url = `${this._urlRes}/LogisticType`
        const result = await this._consume.postData(payload, url)
        return result.data
    }

    async deleteLogisticType (name) {
        const url = `${this._urlRes}/LogisticType/${name}`
        const result = await this._consume.deleteData(url)
        return result.data
    }

    async getLogisticTypeOption() {
        try {
            const url = `${this._urlRes}/LogisticType`
            const result = await this._consume.getData(url)
            return result.data
        } catch (error) {
            return []
        }
    }

    async getLogisticItemOption(mitra = '') {
        try {
            const payload = { mitra }
            const param = MapToParamQuery(payload)
            const url = `${this._urlRes}/LogisticItem` + param
            const result = await this._consume.getData(url)
            return result.data
        } catch (error) {
            return []
        }
    }

    async getLogisticWarehouseOption(mitra = '') {
        try {
            const payload = { mitra }
            const param = MapToParamQuery(payload)
            const url = `${this._urlRes}/LogisticWarehouse` + param
            const result = await this._consume.getData(url)
            return result.data
        } catch (error) {
            return []
        }
    }

    async getLogisticSupplierOption(mitra = '') {
        try {
            const payload = { mitra }
            const param = MapToParamQuery(payload)
            const url = `${this._urlRes}/LogisticSupplier` + param
            const result = await this._consume.getData(url)
            return result.data
        } catch (error) {
            return []
        }
    }

    async getItemAddOnSelect(routerid = '', mitraid = '', category = '') {
        try {
            const url = `${this._urlRes}/AddOnItem?id=${routerid}&category=${category}&mitraid=${mitraid}`
            // console.log(url, routerid, mitraid, category)
            const result = await this._consume.getData(url)
            return result.data
        } catch (error) {
            return []
        }
    }

    async getSubscriptionAddOnSelect(networkId = '') {
        try {
            const url = `${this._urlRes}/AddOnSubscription?id=${networkId}`
            const result = await this._consume.getData(url)
            return result.data
        } catch (error) {
            return []
        }
    }

    async getSubscriptionHistoryAddOnSelect(historyId = '') {
        try {
            const url = `${this._urlRes}/AddOnSubscriptionHistory?id=${historyId}`
            const result = await this._consume.getData(url)
            return result.data
        } catch (error) {
            return []
        }
    }
    // Billing and Subscription
    async addBilling (payload) {
        const result = await this._consume.postData(payload, `${this._sub}`)
        return result.data
    }

    async getBilling (name) {
        const url = `${this._sub}/${name}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getSubscriptionHistory (id) {
        const url = `${this._sub}/history/${id}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getAllBilling (len = 'ALL', page = '') {
        let url = `${this._sub}/view?line=${len}&page=${page}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getAllSubscriptionCustomer (id = '', line = '', page = '') {
        let url = `${this._sub}/customer?id=${id}&line=${line}&page=${page}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getAllSubscriptionHistory (id = '', line = '', page = '') {
        let url = `${this._sub}/history/view?customerId=${id}&line=${line}&page=${page}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async putSubscriptionHistory (name, payload) {
        const url = `${this._sub}/history/${name}`
        console.log(url, payload)
        const result = await this._consume.putData(payload, url)
        return result.data
    }

    async putBilling (name, payload) {
        const url = `${this._sub}/${name}`
        const result = await this._consume.putData(payload, url)
        return result.data
    }

    async deleteBilling (name) {
        const url = `${this._sub}/${name}`
        const result = await this._consume.deleteData(url)
        return result.data
    }

    async addInvoice (payload) {
        const result = await this._consume.postData(payload, `${this._url}/Invoice`)
        return result.data
    }

    async renewInvoiceVa (payload) {
        const result = await this._consume.postData(payload, `${this._url}/Invoice/virtualAccount/renew`)
        return result.data
    }

    async getInvoice (id) {
        const url = `${this._url}/Invoice/${id}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getAllInvoice (len = 'ALL') {
        const url = `${this._url}/Invoice/view/${len}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getAllInvoiceGroup (len = '', period = '', page = '') {
        let url = `${this._url}/Invoice/view?line=${len}&period=${period}&page=${page}`
        const result = await this._consume.getData(url)
        return result.data
    }


    async patchInvoice (id, payload) {
        const url = `${this._url}/Invoice/${id}`
        const result = await this._consume.patchData(payload, url)
        return result.data
    }

    async deleteInvoice (id) {
        const url = `${this._url}/Invoice/${id}`
        const result = await this._consume.deleteData(url)
        return result.data
    }


    async getDefaultTemplate(id) {
        const url = `${this._url}/Invoice/mail-template/${id}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getPdfTemplate(id) {
        const url = `${this._url}/Invoice/pdf-template/${id}`
        const result = await this._consume.getData(url)
        return result.data
    }

    // Get Invoice Period by CustomerId
    async getInvoicePeriod(id) {
        const url = `res/invoice-history/${id}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getInvoiceListPeriod(mitra = '') {
        const url = `res/invoice-period-list?mitra=${mitra}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async downloadInvoice (payload) {
        const { mitra, type, period } = payload
        const url = `export/invoice`
        const filename = 'Invoice-' + mitra + '-' + type + '-' + period + '-Download_Date-' + new Date().toDateString()
        const result = await this._consume.GlobalDownloadPost(payload, url, filename)
        return result
    }
}

export default SubscriptionAndHistoryService