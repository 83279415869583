import Select from 'react-select'

function NoLabelSelect({value, onChange, placeholder, isDisabled, option}) {
    return (
        <>
            <Select
                placeholder= {placeholder || 'Select Here'}
                name="singleSelect"
                value={value}
                options={option}
                isDisabled = {isDisabled}
                onChange={onChange}
            />
        </>
    )
}

export default NoLabelSelect