export default function DefaultInput({id, label = 'Default Input', placeholder, value, onChange, disabled = false, type = 'text'}) {
    return (
        <>
            {/* Form Default */}
            <div className>
                <label htmlFor="defaultInput" className="text-sm text-gray-600">
                    {label}
                </label>
                <input
                    id={id || label}
                    type={type}
                    name="defaultInput"
                    // onChange={(e) => setEmail(e.target.value)}
                    className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full md:py-2 py-3 focus:outline-none focus:border-emerald-400 mt-1"
                    placeholder={placeholder || label}
                    value={value}
                    onChange={onChange}
                    disabled = {disabled}
                    />
            </div>
        </>
    )
}