import { getData, setData } from "../Common"

const identifier = ['hostpotProfileData', 'hostpotProfileList', 'hostpotProfileInternetMeasurementSelect', 'hostpotProfileTimeMeasurementSelect']
const [
    data,
    list,
    hostpotProfileInternetMeasurement,
    hostpotProfileTimeMeasurement
] = identifier

export function addHostpotProfileData(payload) {
  setData(data, payload)
}

export function getHostpotProfileData() {
 return getData(data)
}

export function addHostpotProfileList(payload) {
  setData(list, payload)
}

export function getHostpotProfileList() {
 return getData(list)
}

export function addhostpotProfileInternetMeasurementData(payload) {
  setData(hostpotProfileInternetMeasurement, payload)
}

export function gethostpotProfileInternetMeasurementData() {
 return getData(hostpotProfileInternetMeasurement)
}

export function addhostpotProfileTimeMeasurementData(payload) {
  setData(hostpotProfileTimeMeasurement, payload)
}

export function gethostpotProfileTimeMeasurementData() {
 return getData(hostpotProfileTimeMeasurement)
}

export function payloadHostpotProfile(mitra, routerId, name, buyingPrice, sellingPrice, pool, ip, subnet, sharedUser, rateUp, rateDown, priority, rateUpMeasurement, rateDownMeasurement, lockMac, timeLimit, quotaLimit, timeLimitMeasurement, quotaLimitMeasurement, description) {
    return {
        mitra, routerId, name, buyingPrice, sellingPrice, pool, ip, subnet, sharedUser, rateUp, rateDown, priority, rateUpMeasurement, rateDownMeasurement, lockMac, timeLimit, quotaLimit, timeLimitMeasurement, quotaLimitMeasurement, description
    }
}

export function payloadHostpotProfileUpdate(name,
        buyingPrice,
        sellingPrice,
        pool,
        ip,
        subnet,
        sharedUser,
        rateUp,
        rateDown,
        priority,
        rateUpMeasurement,
        rateDownMeasurement,
        lockMac,
        timeLimit,
        quotaLimit,
        timeLimitMeasurement,
        quotaLimitMeasurement,
        description) {
    return {
        name,
        buyingPrice,
        sellingPrice,
        pool,
        ip,
        subnet,
        sharedUser,
        rateUp,
        rateDown,
        priority,
        rateUpMeasurement,
        rateDownMeasurement,
        lockMac,
        timeLimit,
        quotaLimit,
        timeLimitMeasurement,
        quotaLimitMeasurement,
        description
    }
}

export function payloadHostpotProfileTrash(trash) {
    return {
        trash
    }
}
