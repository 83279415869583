import PrimaryButton from "components/Button/PrimaryButton"
import DefaultInput from "components/Input/DefaultInput"
import LoadingScreen from "components/Loader/LoadingScreen"
import Navbar from "./../../../components/Navbar/Index"
import { CatchError } from "data/Handler/Exceptions/exceptions"
import SubscriptionAndHistoryService from "data/Handler/Pppoe/SubscriptionAndHistoryService"
import { PriceFormat } from "data/Variable/Common"
import { getRefreshToken } from "data/Variable/Common"
import { Datestr } from "data/Variable/Utils"
import { DateTimestr } from "data/Variable/Utils"
import { payDate } from "data/Variable/Utils"
import { parse } from "postcss"
import { forwardRef, useEffect, useRef, useState } from "react"
import { useLocation, useOutletContext } from "react-router-dom"
import { useReactToPrint } from "react-to-print"
import { Card, CardBody, CardHeader, CardTitle, Col, Label, Modal, Row, Table } from "reactstrap"

function PppoeInvoiceUpdate(props) {
  const [sidebarToggle] = useOutletContext()
  const subscriptionAndHistoryService =  new SubscriptionAndHistoryService()
  const refreshToken = getRefreshToken()
  const location = useLocation()
  const { state } = location
  const [InvoiceData, invoiceOnChange] = useState(state)
  const [Preview, PreviewOnChange] = useState(() => {
    return ( <Label>No Data</Label> )
  })
  // console.log(InvoiceData)
  // const customerService = new CustomerService()
  const customerInvoice = InvoiceData.invoice
  const customerData = InvoiceData.customer
  const invoice = customerData.customer
  const id = customerInvoice[0].invoiceId
  const owner = customerInvoice[0].owner
  const created = customerInvoice[0].creation
  const modified = customerInvoice[0].modified
  const start = customerInvoice[0].start
  const end = customerInvoice[0].end
  const due = customerInvoice[0].due
  const createdBy = customerInvoice[0].createdBy
  const modifiedBy = customerInvoice[0].modifiedBy
 // const [payof, payofOnChange] = useState(!!customerInvoice[0].paid)
  const [description] = useState(customerInvoice[0].description)
  const payment = payDate(customerInvoice[0].paid)
  const [show, setShow] = useState(false);
  const [download,/* setDownload */] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const Price = []

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    getInvoice()
  }, [])

  const PrintDocument = forwardRef((props, ref) => {
    return (<div ref={ref} className="marginprint bg-light">
    <div className="preview">
      {Preview}
    </div>
    </div>)
  })


  const getInvoice =  async() => {
    try {
      const dat =  await subscriptionAndHistoryService.getInvoice(id)
      const Preview = await subscriptionAndHistoryService.getPdfTemplate(id)
      const result = dat.data
      // addInvoiceData(result)
      invoiceOnChange(result)
      PreviewOnChange(() => {
        return parse(Preview)
      })
    } catch (error) {
    return await CatchError(error, props, refreshToken)
  }}

  // const exportInvoicePDF = async(id) => {
  //   try {
  //     setDownload(true)
  //     const rawData = await customerService.exportPDF(id)
  //     swal('Success', `${invoice} Download`, 'success').then(() =>{
  //      setDownload(false)
  //      handleClose()
  //      return rawData
  //    })
  //   } catch (error) {
  //     setDownload(false)
  //   }
  // }

  const PdfModal = ({open, close}) => {
    return (
      <Modal
        show={open}
        onHide={close}
        backdrop= {true}
        dialogClassName="modal-90w"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton = {false} className="modal-color">
          <Modal.Title>Preview Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-color">
        <LoadingScreen children={<PrintDocument ref={componentRef} />} loading={download}/>
        </Modal.Body>
        <Modal.Footer  className="modal-color">
           {() => {if(download === true) {
            return (
              <Label>
                Loading...
              </Label>
            )
          } else {
            <Label>
              PDF
            </Label>
          }}}
          <PrimaryButton variant="primary"  className="bg-danger bold" onClick={handleClose} disabled = {download}>
            Close
          </PrimaryButton>
          <PrimaryButton variant="primary" className="bg-success bold" onClick={handlePrint} disabled = {download}>Print</PrimaryButton>
          {/* <Button variant="primary" className="bg-primary" onClick={() => exportInvoicePDF(id)} disabled = {download}>Download</Button> */}
        </Modal.Footer>
      </Modal>
    )
  }

    const CustomerInvoiceList = () => {
      const totalPrc = InvoiceData.total
      return (
        <>
        <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Customer Subscription</CardTitle>
              </CardHeader>
              <CardBody>
                <Table  responsive bordered hover>
                  <thead className="header">
                    <tr>
                      <th colSpan={1} >NetId</th>
                      <th colSpan={2} >Nama Produk</th>
                      <th colSpan={1} className="text-left">Restitusi Subscription</th>
                      <th colSpan={1} className="text-left">Biaya Admin</th>
                      <th colSpan={2} >Tipe</th>
                      <th colSpan={1} className="text-center">Uplink</th>
                      <th colSpan={1} className="text-center">Downlink</th>
                      <th colSpan={1} className="text-left">Harga</th>
                      <th colSpan={1} className="text-left">PPN</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customerInvoice.map((Invoice) => {
                      Price.push(Invoice.price)
                      return (
                      <tr key={Invoice.networkId}>
                      <td colSpan={1}>{Invoice.networkId}</td>
                      <td colSpan={2}>{Invoice.itemName}</td>
                      <td colSpan={1} className="text-left">{PriceFormat(Invoice.discountSubs, Invoice.currency)}</td>
                      <td colSpan={1} className="text-left">{PriceFormat(Invoice.administrationFee, Invoice.currency)}</td>
                      <td colSpan={2}>{Invoice.category}</td>
                      <td colSpan={1} className="text-center">{`${Invoice.uplink} ${customerData.measurement}`}</td>
                      <td colSpan={1} className="text-center">{`${Invoice.downlink} ${customerData.measurement}`}</td>
                      <td colSpan={1} className="text-left">{PriceFormat(Invoice.price, Invoice.currency)}</td>
                      <td colSpan={1} className="text-left">{PriceFormat(Invoice.tax, Invoice.currency)}</td>
                    </tr>)
                    })}
                    <tr >
                      <td colSpan={8}></td>
                      <td colSpan={1} className="td-total">Total</td>
                      <td colSpan={3} className="td-price">{PriceFormat(totalPrc, customerInvoice[0].currency)}</td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </>
      )
    }
    return (
      <>
        <main className="h-full">
          <Navbar toggle={sidebarToggle} />
          <Row>
            <Col md="12">
              {CustomerInvoiceList()}
              <Row md = "6">
                <Col md="2">
                  <PrimaryButton onClick = {handleShow} className="bg-info bold">Exports PDF</PrimaryButton>
                </Col>
              </Row>
              <Card>
                <CardHeader>
                  <h5 className="title">Detail Invoice</h5>
                </CardHeader>
                <CardBody>
                <PdfModal open={show} close={handleClose}/>
                  <DefaultInput
                    label = {'Customer'}
                    value = {invoice}
                    placeholder="Name"
                    type="text"
                  />
                  <DefaultInput
                    label = {'Mitra'}
                    placeholder="Mitra"
                    value = {owner}
                    type="email"
                  />
                  <DefaultInput
                    label = {'Created'}
                    placeholder="Created"
                    value = {Datestr(created)}
                    type="text"
                  />
                  <DefaultInput
                    label = {'modified'}
                    placeholder="Modified"
                    value = {Datestr(modified)}
                    type="datetime"
                  />
                  <DefaultInput
                    label = {'Created By'}
                    placeholder="Created By"
                    value = {createdBy}
                    type="email"
                  />
                  <DefaultInput
                    label = {'modified By'}
                    placeholder="Modified By"
                    value = {modifiedBy}
                    type="email"
                  />
                  <DefaultInput
                    label = {'Tanggal Pembayaran'}
                    placeholder="pembayaran"
                    value = {payment}
                    type="text"
                  />
                  <DefaultInput
                    label = {'Tanggal Mulai'}
                    placeholder="Tanggal Mulai"
                    value = {DateTimestr(start)}
                    type="text"
                  />
                  <DefaultInput
                    label = {'Tanggal Akhir'}
                    placeholder="Tanggal Akhir"
                    value = {DateTimestr(end)}
                    type="text"
                  />
                  <DefaultInput
                    label = {'Jatuh Tempo'}
                    placeholder="Jatuh Tempo"
                    value = {DateTimestr(due)}
                    type="text"
                  />
                  <DefaultInput
                    label = {'Deskripsi'}
                    placeholder="Deskripsi Invoice"
                    value = {description}
                    onChange = {() => {}}
                    type="textarea"
                  />
                </CardBody>
              </Card>
              <Row>
              </Row>
            </Col>
          </Row>
        </main>
      </>
    );
}


export default PppoeInvoiceUpdate;
