import React, { useEffect, useState } from "react";
import Navbar from "./../../../components/Navbar/Index";
import { useNavigate, useOutletContext } from "react-router-dom";
import DefaultInput from "../../../components/Input/DefaultInput";
import SelecttWithUserTier from "../../../components/Input/SelectWithUserTier";
import HostpotProfileService from "data/Handler/HostpotVoucher/HostpotProfileService";
import RouterNasService from "data/Handler/RouterNasService";
import { getUserData } from "data/Variable/UserVariable";
import { getOwner } from "data/Variable/UserVariable";
import { gethostpotProfileTimeMeasurementData } from "data/Variable/HostpotVoucher/HostpotProfile";
import { gethostpotProfileInternetMeasurementData } from "data/Variable/HostpotVoucher/HostpotProfile";
import { getRouterSelect } from "data/Variable/Common";
import { payloadHostpotProfile } from "data/Variable/HostpotVoucher/HostpotProfile";
import SelectBasic from "components/Input/SelectBasic";
import Checklist from "components/Button/Checklist";
import swal from "sweetalert";
import { CatchError } from "data/Handler/Exceptions/exceptions";
import { PriceFormat } from "data/Variable/Common";
import PrimaryIconButton from "components/Button/PrimaryIconButton";
import LoadingScreen from "components/Loader/LoadingScreen";
import { RandomTextGenerator } from "data/Variable/Utils";
import { GlobalHeader } from "components/Datatables/GlobalHeader";

function HostpotProfileForm(props) {
  const [sidebarToggle] = useOutletContext();
  const navigate = useNavigate()
  const hostpotProfileService =  new HostpotProfileService()
  const routerService = new RouterNasService()
  const userData = getUserData()
  const ownerData = getOwner()
  const timeMeasurement = gethostpotProfileTimeMeasurementData()
  const internetMeasurement = gethostpotProfileInternetMeasurementData()
  const [routerSelect, setRouterSelect] = useState(getRouterSelect())
  const [poolSelect, setPoolSelect] = useState([])
  const [mitra, mitraOnChange] = useState(ownerData.find(own => own.value === userData.organization))
  const [loading, loadingOnChange ] = useState(false)
  const [changeMitra, changeMitraOnChange] = useState(false)
  const [name, setName] = useState('')
  const [buyingPrice, setBuyingPrice] = useState(0)
  const [sellingPrice, setSellingPrice] = useState(0)
  const [pool, setPool] = useState('')
  const [ip, setIp] = useState('0.0.0.0')
  const [subnet, setSubnet] = useState(24)
  const [sharedUser, setSharedUser] = useState(1)
  const [rateUp, setRateUp] = useState(0)
  const [rateDown, setRateDown] = useState(0)
  const [priority, setPriority] = useState(1)
  const [rateUpMeasurement, setRateUpMeasurement] = useState(internetMeasurement[1])
  const [rateDownMeasurement, setRateDownMeasurement] = useState(internetMeasurement[1])
  const [lockMac, setLockMac] = useState(false)
  const [timeLimit, setTimeLimit] = useState(0)
  const [quotaLimit, setQuotaLimit] = useState(0)
  const [timeLimitMeasurement, setTimeLimitMeasurement] = useState(timeMeasurement[2])
  const [quotaLimitMeasurement, setQuotaLimitMeasurement] = useState(internetMeasurement[1])
  const [description, setDescription ] = useState('')
  const [router, setRouter] = useState('')
  const [useProfilePPPlist, setUseProfilePPPlist] = useState(false)
  const [RouterpppProfile, SetRouterpppProfile] = useState({})

  const getRouterPPP =  async(routerId) => {
    const result =  await routerService.getpppProfileSelect(routerId)
    setPoolSelect(result.data)
    return result.data
  }

  const getRouter = async(mitraid) => {
    const dat = await routerService.getRouterSelect(mitraid)
    const result = dat.data
    setRouterSelect(result)
    return result
  }

  useEffect(() => {
    getRouter(mitra?.mitraid)
  }, [])

  const submit = async(e) => {
    e.preventDefault()
    try {
      loadingOnChange(true)
      const payload = payloadHostpotProfile(mitra.mitraid, router.value, name, buyingPrice, sellingPrice, pool, ip, subnet, sharedUser, rateUp, rateDown, priority, rateUpMeasurement.value, rateDownMeasurement.value, lockMac, timeLimit, quotaLimit, timeLimitMeasurement.value, quotaLimitMeasurement.value, description)
      const result = await hostpotProfileService.post(payload)
      await swal(result.status, result.message, "success")
      loadingOnChange(false)
      return navigate('/hostpot/profile')
    } catch (error) {
      loadingOnChange(false)
      return await CatchError(error, props)
  }}

  const child = (
    <>
              {/* Main Content */}
        <div className="mainCard">
          <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
            <form>
              <GlobalHeader
                header = {'Tambah Profile Hostpot'}
              />
                  <SelecttWithUserTier
                    key={"1"}
                    label = {'Mitra'}
                    value = {mitra}
                    onChange = {async (e) => {
                      await getRouter(e.mitraid)
                      mitraOnChange(e)
                    }}
                    disabled = {changeMitra}
                    options = {ownerData}
                    checkText = {'Change Mitra'}
                    checkValue = {changeMitra}
                    checkValueOnChange = {changeMitraOnChange}
                  />
                  <DefaultInput
                    key={"2"}
                    label = {'name'}
                    value = {name}
                    onChange = {(e) => setName(e.target.value)}
                    type
                  />
                    <div>
                      <SelectBasic
                        label="Lokasi Router"
                        name="singleSelect"
                        value = {router}
                        option={routerSelect}
                        onChange={async (e) => {
                          setRouter(e)
                          await getRouterPPP(e.value)
                        }}
                      />
                      {!router.status ? (
                          <label className="error">
                            Router Offline
                          </label>
                        ) : <label className="success">
                            Router Online
                      </label>}
                  </div>
                      <DefaultInput
                        key={"3"}
                        label = {'Harga Beli'}
                        value = {buyingPrice}
                        onChange = {(e) => {
                          if (e.target.value >= 0) {
                            setBuyingPrice(e.target.value)
                          }
                        }}
                        type = {'number'}
                      />
                      <DefaultInput
                        key={"4"}
                        label = {'Price Display'}
                        value = {PriceFormat(buyingPrice, 'idr')}
                        disabled
                        onChange = {() => {}}
                      />
                      <DefaultInput
                        key={"5"}
                        label = {'Harga Jual'}
                        value = {sellingPrice}
                        onChange = {(e) => {
                          if (e.target.value >= 0) {
                            setSellingPrice(e.target.value)
                          }
                        }}
                        type = {'number'}
                      />
                      <DefaultInput
                        key={"6"}
                        label = {'Price Display'}
                        value = {PriceFormat(sellingPrice, 'idr')}
                        disabled
                        onChange = {() => {}}
                      />
                  {!useProfilePPPlist ? (
                      <>
                         <DefaultInput
                           key={"8"}
                           label = {'Nama Pool'}
                           value = {pool}
                           onChange = {(e) => setPool(e.target.value)}
                         />
                      </>
                    ) : (
                      <>
                      <SelectBasic
                        key={"9"}
                        label={" Select PPP From Router"}
                        value={RouterpppProfile}
                        option={poolSelect}
                        onChange={(e) => {
                          SetRouterpppProfile(e)
                          setPool(e.value)
                          setIp(e.ip)
                          setSubnet(Number(e.subnet))
                          }} />
                      </>
                    )
                  }
                  <DefaultInput
                    key={"8"}
                    label = {'IP Address'}
                    value = {ip}
                    onChange = {(e) => setIp(e.target.value)}
                  />
                  <DefaultInput
                    key={"9"}
                    label = {'Subnet'}
                    value = {subnet}
                    onChange = {(e) => {
                        if (e.target.value >= 0 && e.target.value <= 32) {
                          setSubnet(e.target.value)
                        }
                      }
                    }
                    type = {'number'}
                  />
                  <Checklist
                    key={"9"}
                    text = {"Memakai Pool Router"}
                    value = {useProfilePPPlist}
                    onChange = { async (e) => {
                      setUseProfilePPPlist(e.target.checked)
                      setPool('')
                      setIp('0.0.0.0')
                      setSubnet(24)
                      if (!e.target.checked) {
                        SetRouterpppProfile([])
                        return e
                      }
                      await getRouterPPP(router.value)
                    }}
                  />
                      <DefaultInput
                        key={"10"}
                        label = {'Kecepatan Upload'}
                        value = {rateUp}
                        onChange = {(e) => {
                          if (e.target.value >= 0) {
                            setRateUp(e.target.value)
                          }
                        }
                        }
                        type = {'number'}
                      />
                      <SelectBasic
                        key={"11"}
                        label = {'Upload Measurement'}
                        value = {rateUpMeasurement}
                        onChange = {setRateUpMeasurement}
                        option = {internetMeasurement}
                      />
                      <DefaultInput
                        key={"12"}
                        label = {'Kecepatan Download'}
                        value = {rateDown}
                        onChange = {(e) => {
                          if (e.target.value >= 0) {
                            setRateDown(e.target.value)
                          }
                        }}
                        type = {'number'}
                      />
                      <SelectBasic
                        key={"13"}
                        label = {'Download Measurement'}
                        value = {rateDownMeasurement}
                        onChange = {setRateDownMeasurement}
                        option = {internetMeasurement}
                      />
                  <DefaultInput
                    key={"14"}
                    label = {'Priority'}
                    value = {priority}
                    onChange = {(e) => {
                      if (e.target.value > 0 && e.target.value <= 10) {
                        setPriority(e.target.value)
                      }
                    }}
                    type = {'number'}
                  />
                  <DefaultInput
                    key={"15"}
                    label = {'Shared User'}
                    value = {sharedUser}
                    onChange = {(e) => {
                      if (e.target.value >= 0) {
                        setSharedUser(e.target.value)
                      }
                    }}
                    type = {'number'}
                  />
                  <DefaultInput
                    key={"16"}
                    label = {'Limit Quota'}
                    value = {quotaLimit}
                    onChange = {(e) => {
                      if (e.target.value >= 0) {
                        setQuotaLimit(e.target.value)
                      }
                    }}
                    type = {'number'}
                  />
                  <SelectBasic
                    key={"17"}
                    label = {'Quota Measurement'}
                    value = {quotaLimitMeasurement}
                    onChange = {setQuotaLimitMeasurement}
                    option = {internetMeasurement}
                  />
                  <DefaultInput
                    key={"18"}
                    label = {'Limit Waktu'}
                    value = {timeLimit}
                    onChange = {(e) => {
                      if (e.target.value >= 0) {
                        setTimeLimit(e.target.value)
                      }
                    }}
                    type = {'number'}
                  />
                  <SelectBasic
                    key={"19"}
                    label = {'Time Measurement'}
                    value = {timeLimitMeasurement}
                    onChange = {setTimeLimitMeasurement}
                    option = {(e) => timeMeasurement(e.target.value)}
                  />
                  <Checklist
                    key = {"20"}
                    text = {'Lock MAC Address'}
                    value = {lockMac}
                    onChange = {(e) => {
                      setLockMac(e.target.checked)
                    }}
                  />
                  <DefaultInput
                    key={"21"}
                    label = {'Deskripsi'}
                    value = {description}
                    onChange = {(e) => setDescription(e.target.value)}
                    type = {'textarea'}
                  />
              <div className="mt-6 flex flex-row gap-4">
                <PrimaryIconButton
                  title="Save"
                  onClick = {submit}
                />
              </div>
            </form>
          </div>
        </div>
    </>
  )
  return (
    <>
      <main className="h-full">
        <Navbar toggle={sidebarToggle} />
        <LoadingScreen
          // key = {RandomTextGenerator()}
          children={child}
          loading = {loading}
          text={"Saving..."}
        />
      </main>
    </>
  );
}

export default HostpotProfileForm;
