import React, { useEffect, useState } from "react";
import DashboardHeader from "./../../components/Other/DashboardHeader.jsx";
import avatar from "./../../Assets/img/no-photo.jpg";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { RandomTextGenerator } from "data/Variable/Utils.js";
import { getUserData } from "data/Variable/UserVariable.js";
import DashboardService from "data/Handler/HostpotVoucher/DashboardService.js";
import { DashboardCard } from "components/Other/DashboardCard.jsx";
import DashboardChart from "components/Other/DashboardChart.jsx";
import { PriceFormat } from "data/Variable/Common.js";
import { Datestr } from "data/Variable/Utils.js";
import { CatchError } from "data/Handler/Exceptions/exceptions.js";
import UserService from "data/Handler/UserService.js";

function Dashboard(props) {
  const location = useLocation()
  const [dashboard, setDashboardState] = useState(location.state)
  const [sidebarToggle] = useOutletContext();
  const today = new Date()
  const userService =  new UserService()
  const navigate = useNavigate()

  const userData = getUserData()
  const dashboardService = new DashboardService()

  const fetchDashboard = async() => {
    const { data } = await dashboardService.getDashboard()
    setDashboardState(data)
    return data
  }

  useEffect(() => {
    fetchDashboard()
  }, [])

  const { username, id: userId } = userData
  const dashboardData = dashboard?.data
  const currency = 'idr'
  const date = Datestr(today)

  const dataOS = [
    {
      title: "Pendapatan Tahunan",
      date,
      os: PriceFormat(dashboardData?.buyingPricePaid, currency),
      gs: 0,
      percentage: 0,
      color: "cardInfo",
    },
    {
      title: "Pendapatan Bulanan",
      date,
      os: PriceFormat(dashboardData?.sellingPricePaid, currency),
      gs: 0,
      percentage: 0,
      color: "cardWarning",
    },
    {
      title: "Realize Profit",
      date,
      os: PriceFormat(dashboardData?.realizeProfit, currency),
      gs: 0,
      percentage: 0,
      color: "cardSuccess",
    }
  ];

  const viewUser = async (id) => {
    try {
      console.log('Clicked View')
      const result = await userService.getUser(id)
      return navigate('/user/profile', { state: result.data })
    } catch (error) {
      return await await CatchError(error, props)
    }
  }

  return (
    <>
      <main className="h-full">
        <DashboardHeader
          key={RandomTextGenerator()}
          toggle={sidebarToggle}
          avatar={avatar}
          date = {date}
          viewUser={() => viewUser(userId)}
          user={{ name: username }}
        />

        <DashboardChart
          chartData = {dashboard?.monthlyChart}
          date = {today}
          profitability = {0}
          profit = {dashboardData?.realizeProfit}
        />

        <DashboardCard
          title = {'Quick Access Report'}
          data = {dataOS}
        />
      </main>
    </>
  );
}

export default Dashboard;
