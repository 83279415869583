import swal from "sweetalert";
import React, { useEffect, useState } from "react";
import Navbar from "./../../components/Navbar/Index";
import { useNavigate, useOutletContext } from "react-router-dom";
import DefaultInput from "../../components/Input/DefaultInput";
import SelecttWithUserTier from "../../components/Input/SelectWithUserTier";
import Checklist from "components/Button/Checklist";
import SelectBasic from "components/Input/SelectBasic";
import { verifyLength } from "data/Variable/Utils";
import { user } from "data/Variable/UserVariable";
import { CatchError } from "data/Handler/Exceptions/exceptions";
import { validatePermission } from "data/Variable/Utils";
import { addUserList } from "data/Variable/UserVariable";
import UserService from "data/Handler/UserService";
import { getUserData } from "data/Variable/UserVariable";
import { getUserType } from "data/Variable/UserVariable";
import { getOwner } from "data/Variable/UserVariable";
import PrimaryIconButton from "components/Button/PrimaryIconButton";
import LoadingScreen from "components/Loader/LoadingScreen";
import { GlobalHeader } from "components/Datatables/GlobalHeader";

function UserForm(props) {
  const [sidebarToggle] = useOutletContext();
  const userService = new UserService()
  const navigate = useNavigate()
  const userData = getUserData()
  const [minLengthState, setminLengthState] = useState("");
  const [minLength, setminLength] = useState("");
  const [userType, userTypeOnChange] = useState('')
  const [dataLoad, setDataLoad] = useState(false)
  const { organization } = userData
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [password, passwordOnChange] = useState('')
  const [Enable, EnableOnChage] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  var userTypeOptions = getUserType()
  const userTypeFilter = userTypeOptions.filter((type) => type.value !== 'SUPERADMIN')
  const [passwordShown, setPasswordShown] = useState(false);
  const ownerData = getOwner()
  // const bankOptionMaster = getBankSelect()
  const [owner, ownerOnChange] = useState(ownerData.find(own => own.value === organization))
  const [ changeMitra, setChangeMitra ] = useState(false)

  useEffect(() => {
    validatePermission(props, 3, 1)
  }, [])

  const getUser =  async() => {
    const len = 'ALL'
    const result =  await userService.getAllUser(len)
    addUserList(result.data)
  }

  const submitUser = async() => {
    try {
      setDataLoad(true)
      const payload = user(name, email,password, firstname, lastname,
                    Enable, userType.value, owner.value)
      const result = await userService.addUser(payload)
      await getUser()
      await swal("Success", "User Berhasil di tambahkan", "success")
      navigate('/user')
      setDataLoad(false)
     return result
    } catch (error) {
      setDataLoad(false)
      return await CatchError(error, props)
    }
  }
  const togglePassword = () => {
      setPasswordShown(!passwordShown);
  };
  const cleanOption = () => {}
  const child = (
    <>
        {/* Main Content */}
        <div className="mainCard">
          <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
              <GlobalHeader
                header = {'Tambah User'}
              />
              <DefaultInput
                label="Username"
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
              />
              <SelecttWithUserTier
                  key={"1xcc"}
                  label = {'User Mitra'}
                  value = {owner}
                  onChange = {ownerOnChange}
                  disabled = {changeMitra}
                  options = {ownerData}
                  subFunction = {cleanOption}
                  // subFunction = {deleteSelect}
                  checkText = {'Ubah Mitra'}
                  checkValue = {changeMitra}
                  checkValueOnChange = {setChangeMitra}
                />
              <div>
              <DefaultInput
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
              />
              </div>
              <div>
                <DefaultInput
                  name="min_length_password"
                  type={passwordShown ? "text" : "password"}
                  label="Password"
                  max={16}
                  value = {password}
                  onChange={(e) => {
                      passwordOnChange(e.target.value)
                    if (!verifyLength(e.target.value, 8)) {
                      setIsDisabled(true)
                      setminLengthState("has-danger");
                    } else {
                      setIsDisabled(false)
                      setminLengthState("has-success");
                    }
                    setminLength(e.target.value);
                  }}
                />
                {minLengthState === "has-danger" ? (
                  <label className="error">
                    Kamu baru mengisi {minLength.length} Karakter, Isi minimal 8 Karakter dan Maksimal 16 Karakter.
                  </label>
                ) : null}
              </div>
              <Checklist
                text = {'Show Password'}
                value={passwordShown}
                onChange={(e) => togglePassword(e.target.checked)}
              />
              <DefaultInput
                label="Nama Depan"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
                type="text"
              />
              <DefaultInput
                label="Nama Belakang"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                type="text"
              />
              <DefaultInput
                label="Nama Lengkap"
                value = {`${firstname} ${lastname}`}
                type="text"
              />
              <SelectBasic
                label="Jenis User"
                name="singleSelect"
                value = {userType}
                option = {userTypeFilter}
                onChange={e => userTypeOnChange(e)
              }
              />
              <Checklist
                text = 'Aktif User'
                value = {Enable}
                onChange = {e => EnableOnChage(e.target.checked)}
              />
              <div className="mt-6 flex flex-row gap-4">
                <PrimaryIconButton
                  title="Save"
                  disabled = {isDisabled}
                  onClick={submitUser}
                />
              </div>
          </div>
        </div>
    </>
  )
  return (
    <>
      <main className="h-full">
        <Navbar toggle={sidebarToggle} />
        <LoadingScreen
          // key = {RandomTextGenerator()}
          children={child}
          loading = {dataLoad}
          text={"Saving..."}
        />
      </main>
    </>
  );
}

export default UserForm;
