import LoginService from '../AuthService'
import APIconsume from '../ConsumeAPI'
import { getAccessToken } from "./../../../data/Variable/Common"

class HostpotUserService extends LoginService {
    constructor() {
        super()
        const accessToken = getAccessToken()
        this._consume = new APIconsume(accessToken)
        this._url = 'hostpotUser'
    }

    async post (payload) {
        const result = await this._consume.postData(payload, this._url)
        return result.data
    }

    async generateVoucher (payload) {
        const result = await this._consume.postData(payload, `${this._url}/generate`)
        return result.data
    }

    async get (id) {
        const url = `${this._url}/${id}`

        const result = await this._consume.getData(url)
        return result.data
    }

    async getAll (len = 'ALL', trash = false,  mitra = '', reseller = '', page = '') {
        let url = `${this._url}/view?mitra=${mitra}&reseller=${reseller}&trash=${trash}&line=${len}&page=${page}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async put (id, payload) {
        const url = `${this._url}/${id}`
        const result = await this._consume.putData(payload, url)
        return result.data
    }

    async setTrash (id, payload) {
        const url = `${this._url}/setTrash/${id}`
        const result = await this._consume.patchData(payload, url)
        return result.data
    }

    async setPaid (id, payload) {
        const url = `${this._url}/setPaid/${id}`
        const result = await this._consume.patchData(payload, url)
        return result.data
    }

    async delete (id) {
        const url = `${this._url}/${id}` //`${this._url}/${name}?noRouter=${!noRouter}`
        const result = await this._consume.deleteData(url)
        return result.data
    }
}

export default HostpotUserService