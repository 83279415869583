import GlobalDataFetch from "data/Handler/GlobalDataFetch"
import UserService from "data/Handler/UserService"
import { removeUserSession } from "data/Variable/Common"
import { getUserData } from "data/Variable/UserVariable"
import { getRefreshToken } from "data/Variable/Common"
const userService = new UserService()

export default async function AuthMiddleware(navigate, location) {
  // try {
      console.log('Auth Middleware running')
      const userData = getUserData()
      const pathLogin = '/auth/login'
      const { pathname } = location
      const refreshtoken = getRefreshToken()
      // console.log('Auth Middleware', userData, pathLogin, pathname, refreshtoken)
      if (!userData || !userData.length) {
        console.log('trigger user refresh')
        await userService.refreshUser(navigate, pathLogin)
      }

      if (!refreshtoken || !refreshtoken.length) {
        console.log('trigger refresh token')
        await removeUserSession()
        navigate(pathLogin)
        return 'Unauthenticated'
      }

      await GlobalDataFetch()
      if (pathname === pathLogin) {
        console.log('trigger path name to home')
        navigate('/')
      }
      console.log('Authenticated')
      return 'Authenticated'
    // } catch (error) {
    //   console.log('Auth Middleware Error', error.message)
    //   return `Error: ${error.message}`
    // }
}