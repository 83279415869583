import React, { useEffect, useState } from "react";
import Navbar from "./../../../components/Navbar/Index";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import DefaultInput from "../../../components/Input/DefaultInput";
import UserService from "data/Handler/UserService";
// import { getpppoeUserData } from "data/Variable/Pppoe/pppoeUser";
import PppoeUserService from "data/Handler/Pppoe/PppoeUserService";
// import PppoeProfileService from "data/Handler/Pppoe/PppoeProfileService";
import { verifySuperAdmin } from "data/Variable/Utils";
import SelectBasic from "components/Input/SelectBasic";
import swal from "sweetalert";
import { CatchError } from "data/Handler/Exceptions/exceptions";
import { addPppoeUserList, payloadPppoeUserUpdate } from "data/Variable/Pppoe/PppoeUser";
import Checklist from "components/Button/Checklist";
import { DateTimestr } from "data/Variable/Utils";
import PrimaryIconButton from "components/Button/PrimaryIconButton";
import LoadingScreen from "components/Loader/LoadingScreen";
// import { RandomTextGenerator } from "data/Variable/Utils";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { GlobalHeader } from "components/Datatables/GlobalHeader";
import InputCoordinate from "components/Input/InputCoordinate";
import { verifyLength } from "data/Variable/Utils";
import ResourceService from "data/Handler/ResourceService";

function PppoeUserDetail(props) {
  const [sidebarToggle] = useOutletContext();
  const navigate = useNavigate()
  const location = useLocation()
  const { state } = location
  const pppoeUser = state // || getpppoeUserData()
  const pppoeUserService =  new PppoeUserService()
  // const pppoeProfileService = new PppoeProfileService()
  const resourceService = new ResourceService()
  const userService = new UserService()
  const { mitraSuper, userAdmin } = verifySuperAdmin()
  const [loading, setLoading] = useState(false)
  const [mitra] = useState(pppoeUser.mitra)
  const [name, setName] = useState(pppoeUser.name)
  const [firstname, setFirstName] = useState(pppoeUser.firstname)
  const [lastname, setLastName] = useState(pppoeUser.lastname)
  const [latitude, setLatitude] = useState(pppoeUser.latitude)
  const [longitude, setLongitude] = useState(pppoeUser.longitude)
  const [description, setDescription ] = useState(pppoeUser.description)
  // const [pppoeProfileId, setPppoeProfileId] = useState({})
  const [router] = useState(pppoeUser.province)
  // getUserSelect())
  // const [routerSelectOption, routerSelectOptionOnChange] = useState([])
  const [minLengthState, setminLengthState] = useState('');
  const [minLengthNpwpState, setminLengthNpwpState] = useState('');
  const [minLengthPhoneState, setminLengthPhoneState] = useState('');
  const [email, setEmail] = useState(pppoeUser.email)
  const [ktpNumber, ktpNumberOnChange] = useState(pppoeUser.ktp)
  const [MobileNo, mobileNoOnChange] = useState(pppoeUser.phone)
  const [npwp, npwpOnChange] = useState(pppoeUser.npwp)
  const [enabled, setEnabled] = useState(pppoeUser.enabled)
  const [pppoeUsername, setPppoeUsername] = useState(pppoeUser.pppoeUsername)
  const [pppoePassword, setPppoePassword] = useState(pppoeUser.pppoePassword)
  const [addressTitle, setAddressTitle] = useState(pppoeUser.addressTitle)
  const [addressLine, setAddressLine] = useState(pppoeUser.addressLine)
  const [reseller, setReseller] = useState({})
  const [discount, setDiscount] = useState(0)
  const [city , cityOnChange] = useState('')
  const [subDistrict , subDistrictOnChange] = useState('')
  const [urban , urbanOnChange] = useState('')
  // const [pppoeProfileOption, setpppoeProfileOption] = useState([])
  const [cityOption, cityOptionOnChange] = useState([])
  const [subDistrictOption, subDistrictOptionOnChange] = useState([])
  const [UrbanOption, urbanOptionOnChange] = useState([])
  const [resellerOption, setResellerOption] = useState([])
  const {id, creation, modified, province, createdBy,
    modifiedBy, mitraId, resellerId,
    province: provinceDefault,
    city: cityDefault,
    urban: urbanDefault,
    subDistrict: subDistrictDefault } = pppoeUser

  // console.log(pppoeUser)
  // console.log(pppoeUser)
  // async function GetReseller(mitra) {
  //   const resellerList = await userService.getUserSelect(mitra)
  //   const resellerData = resellerList.data

  //   setResellerOption(resellerData)

  //   const defaultReseller = resellerData?.find((data) => data.userId === resellerId)
  //   const finalSelection = !defaultReseller ? resellerData[0] : defaultReseller
  //   setReseller(finalSelection)
  //   return finalSelection?.userId
  // }

  const CityLoad = async(provice) => {
      const dat = await resourceService.getCity(provice)
      const result = dat.data
      // console.log(result)
      // addCity(result)
      cityOnChange(result.find((data) => data.value === cityDefault))
      cityOptionOnChange(result)
      return result
    }

    const subDistrictLoad = async(subDistrict) => {
      const dat = await resourceService.getSubDistrict(subDistrict)
      const result = dat.data
      // console.log(result)
      // addSubDistrict(result)
      subDistrictOnChange(result.find((data) => data.value === subDistrictDefault))
      subDistrictOptionOnChange(result)
      return result
    }

    const UrbanLoad = async(urban) => {
      // sessionStorage.removeItem('urban')
      const dat = await resourceService.getUrban(urban)
      const result = dat.data
      // console.log(result)
      // addUrban(result)
      urbanOnChange(result.find((data) => data.value === urbanDefault))
      urbanOptionOnChange(result)
      return result
    }

  // async function GetpppoeProfile(routerId) {
  //   const pppoeProfileList = await pppoeProfileService.getProfileSelect(routerId)
  //   const pppoeProfileData = pppoeProfileList.data

  //   setpppoeProfileOption(pppoeProfileData)
  //   setPppoeProfileId(pppoeProfileData?.find((data) => data.value === profileId))
  //   return pppoeProfileData
  // }

  async function GetReseller(mitra) {
      const resellerList = await userService.getUserSelect(mitra)
      const resellerData = resellerList.data

      setResellerOption(resellerData)

      const defaultReseller = resellerData?.find((data) => data.userId === resellerId)
      const finalSelection = !defaultReseller ? resellerData[0] : defaultReseller
      setReseller(finalSelection)
      return finalSelection?.userId
  }

  useEffect(() => {
    // GetpppoeProfile(routerId)
    GetReseller(mitraId)
    CityLoad(provinceDefault)
    subDistrictLoad(cityDefault)
    UrbanLoad(subDistrictDefault)
  }, [])

  const getAll =  async(trash = false) => {
    const len = 'ALL'
    const result =  await pppoeUserService.getAll(len, trash)
    addPppoeUserList(result.data)
  }

  const update = async() => {
    const selected = await swal("Update", "Perubahan Data Tidak Dapat di Batalkan", "info", {
          buttons: {
          update: {text: "Update", value: 'up' },
          cancel: 'Batal'
        }
      }
    )

    switch (selected) {
        case 'up':
          try {
            setLoading(true)
            const payload = payloadPppoeUserUpdate(
                province,
                city?.value,
                subDistrict?.value,
                urban?.value,
                reseller?.userId,
                name,
                firstname,
                lastname,
                ktpNumber,
                npwp,
                MobileNo,
                pppoeUsername,
                pppoePassword,
                addressTitle,
                addressLine,
                discount,
                enabled,
                latitude,
                longitude,
                description
             )

            const result = await pppoeUserService.put(id, payload)
            await getAll()
            await swal(result.status, result.message, 'success')
            setLoading(false)
            return navigate('/pppoe/user')
          } catch (error) {
            setLoading(false)
            return await CatchError(error, props)
          }
        default:
          break;
      }
  }

  const child = (
    <>
      {/* Main Content */}
        <div className="mainCard">
          <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
            {/* <form> */}
            <GlobalHeader
                header = {'Detail pppoe User'}
              />
            <InputCoordinate
              latitude = {latitude}
              setLatitude = {setLatitude}
              longitude = {longitude}
              setLongitude = {setLongitude}
            />
            <DefaultInput
              key={"1"}
              label = {'Mitra'}
              value = {mitra}
              onChange = {() => {}}
            />
            <SelectBasic
              key={"2"}
              label = {"Reseller"}
              value = {reseller}
              option = {resellerOption}
              isDisabled = {!mitraSuper && !userAdmin}
              onChange = {async (e) => {
                setReseller(e)
              }}
            />
            <DefaultInput
              key={"3"}
              label = {'Nama'}
              value = {name}
              onChange = {(e) => setName(e.target.value)}
              type
            />
            <DefaultInput
              key={"4"}
              label = {'Nama Depan'}
              value = {firstname}
              onChange = {(e) => setFirstName(e.target.value)}
              type
            />
            <DefaultInput
              key={"5"}
              label = {'Nama Belakang'}
              value = {lastname}
              onChange = {(e) => setLastName(e.target.value)}
              type
            />
            <DefaultInput
              key={"6"}
              label="Nama Lengkap"
              value = {`${firstname} ${lastname}`}
              type="text"
            />
            <DefaultInput
              key={"7"}
              label="Email *"
              name="email"
              type="email"
              value = {email}
              onChange = {(e) => setEmail(e.target.value)}
            />
            <DefaultInput
              key={"8"}
              name="min_length_ktp"
              type="number"
              label="KTP Number *"
              max={16}
              value = {ktpNumber}
              onChange={(e) => {
                if (!verifyLength(e.target.value, 17)) {
                  ktpNumberOnChange(e.target.value)
                if (!verifyLength(e.target.value, 16)) {
                  setminLengthState("has-danger");
                } else {
                  setminLengthState("has-success");
                }
                }
              }}
            />
             {minLengthState === "has-danger" ? (
               <label className="error">
                 KTP harus 16 Karakter.
               </label>
             ) : null}
            <DefaultInput
              key={"9"}
              name="min_length_ktp"
              type="number"
              label="NPWP"
              max={16}
              value = {npwp}
              onChange={(e) => {
                if (!verifyLength(e.target.value, 16)) {
                  npwpOnChange(e.target.value)
                  if (!verifyLength(e.target.value, 15)) {
                    setminLengthNpwpState("has-danger");
                  } else {
                    setminLengthNpwpState("has-success");
                  }
                }
                // setminLengthNPWP(e.target.value);
              }}
            />
            {minLengthNpwpState === "has-danger" ? (
              <label className="error">
                NPWP Harus 15 Karakter.
              </label>
            ) : null}
            <div>
            <DefaultInput
              key={"10"}
              name="min_length_phone"
              type="number"
              label="Nomor Telepon *"
              max={12}
              value={MobileNo}
              onChange={(e) => {
                if (!verifyLength(e.target.value, 13)) {
                  mobileNoOnChange(e.target.value)
                if (!verifyLength(e.target.value, 11)) {
                  setminLengthPhoneState("has-danger");
                } else {
                  setminLengthPhoneState("has-success");
                }
              }      // setminLengthPhone(e.target.value);
              }}
            />
              {minLengthPhoneState === "has-danger" ? (
                <label className="error">
                  Nomor Telepon Minimal 8 Karakter dan Maksimal 13 Karakter.
                </label>
            ) : null}
            {/* <SelectBasic
                label="Lokasi Router"
                name="singleSelect"
                value = {router}
                option={routerSelectOption}
                onChange={ async (e) => {
                    setRouter(e)
                    await GetpppoeProfile(e.value)
                    await CityLoad(e?.province)
                  }
                }
              />
              {!router || !router.status ? (
                <label className="error">
                  Router Offline
                </label>
              ) : <label className="success">
                  Router Online
                </label>} */}
            <DefaultInput
              key={"11"}
              label="Lokasi Router"
              value = {router}
              onChange={() => {}}
            />
            </div>
            <SelectBasic
              key={"12"}
              label="Kota"
              name="singleSelect"
              value = {city}
              option={cityOption}
              onChange={async (e) => {
                subDistrictOnChange(null)
                urbanOnChange(null)
                await subDistrictLoad(e.value)
                urbanOptionOnChange([])
                cityOnChange(e)
              }}
            />
            <SelectBasic
              key={"13"}
              label="Kecamatan"
              name="singleSelect"
              value = {subDistrict}
              option={subDistrictOption}
              onChange={async (e) => {
                urbanOnChange(null)
                subDistrictOnChange(e)
                await UrbanLoad(e.value)
              }}
            />
            <SelectBasic
              key={"14"}
              label="Kelurahan"
              name="singleSelect"
              value = {urban}
              option={UrbanOption}
              onChange={(value) => urbanOnChange(value)}
            />
            {/* <SelectBasic
              key={"15"}
              label = {'pppoe Profile'}
              value = {pppoeProfileId}
              onChange = {(e) => {
                setPppoeProfileId(e)
              }}
              option = {pppoeProfileOption}
            /> */}
              {!mitraSuper ? null : (
                  <Checklist
                    key={"16"}
                    text = {"Aktivasi Customer"}
                    value = {enabled}
                    checked = {enabled}
                    onChange = {(e) => {
                      setEnabled(e.target.checked)
                    }}
                  />
                )
              }
              <DefaultInput
                key={"17"}
                label = {'PPPOE Username'}
                value = {pppoeUsername}
                onChange = {(e) => setPppoeUsername(e.target.value)}
                type
              />
              <DefaultInput
                key={"18"}
                label = {'PPPOE Password'}
                value = {pppoePassword}
                onChange = {(e) => setPppoePassword(e.target.value)}
                type
              />
              <DefaultInput
                key={"19"}
                label = {'Presentase Potongan Harga Customer'}
                value = {discount}
                onChange = {(e) => {
                    if (e.target.value >= 0 && e.target.value <= 100) {
                      setDiscount(e.target.value)
                    }
                  }
                }
                type = {'number'}
              />
              <DefaultInput
                key={"20"}
                label = {'Title Alamat'}
                value = {addressTitle}
                onChange = {(e) => setAddressTitle(e.target.value)}
                type = {'text'}
              />
              <DefaultInput
                key={"21"}
                label = {'Isi Alamat'}
                value = {addressLine}
                onChange = {(e) => setAddressLine(e.target.value)}
                type = {'textarea'}
              />
              <DefaultInput
                key={"22"}
                label = {'Deskripsi'}
                value = {description}
                onChange = {(e) => setDescription(e.target.value)}
                type = {'textarea'}
              />
              <DefaultInput
                key={"23"}
                label = {'Creation'}
                disabled
                value = {DateTimestr(creation)}
                onChange = {() => {}}
              />
              <DefaultInput
                key={"24"}
                label = {'Modified'}
                disabled
                value = {DateTimestr(modified)}
                onChange = {() => {}}
              />
              <DefaultInput
                key={"25"}
                label = {'CreatedBy'}
                disabled
                value = {createdBy}
                onChange = {() => {}}
              />
              <DefaultInput
                key={"26"}
                label = {'ModifiedBy'}
                disabled
                value = {modifiedBy}
                onChange = {() => {}}
              />
              <div className="mt-6 flex flex-row gap-4">
                <PrimaryIconButton
                  title="Update"
                  icon = {faRefresh}
                  onClick = {update}
                />
              </div>
            {/* </form> */}
          </div>
        </div>
    </>
  )
  return (
    <>
      <main className="h-full">
        <Navbar toggle={sidebarToggle} />
        <LoadingScreen
          // key = {RandomTextGenerator()}
          children={child}
          loading = {loading}
          text={"Saving..."}
        />
      </main>
    </>
  );
}

export default PppoeUserDetail;
