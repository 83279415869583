import { setIncrementDate } from 'data/Variable/Utils';
import React from 'react';
import { Input, FormGroup, Label, Col, Row } from 'reactstrap';

function DateIncrementInput({labelInput, labelOutput, value, dueDisplayValue, onChange, disabled, decr = false}) {
  const today = new Date()
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth()+1, 0)
  const lastDayOfMonthFinal = new Date(lastDayOfMonth.setHours(23, 59))
  const incrementValue = !dueDisplayValue ? Number(value) : (Number(dueDisplayValue) - Number(value))
  return (
    <div className="flex flex-row my-12">
      <div className="flex md:flex-col mr-12">
         <Label for="latitudeInput"
          className="text-sm text-gray-600"
         >{labelInput}</Label>
        <Input
          type="number"
          id="latitudeInput"
          // onChange={(e) => setEmail(e.target.value)}
          className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full md:py-2 py-3 focus:outline-none focus:border-emerald-400 mt-1"
          value={value}
          onChange={e => onChange(e)}
          disabled = {disabled}
        />
      </div>
      <div className="flex md:flex-col mr-12">
        <Label for="longitudeInput"
          className="text-sm text-gray-600"
        >{labelOutput}</Label>
        <Input
          type="text"
          disabled
          id="longitudeInput"
          // onChange={(e) => setEmail(e.target.value)}
          className="text-sm placeholder-gray-500 px-4 rounded-lg border border-gray-200 w-full md:py-2 py-3 focus:outline-none focus:border-emerald-400 mt-1"
          value={setIncrementDate(lastDayOfMonthFinal, incrementValue, decr)}
          onChange={() => {}}
        />
      </div>
    </div>
  )
}

export default DateIncrementInput