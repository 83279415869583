// import { Button } from "reactstrap";

export default function PrimaryButton({title = 'Primary Button', value, onClick, disabled, className = "bg-emerald-600 text-gray-100 px-3 py-2 rounded-lg shadow-lg text-sm"}) {
    return (
        <button className= {className}
            value={value}
            onClick={onClick}
            disabled = {disabled}
        >
          {title}
        </button>
    )
}