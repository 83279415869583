export const UserHeader = [{
        label: "Username",
        key: "name",
      },
      {
        label: "Email",
        key: "email",
      },
      {
        label: "Tipe User",
        key: "type",
      },
      {
        label: "Organisasi",
        key: "organization",
      },
      {
        label: "Login",
        key: "login",
      },
      {
        label: "Status",
        key: "status",
      },
      {
        label: "Action",
        key: "actions",
        sortable: false,
        filterable: false,
      },
    ]