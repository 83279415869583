// import { getRefreshToken } from "data/Variable/Common"
import UserService from "./UserService"
import ResourceService from "./ResourceService"
import RouterNasService from "./RouterNasService"
import HostpotProfileService from "./HostpotVoucher/HostpotProfileService"
import { addUserSelect } from "data/Variable/UserVariable"
import { addProvince } from "data/Variable/Common"
import { addhostpotProfileTimeMeasurementData } from "data/Variable/HostpotVoucher/HostpotProfile"
import { addhostpotProfileInternetMeasurementData } from "data/Variable/HostpotVoucher/HostpotProfile"
// import { addCustomerGroup } from "data/Variable/Common"
// import { addCustomerGroupRaw } from "data/Variable/Common"
import { addOwner } from "data/Variable/UserVariable"
import { addStatusOptions } from "data/Variable/Common"
import { addStatusOptionsRaw } from "data/Variable/Common"
import { addUserType } from "data/Variable/UserVariable"
import { addRole } from "data/Variable/UserVariable"
import { addItemMeasurement } from "data/Variable/Common"
import { addItemMeasurementRaw } from "data/Variable/Common"
// import { addItemCurrency } from "data/Variable/Common"
// import { addItemCurrencyRaw } from "data/Variable/Common"
import { addItemCategory } from "data/Variable/Common"
import { addItemCategoryRaw } from "data/Variable/Common"
import { addPaymentStatus } from "data/Variable/Common"
import { addBillingStatus } from "data/Variable/Common"
import { addRouterSelect } from "data/Variable/Common"
import { addIndoorDeviceOption } from "data/Variable/Common"
import { addIndoorDeviceOptionRaw } from "data/Variable/Common"
import { addoutdoorDeviceOption } from "data/Variable/Common"
import { addoutdoorDeviceOptionRaw } from "data/Variable/Common"
import { decodeToken } from "data/Variable/Common"
import { getRefreshToken } from "data/Variable/Common"
import { addUserData } from "data/Variable/UserVariable"
import { addMitra } from "data/Variable/UserVariable"
// import { decodeToken } from "data/Variable/Common"

const GlobalDataFetch = async () => {
  const refreshToken = getRefreshToken()
  const userService = new UserService()
  const resourceService = new ResourceService()
//   const bankAccountService = new BankAccountService()
  const routerService = new RouterNasService()
//   const globalTemplateService = new TemplateService()
  const hostpotProfileService = new HostpotProfileService()

  const User = decodeToken(refreshToken);
  const userId = User.id
  const user = await userService.getUser(userId)
  const userSelect = await userService.getUserSelect()
  const mitraName = user.data.organization
  addUserData(user.data)
  addUserSelect(userSelect.data)

  const Getprovince = await resourceService.getProvince()
  addProvince(Getprovince.data)

  const hostpotProfileTimeMeasurement = await hostpotProfileService.getTimeMeasurement()
  addhostpotProfileTimeMeasurementData(hostpotProfileTimeMeasurement.data)

  const hostpotProfileInternetMeasurement = await hostpotProfileService.getInternetMeasurement()
  addhostpotProfileInternetMeasurementData(hostpotProfileInternetMeasurement.data)

//   const CustomerGroup = await resourceService.getCustomerGroup()
//   addCustomerGroup(CustomerGroup.data)
//   addCustomerGroupRaw(CustomerGroup.raw)

  const Owner = await resourceService.getOwner()
  addOwner(Owner.data)

  const userType = await resourceService.getUserTypeSelect()
  const role = await resourceService.getRoleSelect()
  const statusOption = await resourceService.getStatusSelect()
  addStatusOptions(statusOption.data)
  addStatusOptionsRaw(statusOption.raw)
  addUserType(userType.data)
  addRole(role.data)

  const ItemMeasurement = await resourceService.getItemMeasurement()
  addItemMeasurement(ItemMeasurement.data)
  addItemMeasurementRaw(ItemMeasurement.raw)

//   const itemCurrency = await resourceService.getItemCurrency()
//   addItemCurrency(itemCurrency.data)
//   addItemCurrencyRaw(itemCurrency.raw)

  const itemCategory = await resourceService.getItemCategory()
  addItemCategory(itemCategory.data)
  addItemCategoryRaw(itemCategory.raw)

//   const addressType = await resourceService.getAddressType()
//   addAddressType(addressType.data)
//   addAddressTypeRaw(addressType.raw)

  const paymentStatus = await resourceService.getPaymentStatusSelect()
  addPaymentStatus(paymentStatus.data)

  const billingStatus = await resourceService.getBillingStatusSelect()
  addBillingStatus(billingStatus.data)

  const mitra = await userService.getMitraData(mitraName)
  addMitra(mitra.data)

//   const customerOption = await resourceService.getCustomerSelect()
//   const itemOption = await resourceService.getItemSelect()
//   addItemOptions(itemOption.data)

//   const addressOption = await resourceService.getAddressSelect()
//   addCustomerOptions(customerOption.data)
//   addAddressOptions(addressOption.data)

//   const billingOption = await resourceService.getBillingSelect()
//   addBillingOptions(billingOption.data)

//   const bankAccountList = await bankAccountService.getAllBankAccount()
//   addBankAccountList(bankAccountList.data)

//   const templateOption = await globalTemplateService.getGlobalTemplateType()
//   addGlobalTemplateOption(templateOption.data)

//   const bankSelect = await resourceService.getBankSelect()
//   addBankSelect(bankSelect.data)
//   addBankSelectRaw(bankSelect.raw)

//   const bankMitraSelect = await bankAccountService.getBankAccountSelect()
//   addMitraBankSelect(bankMitraSelect.data)

//   const dashboard = await resourceService.loadDashboard()
//   addDashboard(dashboard.data)

//   const datLog =  await userService.getDatalog()
//   const logData = datLog.data
//   addDatalogList(logData)

//   const LogParam =  await userService.getDatalogParam()
//   const logDataParam = LogParam.data
//   addDatalogParam(logDataParam)

  const routerSelect = await routerService.getRouterSelect()
  addRouterSelect(routerSelect.data)

  const indoorDevice = await resourceService.getIndoorDeviceSelect()
  const optIndoorData = indoorDevice.data
  const IndoorRaw = indoorDevice.raw
  addIndoorDeviceOption(optIndoorData)
  addIndoorDeviceOptionRaw(IndoorRaw)

  const outdoorDevice = await resourceService.getOutdoorDeviceSelect()
  const optOutdoorData = outdoorDevice.data
  const OutdoorRaw = outdoorDevice.raw
  addoutdoorDeviceOption(optOutdoorData)
  addoutdoorDeviceOptionRaw(OutdoorRaw)
}

export default GlobalDataFetch