import { faRefresh } from "@fortawesome/free-solid-svg-icons"
import PrimaryIconButton from "components/Button/PrimaryIconButton"
import { GlobalHeader } from "components/Datatables/GlobalHeader"
import SelectBasic from "components/Input/SelectBasic"
import SelecttWithUserTier from "components/Input/SelectWithUserTier"
import LoadingScreen from "components/Loader/LoadingScreen"
import Navbar from "./../../../components/Navbar/Index"
import { CatchError } from "data/Handler/Exceptions/exceptions"
import SubscriptionAndHistoryService from "data/Handler/Pppoe/SubscriptionAndHistoryService"
import { InvoiceExportPayload } from "data/Variable/Common"
import { getRefreshToken } from "data/Variable/Common"
import { getExportDataType } from "data/Variable/Common"
import { getOwner } from "data/Variable/UserVariable"
import { getUserData } from "data/Variable/UserVariable"
import { useEffect, useState } from "react"
import { sidebarToggle } from "utils/toggler"

function PppoeInvoiceExport(props) {
  const subscriptionAndHistoryService =  new SubscriptionAndHistoryService()
  const refreshToken = getRefreshToken()
  const ownerData = getOwner()
  const userData = getUserData()
  // const bankOptionMaster = getBankSelect()
  const bankOptionDefault = [{label: 'ALL', value: '*'}]
  const [owner, ownerOnChange] = useState(ownerData.find(own => own.value === userData.organization))
  const [period, periodOnChange] = useState([])
  const [bank, setBank] = useState({})
  const [fileType, setFileType] = useState({})
  const [loading, setLoading] = useState(false)
  const fileTypeOption = getExportDataType()
  const [periodOption, setPeriodOption] = useState([])
  const [changeMitra, setChangeMitra] = useState()
  const bankOption = bankOptionDefault.concat([
    { label: 'BNI', value: 'BNI' },
    { label: 'Mandiri', value: 'Mandiri' },
    { label: 'BRI', value: 'BRI' }
  ])

  const fetchPeriod =  async(mitra) => {
    try {
      const dat =  await subscriptionAndHistoryService.getInvoiceListPeriod(mitra.mitraid)
      const result = dat.data
      setPeriodOption(result)
      return result
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }


  useEffect(() => {
      fetchPeriod(owner)
  }, [])

  const PppoeInvoiceExportData = async() => {
    try {
      setLoading(true)
      const payload = InvoiceExportPayload(owner.mitraid, period.value, bank.value, fileType.value)
      const result = await subscriptionAndHistoryService.downloadInvoice(payload)
      setLoading(false)
      return result
    } catch (error) {
      setLoading(false)
      return await CatchError(error, props, refreshToken)
    }
  }

  async function FetchInvoicePeriod (mitra) {
    setBank({})
    periodOnChange({})
    return await fetchPeriod(mitra)
  }

  const children = (
    <>
      {/* Main Content */}
      <div className="mainCard">
        <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
            {/* <form> */}
              <GlobalHeader
                header = {'Export Tagihan Invoice'}
              />
              <SelecttWithUserTier
                key={"1"}
                label = {'Mitra *Wajib di Isi'}
                value = {owner}
                onChange = {ownerOnChange}
                disabled = {changeMitra}
                options = {ownerData}
                subFunction = {FetchInvoicePeriod}
                // subFunction = {deleteSelect}
                checkText = {'Ubah Mitra'}
                checkValue = {changeMitra}
                checkValueOnChange = {setChangeMitra}
              />
              <SelectBasic
                key={"2"}
                label = {'Tipe File *Wajib di Isi'}
                value = {fileType}
                option = {fileTypeOption}
                onChange = {setFileType}
              />
              <SelectBasic
                key={"3"}
                label = {'Bank'}
                value = {bank}
                option = {bankOption}
                onChange = {setBank}
              />
              <SelectBasic
                key={"4"}
                label = {'Periode'}
                value = {period}
                option = {periodOption}
                onChange = {periodOnChange}
              />
            <div className="mt-6 flex flex-row gap-4">
              <PrimaryIconButton
                title= {`Export Invoice ${bank?.value || ''} ${fileType?.value || ''}`}
                icon = {faRefresh}
                onClick = {PppoeInvoiceExportData}
                disabled = {!fileType.value}
                color="info"
              />
          </div>
        </div>
        {/* </form> */}
      </div>
    </>
  )

  return (
    <>
      <main className="h-full">
        <Navbar toggle={sidebarToggle} />
        <LoadingScreen
          children={children}
          loading = {loading}
          text={`Export Invoice ${fileType.value}`}
        />
      </main>
    </>
  );
}


export default PppoeInvoiceExport;
