import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Index";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import DefaultInput from "../../components/Input/DefaultInput";
import SelecttWithUserTier from "../../components/Input/SelectWithUserTier";
import Checklist from "components/Button/Checklist";
import { validatePermission } from "data/Variable/Utils";
// import { getUserManagerData } from "data/Variable/UserVariable";
import UserService from "data/Handler/UserService";
import { getUserType } from "data/Variable/UserVariable";
import { getOwner } from "data/Variable/UserVariable";
import swal from "sweetalert";
import { user } from "data/Variable/UserVariable";
import { addUserData } from "data/Variable/UserVariable";
import { CatchError } from "data/Handler/Exceptions/exceptions";
import { verifyLength } from "data/Variable/Utils";
import { compare } from "data/Variable/Utils";
import SelectBasic from "components/Input/SelectBasic";
import PrimaryIconButton from "components/Button/PrimaryIconButton";
import LoadingScreen from "components/Loader/LoadingScreen";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { GlobalHeader } from "components/Datatables/GlobalHeader";

function UserProfile(props) {
  const [sidebarToggle] = useOutletContext();
  // const pages = 'user'
  // const permissionData = getUserPermission()
  const navigate = useNavigate()
  const location = useLocation()
  const { state } = location
  // const Permission = permissionData.find(data => data.Role === pages) || ''
  const userData = state // || getUserManagerData()
  const userService = new UserService()
  const [dataLoad, setDataLoad] = useState(false)
  var userTypeOptions = getUserType() || []
  const [registerConfirmPasswordState, setregisterConfirmPasswordState] = React.useState('');
  const [registerPassword, setregisterPassword] = useState("");
  const [IsComplete, setIsComplete] = useState(true)
  const [registerPasswordState, setregisterPasswordState] = useState("");
  const organization = userData.organization
  const { id: Id } = userData
  const [username, setUsername] = useState(userData.username)
  const [email, setEmail] = useState(userData.email)
  const [firstName, setFirstname] = useState(userData.firstName)
  const [lastName, setLastname] = useState(userData.lastName)
  const [Password, PasswordOnChange] = useState('')
  const [Enable, EnableOnChage] = useState(userData.enabled)
  const [userType, userTypeOnChange] = useState(userTypeOptions.find(data => data.value === userData.userType))
  const [passwordShown, setPasswordShown] = useState(false);
  const ownerData = getOwner()
  // const bankOptionMaster = getBankSelect()
  const [owner, ownerOnChange] = useState(ownerData.find(own => own.value === organization))
  const [ changeMitra, setChangeMitra ] = useState(false)

  useEffect(() => {
    validatePermission(props, 3, 1)
  })

  // function that verifies if two strings are equal
  const EditUser = async() => {
    const option = await swal("Update", "Data Tidak Dapat di Recover", "info", {
                buttons: {
                update: {text: "Update", value: 'up' },
                cancel: 'Cancel'
              }
            }
      )
      switch (option) {
        case 'up':
          try {
            setDataLoad(true)
            const payload = user(username, email, Password, firstName, lastName,
                  Enable, userType.value, owner.value)
            const result = await userService.putUser(Id.value, payload)
            const refresh = await userService.getUser(Id.value)
            addUserData(refresh)
            await swal('Success', result.message, result.status)
            navigate('/user')
            setDataLoad(false)
          } catch (error) {
            return await CatchError(error, props)
          }
          break;

        default:
          break;
    }
  }

  const togglePassword = () => {
      setPasswordShown(!passwordShown);
  };
  const child = (
    <>
      {/* Main Content */}
        <div className="mainCard">
          <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
            <GlobalHeader
                header = {'Profile User'}
              />
            <DefaultInput
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              label="Username"
              type="text"
            />
            <DefaultInput
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label="Email"
              type="text"
            />
            <SelecttWithUserTier
              key={"1xcc"}
              label = {'User Mitra'}
              value = {owner}
              onChange = {ownerOnChange}
              disabled = {changeMitra}
              options = {ownerData}
              subFunction = {() => {}}
              // subFunction = {deleteSelect}
              checkText = {'Ubah Mitra'}
              checkValue = {changeMitra}
              checkValueOnChange = {setChangeMitra}
            />
            <DefaultInput
              id="registerPassword"
              name="password"
              type={passwordShown ? "text" : "password"}
              label = "Password"
              autoComplete="off"
              onChange={(e) => {
                if (!verifyLength(e.target.value, 1)) {
                  setregisterPasswordState("has-danger");
                } else {
                  setregisterPasswordState("has-success");
                }
                setregisterPassword(e.target.value);
              }}
            />
            {registerPasswordState === "has-danger" ? (
              <label className="error">Field Ini wajib di Isi.</label>
            ) : null}
            <DefaultInput
              equalto="#registerPassword"
              id="registerPasswordConfirmation"
              name="password_confirmation"
              type={passwordShown ? "text" : "password"}
              label = "Konfirmasi Password"
              autoComplete="off"
              value = {Password}
              onChange={(e) => {
                if (!compare(e.target.value, registerPassword)) {
                  setregisterConfirmPasswordState("has-danger");
                  setIsComplete(true)
                  setregisterPasswordState("has-danger");
                } else {
                  setregisterConfirmPasswordState("has-success");
                  setIsComplete(false)
                  setregisterPasswordState("has-success");
                }
                PasswordOnChange(e.target.value);
              }}
            />
            {registerConfirmPasswordState === "has-danger" ? (
              <label className="error">Field Ini wajib di Isi.</label>
            ) : null}
            <Checklist
                text = {'Show'}
                value={passwordShown}
                onChange={(e) => togglePassword(e.target.checked)}
            />
            <DefaultInput
              value={firstName}
              onChange={(e) => setFirstname(e.target.value)}
              label="nama Depan"
              type="text"
            />
            <DefaultInput
              value={lastName}
              onChange={(e) => setLastname(e.target.value)}
              label="Nama Belakang"
              type="text"
            />
            <DefaultInput
              value = {`${firstName} ${lastName}`}
              label="Nama Lengkap"
              type="text"
            />
            <SelectBasic
              label="Jenis User"
              name="singleSelect"
              value = {userType}
              option = {userTypeOptions}
              onChange={e => {
                if (e.value !== 'SUPERADMIN') {
                  userTypeOnChange(e)
                }
              }}
            />
            <DefaultInput
              value={organization}
              label="Organisasi"
              type="text"
            />
            <Checklist
              text = {'Aktif User'}
              value = {Enable}
              checked = {Enable}
              onChange = {e => EnableOnChage(e.target.checked)}
            />
            <div className="mt-6 flex flex-row gap-4">
              <PrimaryIconButton
                title="Update"
                icon = {faRefresh}
                onClick={EditUser}
                disabled = {IsComplete}
              />
            </div>
          </div>
        </div>
    </>
  )
  return (
    <>
      <main className="h-full">
        <Navbar toggle={sidebarToggle} />
        <LoadingScreen
          // key = {RandomTextGenerator()}
          children={child}
          loading = {dataLoad}
          text={"Saving..."}
        />
      </main>
    </>
  );
}

export default UserProfile;
