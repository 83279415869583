import React, { useState } from "react";
import Navbar from "../../components/Navbar/Index";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import DefaultInput from "../../components/Input/DefaultInput";
import { verifyLength } from "data/Variable/Utils";
import DateIncrementInput from "components/Input/DateIncrementInput";
import Checklist from "components/Button/Checklist";
import InputCoordinate from "components/Input/InputCoordinate";
import { CatchError } from "data/Handler/Exceptions/exceptions";
import swal from "sweetalert";
import { MitraPut } from "data/Variable/UserVariable";
// import { getUserData } from "data/Variable/UserVariable";
import { DateTimestr } from "data/Variable/Utils";
// import { getMitraData } from "data/Variable/UserVariable";
// import { getRefreshToken } from "data/Variable/Common";
import UserService from "data/Handler/UserService";
// import { getUserPermission } from "data/Variable/UserVariable";
import { PriceFormat } from "data/Variable/Common";
// import { addMitraData } from "data/Variable/UserVariable";
import { getUserType } from "data/Variable/UserVariable";
import PrimaryIconButton from "components/Button/PrimaryIconButton";
// import { RandomTextGenerator } from "data/Variable/Utils";
import LoadingScreen from "components/Loader/LoadingScreen";
import SelectBasic from "components/Input/SelectBasic";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import FreeRadiusDatabaseForm from "components/Other/FreeRadiusForm/FreeRadiusDatabaseForm";
import { verifyNetworkPortRange } from "data/Variable/Utils";
import { GlobalHeader } from "components/Datatables/GlobalHeader";

function ResellerDetail(props) {
  const [sidebarToggle] = useOutletContext();
  // const pages = 'userManager'
  const currency = 'idr'
  const navigate = useNavigate()
  const location = useLocation()
  const { state } = location
  // const permission = getUserPermission()
  // const Permission = permission.find(data => data.Role === pages) || ''
  // const PermissionCustomer = permission.find(data => data.Role === 'customer') || ''
  const userService =  new UserService()
  const mitraData = state // || getMitraData()
  const [email, setEmail] = useState(mitraData.email)
  const [name] = useState(mitraData.name)
  const [firstname, setFirstname] = useState(mitraData.firstname)
  const [lastname, setLastname] = useState(mitraData.lastname)
  const [ktpNumber, ktpNumberOnChange] = useState(mitraData.ktp)
  const [npwp, npwpOnChange] = useState(mitraData.npwp)
  const [MobileNo, mobileNoOnChange] = useState(mitraData.mobileNo)
  const [isGenerate, isGenerateOnChage] = useState(mitraData.generateInvoice || false)
  const namingSeries = ''
  const generateDate = DateTimestr(mitraData.generateTime)
  const [Date, DateOnChange] = useState(mitraData.generateTime)
  const [created] = DateTimestr(mitraData.creation)
  const [modified] = DateTimestr(mitraData.modified)
  const [createdBy] = useState(mitraData.modifiedBy)
  const [modifiedBy] = useState(mitraData.modifiedBy)
  const [enabled, enabledOnChage] = useState(mitraData.enabled)
  const [minLengthState, setminLengthState] = React.useState("");
  const [minLengthNpwpState, setminLengthNpwpState] = React.useState("");
  const [minLengthPhoneState, setminLengthPhoneState] = React.useState("");
  const [due, dueOnChange] = useState(mitraData.dueDate)
  const TypeOptions = getUserType()
  const [Type, TypeOnChange] = useState(TypeOptions.find(e => e.label === mitraData.type))
  const [ppn, ppnOnChange] = useState(mitraData.ppn)
  const [beforeDue, beforeDueOnChange] = useState(mitraData.beforeDue)
  const [administrationFee, setAdministrationFee] = useState(mitraData.administrationFee)
  const [maxMergeDate, setMaxMergeDate] = useState(mitraData.maxMergeDate)
  const [maxMergePrice, setMaxMergePrice] = useState(mitraData.maxMergePrice)
  const refKey = mitraData.referenceKey
  const province = mitraData.province
  const [latitude, setLatitude] = useState(mitraData.latitude)
  const [longitude, setLongitude] = useState(mitraData.longitude)
  const [instalationFee, setInstalationFee] = useState(mitraData.instalationFee)
  const [memberFee, setMemberFee] = useState(mitraData.memberFee)
  const [syncVa /*, setSyncVa */] = useState(mitraData.syncVa)
  const [syncHostpot, setSyncHostpot] = useState(mitraData.syncHostpot)
  const [loading, setLoading] = useState(false)
  const [mysqlHost, setMysqlHost] = useState(mitraData.defaultRadiusDbUrl)
  const [mysqlPort, setMysqlPort] = useState(mitraData.defaultRadiusDbPort)
  const [mysqlDatabase, setMysqlDatabase] = useState(mitraData.defaultRadiusDbDatabase)
  const [mysqlUsername, setMysqlUsername] = useState(mitraData.defaultRadiusDbUsername)
  const [mysqlPassword, setMysqlPassword] = useState(mitraData.defaultRadiusDbPassword)
  const [passwordShow, setPasswordShow] = useState(false)

  const putMitra = async() => {
    // console.log(maxMergeDate, maxMergePrice)
    const state = await swal("Update", "Data Tidak Dapat di Recover", "info", {
            buttons: {
            update: {text: "Update", value: 'up' },
            cancel: 'Cancel'
          }
        }
    )
    switch (state) {
      case 'up':
        try {
          setLoading(true)
          const payload =  MitraPut(name, email, firstname, lastname,
                            MobileNo, ktpNumber, npwp, enabled, namingSeries, isGenerate,
                            Date, Type.value, due, ppn, beforeDue, administrationFee, maxMergeDate,
                            maxMergePrice, latitude, longitude, instalationFee, memberFee, syncVa,
                            syncHostpot, mysqlHost, mysqlDatabase, mysqlPort, mysqlUsername, mysqlPassword)

          // console.log(payload)
          const result = await userService.putMitra(name, payload)
          await swal(result.status, result.message, 'success')
          setLoading(false)
          navigate('/reseller')
        } catch (error) {
          setLoading(false)
          return await CatchError(error, props)
        }
        break
      default:
        break;
    }
  }

  const child = (
    <>
      {/* Main Content */}
        <div className="mainCard">
          <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
            {/* <form> */}
            <GlobalHeader
                header = {'Detail Reseller'}
              />
                <DefaultInput
                    key = {1}
                    label = {"Province *"}
                    value = {province}
                    disabled
                    onChange = {() => {}}
                  />
                  <InputCoordinate
                    // key = {2}
                    latitude = {latitude}
                    setLatitude = {setLatitude}
                    longitude = {longitude}
                    setLongitude = {setLongitude}
                  />
                  <DefaultInput
                    key = {3}
                    label = {"Reference Key"}
                    value = {refKey}
                    disabled
                    onChange = {() => {}}
                  />
                  <DefaultInput
                    label="firstname"
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                    type="text"
                  />
                  <DefaultInput
                    label="lastname"
                    value = {lastname}
                    onChange={(e) => setLastname(e.target.value)}
                    type="text"
                  />
                  <DefaultInput
                    label="fullname"
                    value={`${firstname} ${lastname}`}
                    type="text"
                  />
                  <div>
                  <DefaultInput
                    label="Email *"
                    name="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                <DefaultInput
                  label="KTP *"
                  name="min_length_ktp"
                  type="number"
                  value = {ktpNumber}
                  onChange={(e) => {
                    if (!verifyLength(e.target.value, 17)) {
                      ktpNumberOnChange(e.target.value)
                    if (!verifyLength(e.target.value, 16)) {
                      setminLengthState("has-danger");
                    } else {
                      setminLengthState("has-success");
                    }
                    }
                  }}
                />
                {minLengthState === "has-danger" ? (
                  <label className="error">
                     KTP harus 16 Karakter.
                  </label>
                ) : null}
               </div>
               <div>
                <DefaultInput
                  label="NPWP"
                  name="min_length_npwp"
                  type="number"
                  value = {npwp}
                  onChange={(e) => {
                    if (!verifyLength(e.target.value, 16)) {
                      npwpOnChange(e.target.value)
                      if (!verifyLength(e.target.value, 15)) {
                        setminLengthNpwpState("has-danger");
                      } else {
                        setminLengthNpwpState("has-success");
                      }
                    }
                  }}
                />
                {minLengthNpwpState === "has-danger" ? (
                  <label className="error">
                    Please enter at least 16 characters.
                  </label>
                ) : null}
               </div>
               <div>
                <DefaultInput
                  label="Nomor Telepon *"
                  name="min_length_phone"
                  type="number"
                  value={MobileNo}
                  onChange={(e) => {
                    if (!verifyLength(e.target.value, 13)) {
                      mobileNoOnChange(e.target.value)
                      if (!verifyLength(e.target.value, 11)) {
                        setminLengthPhoneState("has-danger");
                      } else {
                        setminLengthPhoneState("has-success");
                      }
                    }
                  }}
                />
                {minLengthPhoneState === "has-danger" ? (
                  <label className="error">
                    Nomor Telepon Minimal 8 Karakter dan Maksimal 13 Karakter.
                  </label>
                ) : null}
               </div>
                <SelectBasic
                    label = 'Type *'
                    name="singleSelect"
                    value = {Type}
                    option = {TypeOptions}
                    onChange={e => TypeOnChange(e)}
                  />
                  <DefaultInput
                    label = 'Created'
                    value = {created}
                    onChange={()=>{}}
                    type="text"
                  />
                  <DefaultInput
                    label="Modified"
                    value = {modified}
                    onChange={() => {}}
                    type="text"
                  />
                  <DefaultInput
                    label="created By"
                    value = {createdBy}
                    onChange={() => {}}
                    type="email"
                  />
                  <DefaultInput
                    label="Modified By"
                    value = {modifiedBy}
                    onChange={() => {}}
                    type="email"
                  />
                <div>
                  <Checklist
                    key = {5}
                    text = 'Active'
                    value = {enabled}
                    checked = {enabled}
                    onChange = {e => enabledOnChage(e.target.checked)}
                  />
                  <Checklist
                    key = {6}
                    text = 'Auto Generate Invoice'
                    value = {isGenerate}
                    checked = {isGenerate}
                    onChange = {e => isGenerateOnChage(e.target.checked)}
                  />
                  <DefaultInput
                    label="Generate"
                    value = {generateDate}
                    onChange={() => {}}
                    type="text"
                  />
                  <DefaultInput
                    className = "generate"
                    label="Set Invoice Generate Date"
                    disabled = {!isGenerate}
                    value = {Date}
                    onChange = {e => {
                      DateOnChange(e.target.value)
                    }}
                    type="datetime-local"
                  />
                  <DateIncrementInput
                    key = {7}
                    labelInput = {"Jatuh Tempo (MIN -30 MAX 120 Day)"}
                    labelOutput = {'Tanggal Tertampil'}
                    value = {due}
                    onChange={(e) => {
                      if (e.target.value >= -30 && e.target.value <= 120) {
                        dueOnChange(e.target.value)
                      }
                    }}
                  />
                  <DateIncrementInput
                    key = {8}
                    labelInput = {"Notifikasi Sebelum Jatuh Tempo (MAX 30 Day)"}
                    labelOutput = {'Tanggal Tertampil'}
                    value = {beforeDue}
                    dueDisplayValue = {due}
                    decr = {true}
                    onChange={(e) => {
                      if (e.target.value >= 0 && e.target.value <= 30) {
                        beforeDueOnChange(e.target.value)
                      }
                    }}
                  />
                  <DefaultInput
                    value = {ppn}
                    label="PPN (Tax Percentage)"
                    type="number"
                    onChange={(e) => {
                      if (e.target.value <= 100) {
                        ppnOnChange(e.target.value)
                      }
                    }}
                  />
                  <FreeRadiusDatabaseForm
                    mysqlHost = {mysqlHost}
                    setMysqlHost = {(e) => {
                      setMysqlHost(e.target.value)
                    }}
                    mysqlPort = {mysqlPort}
                    setMysqlPort = {(e) => {
                      if (verifyNetworkPortRange(e)) {
                        setMysqlPort(e.target.value)
                      }
                    }}
                    mysqlDatabase = {mysqlDatabase}
                    setMysqlDatabase = {(e) => {
                      setMysqlDatabase(e.target.value)
                    }}
                    mysqlUsername = {mysqlUsername}
                    setMysqlUsername = {(e) => {
                      setMysqlUsername(e.target.value)
                    }}
                    mysqlPassword = {mysqlPassword}
                    setMysqlPassword = {(e) => {
                      setMysqlPassword(e.target.value)
                    }}
                    passwordShow = {passwordShow}
                    setPasswordShow = {setPasswordShow}
                    header = {'Default Radius Mysql Connection'}
                    mysqlHostLabel = {'Default Radius MSQL Database Host'}
                    mysqlPortLabel = {'Radius MSQL Database Port'}
                    mysqlDatabaseNameLabel = {'Radius MSQL Database Name'}
                    mysqlDatabaseUsernameLabel = {'Radius MSQL Database Username'}
                    mysqlDatabasePasswordLabel = {'Radius MSQL Database Password'}
                    bgColor = {'bg-amber-200'}
                  />
                  <div className="row">
                    <div className="col md-6">
                      <DefaultInput
                        key = {9}
                        label = {'Biaya Admin'}
                        value = {administrationFee}
                        type = {"number"}
                        onChange = {(e) => setAdministrationFee(e.target.value)}
                      />
                    </div>
                    <div className="col md-6">
                      <DefaultInput
                        key = {10}
                        value = {PriceFormat(administrationFee, currency)}
                        onChange = {()=>{}}
                        disabled
                        label="Harga Tertampil"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col md-6">
                      <DefaultInput
                        key = {11}
                        label = {'Biaya Instalasi Jaringan'}
                        value = {instalationFee}
                        type = {"number"}
                        onChange = {(e) => setInstalationFee(e.target.value)}
                      />
                    </div>
                    <div className="col md-6">
                      <DefaultInput
                        key = {12}
                        value = {PriceFormat(instalationFee, currency)}
                        onChange = {()=>{}}
                        disabled
                        label={"Harga Tertampil"}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col md-6">
                      <DefaultInput
                        key = {13}
                        label = {'Biaya Member Reseller'}
                        value = {memberFee}
                        type = {"number"}
                        onChange = {(e) => setMemberFee(e.target.value)}
                      />
                    </div>
                    <div className="col md-6">
                      <DefaultInput
                        key = {14}
                        value = {PriceFormat(memberFee, currency)}
                        onChange = {()=>{}}
                        disabled
                        label = {"Harga Tertampil"}
                      />
                    </div>
                  </div>
                  <DefaultInput
                    value = {maxMergeDate}
                    label="Tanggal minimal Pengabungan Invoice (Customer Baru)"
                    type="number"
                    onChange={(e) => {
                      if (e.target.value >= 1 && e.target.value <= 25) {
                        setMaxMergeDate(e.target.value)
                      }
                    }}
                  />
                  <DefaultInput
                    key = {15}
                    label = {'Harga Maksimal Pengabungan Invoice (Customer Lama)'}
                    value = {maxMergePrice}
                    type = {"number"}
                    onChange = {(e) => setMaxMergePrice(e.target.value)}
                  />
                  <input
                    value = {PriceFormat(maxMergePrice, currency)}
                    label="Harga Maksimal Pengabungan Invoice"
                  />
                </div>
                {/* <Checklist
                  key = {16}
                  text = 'Sinkronisasi Harian Virtual Account'
                  value = {syncVa}
                  checked = {syncVa}
                  onChange = {(e) => setSyncVa(e.target.checked)}
                /> */}
                <Checklist
                  key = {17}
                  text = 'Sinkronisasi Hostpot'
                  value = {syncHostpot}
                  checked = {syncHostpot}
                  onChange = {(e) => setSyncHostpot(e.target.checked)}
                />
              <div className="mt-6 flex flex-row gap-4">
                <PrimaryIconButton
                  title="Update"
                  icon = {faRefresh}
                  onClick={putMitra}
                />
              </div>
            {/* </form> */}
          </div>
        </div>
    </>
  )

  return (
    <>
      <main className="h-full">
        <Navbar toggle={sidebarToggle} />
        <LoadingScreen
          // key = {RandomTextGenerator()}
          children={child}
          loading = {loading}
          text={"Saving..."}
        />
      </main>
    </>
  );
}

export default ResellerDetail;
