import ConsumeAPI from './ConsumeAPI'
class AuthService {
    constructor(){
        this._consume = new ConsumeAPI()
        this._path = 'auth/login'
    }

    async Login(email, password) {
        const payload = {
            Email: email.value || email,
            Password: password.value || password
        }
        const result = await this._consume.postData(payload, this._path)
        return result.data
    }

   async verifyLoginPayload (email, pass) {
    if (email.lenght === 0 && pass.lenght === 0) {
        throw new Error('Email and Password Required')
    } else if (email.lenght === 0) {
        throw new Error('Email Required')
    } else if (pass.lenght === 0) {
        throw new Error('Password Required')
    }
}
}



export default AuthService