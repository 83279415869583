import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function PrimaryIconButton({title = 'Primary Button', value, onClick, disabled, icon}) {
    return (
        <button className="bg-emerald-600 border-emerald-600 text-gray-100 px-3 py-2 rounded-lg shadow-lg text-sm flex gap-2 items-center"
            value={value}
            onClick={onClick}
            disabled = {disabled}
        >
          <div>
            <FontAwesomeIcon icon={icon || faAdd} />
          </div>
          <span>{title}</span>
        </button>
    )
}