import React, { useEffect, useState } from "react";
import Navbar from "./../../../components/Navbar/Index";
import { useNavigate, useOutletContext } from "react-router-dom";
import MainTable from "../../../components/Table/MainTable";
import { EditButton } from "components/Button/EditButton";
import { DeleteButton } from "components/Button/DeleteButton";
import { ColumnsAdmin, ColumnsTrashAdmin, ColumnsTrashUser, ColumnsUser } from "./PppoeUserHeader";
import PrimaryIconButton from "components/Button/PrimaryIconButton";
import { RandomTextGenerator } from "data/Variable/Utils";
import PppoeUserService from "data/Handler/Pppoe/PppoeUserService";
import UserService from "data/Handler/UserService";
import { getUserPermission } from "data/Variable/UserVariable";
import { verifySuperAdmin } from "data/Variable/Utils";
import { getOwner } from "data/Variable/UserVariable";
import { getUserSelect } from "data/Variable/Common";
import { CatchError } from "data/Handler/Exceptions/exceptions";
import { validatePermission } from "data/Variable/Utils";
import swal from "sweetalert";
import { payloadPppoeProfileTrash } from "data/Variable/Pppoe/PppoeProfile";
import { addPppoeUserData, payloadPppoeUserPaid } from "data/Variable/Pppoe/PppoeUser";
import { customTextFromBoolean } from "data/Variable/Utils";
import PrimaryButton from "components/Button/PrimaryButton";
import NoLabelSelect from "components/Input/NoLabelSelect";
// import Checklist from "components/Button/Checklist";
import { faResolving } from "@fortawesome/free-brands-svg-icons";

function PppoeUserList(props) {
  const [sidebarToggle] = useOutletContext();
  const navigate = useNavigate()
  const page = 'voucherpppoe'
  const pppoeUserService = new PppoeUserService()
  const userService = new UserService()
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role.toLowerCase() === page) || ''
  const [load, setDataLoad] = useState(true)
  const [Table, setDataTable] = useState([])
  const [viewTrashPage, viewTrashPageOnChange] = useState(false)
  const { mitraSuper, organization, id: userId } = verifySuperAdmin()
  const Columns = mitraSuper ? ColumnsAdmin : ColumnsUser
  const ColumnsTrash = mitraSuper ? ColumnsTrashAdmin : ColumnsTrashUser
  const ownerOption = getOwner()
  const [resellerOption, setResellerOption] = useState(getUserSelect())
  const [mitra, setMitra] = useState(ownerOption.find((data) => data.value === organization))
  const [reseller, setReseller] = useState(resellerOption.find((data) => data.userId === userId))

  async function GetReseller(mitra) {
    const resellerList = await userService.getUserSelect(mitra)
    const resellerData = resellerList.data

    setResellerOption(resellerData)

    const defaultReseller = resellerData.find((data) => data.userId === userId)
    const finalSelection = !defaultReseller ? resellerData[0] : defaultReseller
    setReseller(finalSelection)
    return finalSelection.userId
  }

  const getAll =  async(trash = false, mitra = '', reseller = '') => {
    try {
      setDataLoad(true)
      // console.log({ trash, mitra, reseller })
      const len = 'ALL'
      const dat =  await pppoeUserService.getAll(len, trash, mitra, reseller)
      const result = dat.data

      setDataState(mapping(result))
      setDataTable(result)
      setDataLoad(false)
      return result
    } catch (error) {
      setDataLoad(false)
      return await CatchError(error, props)
    }
  }

  useEffect(() => {
    validatePermission(props, 0, 1)
    GetReseller(mitra?.mitraid)
    getAll(viewTrashPage, mitra?.mitraid, reseller?.userId)
  }, [])

    const deleteData = async (id) => {
      const selection = await swal("Delete", "Data Tidak Dapat di Recover", "info", {
        buttons: {
          cancel: 'Batal',
          delete: {text: "Hapus", value: 'del' }
        }}
      )

      switch (selection) {
          case 'del':
            try {
              setDataLoad(true)
              const result = await pppoeUserService.delete(id)
              await swal(result.status, result.message, 'success')
              await getAll(viewTrashPage, mitra?.mitraid, reseller?.userId)
              const newState = dataState.filter(data => data.id !== id)
              setDataState(newState)
              viewTrashPageOnChange(false)
              setDataLoad(false)
              return true
            } catch (error) {
              return await CatchError(error, props)
            }

          default:
            await getAll(viewTrashPage, mitra?.mitraid, reseller?.userId)
            return false
        }
    }

    const sendTrash = async (id, trash) => {
      try {
          setDataLoad(true)
          const payload = payloadPppoeProfileTrash(trash)
          const result = await pppoeUserService.setTrash(id, payload)
          await swal('Success', result.message, 'success')
          if (trash === true) {
            const state = await getAll(viewTrashPage, mitra?.mitraid, reseller?.userId)
            const stateRaw = mapping(state)
            const newState = stateRaw.filter(data => data.id !== id)
            setDataState(newState)
          } else {
            await getAll(viewTrashPage, mitra?.mitraid, reseller?.userId)
            viewTrashPageOnChange(false)
          }
          setDataLoad(false)
          return true
      } catch (error) {
        return await CatchError(error, props)
      }
    }

    const sendToTrash = async (id) => {
      const setTrash = await swal("Trash", "Send To Trash", "info", {
        buttons: {
          cancel: 'Cancel',
          delete: {text: "Continue", value: 'del' }
        }}
      )
      switch (setTrash) {
        case 'del':
          const trash = true
          return await sendTrash(id, trash)
        default:
          await getAll(viewTrashPage, mitra?.mitraid, reseller?.userId)
          return false
      }
    }

    const recoverTrash = async (id) => {
      const setTrash = await swal("Recover", "Recover Data", "info", {
        buttons: {
          cancel: 'Cancel',
          delete: {text: "Continue", value: 'del' }
        }}
      )

      switch (setTrash) {
        case 'del':
          const trash = false
          return await sendTrash(id, trash)
        default:
          return false
      }
    }

  const viewData = async(id) => {
    try {
      const dat = await pppoeUserService.get(id)
      const result = dat.data
      addPppoeUserData(result)
      return navigate('/pppoe/user/detail', {state: result})
    } catch (error) {
      return await CatchError(error, props)
    }
  }

  const setPaidStatus = async(id, status) => {
    try {
      setDataLoad(true)
      var operation = status ? 'Aktif' : 'Suspend'
      const payload = payloadPppoeUserPaid(status)
      const dat = await pppoeUserService.setPaid(id, payload)
      await swal(operation, dat.message, dat.status)
      const result = dat.data
      const newState = await getAll(viewTrashPage, mitra?.mitraid, reseller?.userId)
      setDataState(mapping(newState))
      setDataLoad(false)
      return result
    } catch (error) {
      setDataLoad(false)
      await swal('failed', error.message, 'warning')
    }
  }

  const mapping =  (Table) => {
    return Table.map((Data, i) => {
      try {
          return {
            id: Data.id,
            name: Data.name,
            pppoeUsername: Data.pppoeUsername,
            pppoePassword: Data.pppoePassword,
            mitra: Data.mitra,
          setPaidAdmin: (
              <div className="actions-center">
                  <PrimaryButton
                    value = {Data.isPaid}
                    onClick = {async (e) => {
                      const status = !Data.enabled
                      await setPaidStatus(Data.id, status)
                    }}
                    status = {Data.enabled}
                    title = {!Data.enabled ? 'Suspended' : 'Active'}
                    disableText='Suspended'
                    enableText='Active'
                    disableColor='warning'
                    enableColor = 'success'
                    disabled = {Permission.Update}
                  />
              </div>
            ),
            setPaidUser: customTextFromBoolean(Data.enabled, 'Bayar', 'Belum-Bayar'),
            actionsUser: (
              <div className="actions-right" key={Data.id}>
                <EditButton
                  onClick={ async () => {
                    await viewData(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}`}
                  disabled = {!Permission.Read}
                  color="warning"
                  size="sm"
                />
              </div>
            ),
            actions: (
              // we've added some custom button actions
              <div key={Data.id}>
                <EditButton
                  onClick={ async () => {
                    await viewData(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}`}
                  disabled = {!Permission.Read}
                  color="warning"
                  size="sm"
                />
                <DeleteButton
                  onClick={async () => {
                    await sendToTrash(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}1`}
                  disabled = {!Permission.Delete}
                  color="danger"
                  size="sm"
                />
              </div>
            ),
            recover: (
              <PrimaryIconButton
                title = {'Recover'}
                  onClick={ async () => {
                    await recoverTrash(Data.id)
                  }}
                  className="btn"
                  id={`tooltip${Data.id}`}
                  disabled = {!Permission.Update}
                  color="warning"
                  size="sm"
                />
            ),
            actionsTrash: (
              <div className="actions-right" key={Data.id}>
                <DeleteButton
                  onClick={ async () => {
                    await deleteData(Data.id)
                  }}
                  className="btn-icon"
                  id={`tooltip${Data.id}1`}
                  disabled = {!Permission.Delete}
                  color="danger"
                  size="sm"
                />
              </div>
            ),
        };
        } catch (error) {
          return []
        }
      }
    )
  }

  const [dataState, setDataState] = useState(mapping(Table))

  // const viewTrash = async (trash) => {
  //   try {
  //     setDataLoad(true)
  //     viewTrashPageOnChange(!trash)
  //     await getAll(!trash, mitra?.mitraid, reseller?.userId)
  //     setDataLoad(false)
  //   } catch (error) {
  //     setDataLoad(false)
  //     await CatchError(error, props)
  //   }
  // }

  function onClickAdd() {
    return navigate('/pppoe/user/form')
  }

  const menu = (
    <>
    <div className="flex flex-row my-3">
      <div className="p-4 flex md:flex-col mr-5">
        <PrimaryIconButton
          key={RandomTextGenerator()}
          title = {'Add'}
          onClick = {onClickAdd}
        />
      </div>
      <div className="p-1 flex md:flex-col mr-5">
          <NoLabelSelect
            placeholder = {"Mitra"}
            value = {mitra}
            option = {ownerOption}
            isDisabled = {!mitraSuper}
            onChange = {async (e) => {
              setMitra(e)
              const resellerDefault = await GetReseller(e?.mitraid)
              await getAll(viewTrashPage, e?.mitraid, resellerDefault)
            }}
          />
      </div>
      <div className="p-1 flex md:flex-col mr-5">
          <NoLabelSelect
            placeholder = {"Reseller"}
            value = {reseller}
            option = {resellerOption}
            isDisabled = {!mitraSuper}
            onChange = {async (e) => {
              setReseller(e)
              await getAll(viewTrashPage, mitra?.mitraid, e?.userId)
            }}
          />
      </div>
      {/* <div className="flex md:flex-col mr-10">
        <Checklist
          key={RandomTextGenerator()}
          text = {'Trash'}
          onClick = {async (e) => {
            const { checked } = e.target
            viewTrashPageOnChange(checked)
            await viewTrash(checked)
          }}
        />
      </div> */}
    </div>
    </>
  )

  return (
    <>
      <main className="h-full">
        <Navbar toggle={sidebarToggle} />

        {/* Main Content */}
        <div className="mainCard">
          <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
            <MainTable
              key={RandomTextGenerator()}
              header = {!viewTrashPage ? 'PPPOE User Manager' : 'Trash'}
              menu={menu}
              loading={load}
              dataHeader={!viewTrashPage ? Columns : ColumnsTrash}
              dataState={dataState}
            />
          </div>
        </div>
      </main>
    </>
  );
}

export default PppoeUserList;
