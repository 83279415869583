import React from "react";
import PrimaryButton from "components/Button/PrimaryButton"
import LoadingScreen from "components/Loader/LoadingScreen";
import { Modal } from "react-bootstrap";

// const ModalView = ({open, close, child, loading, handleClose, handleAction, actionTitle = 'Print', actionColor = "primary", title = "Detail Invoice"}) => {
//   const childModal = child || (
//     <>
//       <div className="bootstrap-iso p-6 flex-auto">
//           No Data
//       </div>
//     </>
//   )

//   return (
//     <>
//       {open ? (
//         <>
//           <div
//             className="bootstrap-iso justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
//           >
//             <div className="relative w-auto my-10 mx-auto max-w-3xl">
//               {/*content*/}
//               <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
//                 {/*header*/}
//                 <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
//                   <h3 className="text-3xl font-semibold">
//                     { title }
//                   </h3>
//                   <button
//                     className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
//                     onClick={() => close(false)}
//                   >
//                   </button>
//                 </div>
//                 {/*body*/}
//                 <div className="content relative p-6 flex-auto">
//                     {childModal}
//                 </div>
//                 {/*footer*/}
//                 <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-600 rounded-b">
//                   <PrimaryButton
//                     variant="primary"
//                     title = "Close"
//                     className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
//                     onClick={handleClose}
//                     disabled = {loading}
//                   />
//                   {
//                       (typeof handleAction === 'function') ? (
//                           <PrimaryButton
//                             variant={actionColor}
//                             title = {actionTitle}
//                             className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
//                             disabled = {loading}
//                             onClick = {handleAction}
//                         />
//                       )
//                       :
//                       (<></>)
//                    }
//                 </div>
//               </div>
//             </div>
//           </div>
//         </>
//       ) : <></>}
//     </>
//   );
// };


 export const ModalView = ({open, close, child, loading, handleClose, handleAction, actionTitle = 'Print', actionColor = "primary"}) => {
    return (
        <>
          <Modal
            show={open}
            onHide={close}
            backdrop= {true}
            dialogClassName="modal-150w"
            keyboard={false}
            size="lg"
            centered
          >
            <Modal.Header
              closeButton = {false}
              className="modal-color bg-gray-300">
              <h5 className="font-weight-bold">Informasi Tagihan</h5>
            </Modal.Header>
            <Modal.Body className="bg-gray-200 p-4">
                  <div className="bg-white">
                    <LoadingScreen
                      children={child || (<p>No-Child</p>)}
                      loading={loading}
                    />
                  </div>
            </Modal.Body>
            <Modal.Footer className="bg-gray-300">
               {
                (typeof handleAction === 'function') ? (
                  <PrimaryButton
                    variant={actionColor}
                    title = {actionTitle}
                    className="bg-green-800 bold px-3 py-2 rounded-lg shadow-lg text-sm text-white"
                    onClick={handleAction}
                    // disabled = {download}
                  />
                ) : (<></>)
               }

              <PrimaryButton
                variant="primary"
                title = "Close"
                className="bg-red-600 bold px-3 py-2 rounded-lg shadow-lg text-sm text-white"
                onClick={handleClose}
                disabled = {loading}/>
            </Modal.Footer>
          </Modal>
        </>
    )
  }

export default ModalView