import React, { useEffect, useState } from "react";
import Navbar from "./../../../components/Navbar/Index";
import { useNavigate, useOutletContext } from "react-router-dom";
import DefaultInput from "../../../components/Input/DefaultInput";
import SelecttWithUserTier from "../../../components/Input/SelectWithUserTier";
import Checklist from "components/Button/Checklist";
import SelectBasic from "components/Input/SelectBasic";
import { CatchError } from "data/Handler/Exceptions/exceptions";
import { payloadPppoeUser } from "data/Variable/Pppoe/PppoeUser";
import { verifySuperAdmin } from "data/Variable/Utils";
import PppoeUserService from "data/Handler/Pppoe/PppoeUserService";
import PppoeProfileService from "data/Handler/Pppoe/PppoeProfileService";
import RouterNasService from "data/Handler/RouterNasService";
import UserService from "data/Handler/UserService";
import { getOwner } from "data/Variable/UserVariable";
import swal from "sweetalert";
import PrimaryIconButton from "components/Button/PrimaryIconButton";
import LoadingScreen from "components/Loader/LoadingScreen";
import { GlobalHeader } from "components/Datatables/GlobalHeader";
import InputCoordinate from "components/Input/InputCoordinate";
import { verifyLength } from "data/Variable/Utils";
import ResourceService from "data/Handler/ResourceService";

function PppoeUserForm(props) {
  const [sidebarToggle] = useOutletContext();
  const navigate = useNavigate()
  const pppoeUserService =  new PppoeUserService()
  const pppoeProfileService = new PppoeProfileService()
  const routerService = new RouterNasService()
  const resourceService = new ResourceService()
  const { mitraSuper, organization, id: userId, userAdmin } = verifySuperAdmin()
  const userService = new UserService()
  const ownerData = getOwner()
  const [mitra, setMitra] = useState(ownerData.find(own => own.value === organization))
  const [loading, loadingOnChange ] = useState(false)
  const [changeMitra, changeMitraOnChange] = useState(false)
  const [pppoeProfileId, setPppoeProfileId] = useState({})
  const [name, setName] = useState('')
  const [firstname, setFirstName] = useState('')
  const [lastname, setLastName] = useState('')
  const [latitude, setLatitude] = useState(0.0)
  const [longitude, setLongitude] = useState(0.0)
  const [description, setDescription ] = useState('')
  const [resellerOption, setResellerOption] = useState([]) // getUserSelect())
  const [pppoeProfileOption, setPppoeProfileOption] = useState([])
  const [routerSelectOption, routerSelectOptionOnChange] = useState([])
  const [router, setRouter] = useState('')
  const [minLengthState, setminLengthState] = React.useState("");
  const [minLengthNpwpState, setminLengthNpwpState] = React.useState("");
  const [minLengthPhoneState, setminLengthPhoneState] = React.useState("");
  const [email, setEmail] = useState('')
  const [ktpNumber, ktpNumberOnChange] = useState('')
  const [MobileNo, mobileNoOnChange] = useState('')
  const [npwp, npwpOnChange] = useState('')
  const [enabled, setEnabled] = useState(false)
  const [pppoeUsername, setPppoeUsername] = useState('')
  const [pppoePassword, setPppoePassword] = useState('')
  const [addressTitle, setAddressTitle] = useState('')
  const [addressLine, setAddressLine] = useState('')
  const [reseller, setReseller] = useState({})
  const [discount, setDiscount] = useState(0)
  const [city , cityOnChange] = useState('')
  const [subDistrict , subDistrictOnChange] = useState('')
  const [urban , urbanOnChange] = useState('')
  const [cityOption, cityOptionOnChange] = useState([])
  const [subDistrictOption, subDistrictOptionOnChange] = useState([])
  const [UrbanOption, urbanOptionOnChange] = useState([])

    const CityLoad = async(provice) => {
      const dat = await resourceService.getCity(provice)
      const result = dat.data
      // console.log(result)
      // addCity(result)
      cityOptionOnChange(result)
      return result
    }

    const subDistrictLoad = async(subDistrict) => {
      const dat = await resourceService.getSubDistrict(subDistrict)
      const result = dat.data
      // console.log(result)
      // addSubDistrict(result)
      subDistrictOptionOnChange(result)
      return result
    }

    const UrbanLoad = async(urban) => {
      // sessionStorage.removeItem('urban')
      const dat = await resourceService.getUrban(urban)
      const result = dat.data
      // console.log(result)
      // addUrban(result)
      urbanOptionOnChange(result)
      return result
    }

  const getRouter = async(mitraid) => {
    const dat = await routerService.getRouterSelect(mitraid)
    const result = dat.data
    // setReseller([])
    // setpppoeProfileId([])
    routerSelectOptionOnChange(result)
    return result
  }

  async function GetReseller(mitra) {
      const resellerList = await userService.getUserSelect(mitra)
      const resellerData = resellerList.data

      setResellerOption(resellerData)

      const defaultReseller = resellerData?.find((data) => data.userId === userId)
      const finalSelection = !defaultReseller ? resellerData[0] : defaultReseller
      setReseller(finalSelection)
      return finalSelection?.userId
  }

  async function GetpppoeProfile(routerId) {
      const pppoeList = await pppoeProfileService.getProfileSelect(routerId)
      const pppoeData = pppoeList.data

      setPppoeProfileOption(pppoeData)
      return pppoeData
  }

  useEffect(() => {
    getRouter(mitra?.mitraid)
    GetReseller(mitra?.mitraid)
  }, [])

  const submit = async(e) => {
    e.preventDefault()
    try {
      loadingOnChange(true)
      const payload = payloadPppoeUser(
          mitra?.mitraid,
          router?.province,
          city?.value,
          subDistrict?.value,
          urban?.value,
          reseller?.userId,
          name,
          firstname,
          lastname,
          ktpNumber,
          npwp,
          MobileNo,
          pppoeUsername,
          pppoePassword,
          addressTitle,
          addressLine,
          discount,
          enabled,
          latitude,
          longitude,
          pppoeProfileId?.value,
          description
      )
      // console.log(payload)
      const result = await pppoeUserService.post(payload)
      await swal(result.status, result.message, "success")
      loadingOnChange(false)
      return navigate('/pppoe/user')
    } catch (error) {
      loadingOnChange(false)
      return await CatchError(error, props)
  }}

  const child = (
    <>
        {/* Main Content */}
        <div className="mainCard">
          <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
            {/* <form> */}
            <GlobalHeader
                header = {'Tambah PPPOE User'}
              />
          <div>
            <InputCoordinate
              latitude = {latitude}
              setLatitude = {setLatitude}
              longitude = {longitude}
              setLongitude = {setLongitude}
            />
           <SelecttWithUserTier
              key={"1"}
              label = {'Mitra'}
              value = {mitra}
              onChange = {setMitra}
              disabled = {changeMitra}
              subFunction = {async (e) => {
                routerSelectOptionOnChange([])
                setResellerOption([])
                setPppoeProfileOption([])
                setReseller('')
                setPppoeProfileId('')
                setRouter('')
                await getRouter(e?.mitraid)
                await GetReseller(e?.mitraid)
              }}
              options = {ownerData}
              checkText = {'Change Mitra'}
              checkValue = {changeMitra}
              checkValueOnChange = {changeMitraOnChange}
            />
            <SelectBasic
              key={"2"}
              label = {"Reseller"}
              value = {reseller}
              option = {resellerOption}
              isDisabled = {!mitraSuper && !userAdmin}
              onChange = {async (e) => {
                setReseller(e)
              }}
            />
            <DefaultInput
              key={"3"}
              label = {'Nama'}
              value = {name}
              onChange = {(e) => setName(e.target.value)}
              type
            />
            <DefaultInput
              key={"4"}
              label = {'Nama Depan'}
              value = {firstname}
              onChange = {(e) => setFirstName(e.target.value)}
              type
            />
            <DefaultInput
              key={"5"}
              label = {'Nama Belakang'}
              value = {lastname}
              onChange = {(e) => setLastName(e.target.value)}
              type
            />
            <DefaultInput
              key={"6"}
              label="Nama Lengkap"
              value = {`${firstname} ${lastname}`}
              type="text"
            />
            <DefaultInput
              key={"7"}
              label="Email *"
              name="email"
              type="email"
              value = {email}
              onChange = {(e) => setEmail(e.target.value)}
            />
            <DefaultInput
              key={"8"}
              name="min_length_ktp"
              type="number"
              label="KTP Number *"
              max={16}
              value = {ktpNumber}
              onChange={(e) => {
                if (!verifyLength(e.target.value, 17)) {
                  ktpNumberOnChange(e.target.value)
                if (!verifyLength(e.target.value, 16)) {
                  setminLengthState("has-danger");
                } else {
                  setminLengthState("has-success");
                }
                }
              }}
            />
             {minLengthState === "has-danger" ? (
               <label className="error">
                 KTP harus 16 Karakter.
               </label>
             ) : null}
            <DefaultInput
              key={"9"}
              name="min_length_ktp"
              type="number"
              label="NPWP"
              max={16}
              value = {npwp}
              onChange={(e) => {
                if (!verifyLength(e.target.value, 16)) {
                  npwpOnChange(e.target.value)
                  if (!verifyLength(e.target.value, 15)) {
                    setminLengthNpwpState("has-danger");
                  } else {
                    setminLengthNpwpState("has-success");
                  }
                }
                // setminLengthNPWP(e.target.value);
              }}
            />
            {minLengthNpwpState === "has-danger" ? (
              <label className="error">
                NPWP Harus 15 Karakter.
              </label>
            ) : null}
            <div>
            <DefaultInput
              key={"10"}
              name="min_length_phone"
              type="number"
              label="Nomor Telepon *"
              max={12}
              value={MobileNo}
              onChange={(e) => {
                if (!verifyLength(e.target.value, 13)) {
                  mobileNoOnChange(e.target.value)
                if (!verifyLength(e.target.value, 11)) {
                  setminLengthPhoneState("has-danger");
                } else {
                  setminLengthPhoneState("has-success");
                }
              }      // setminLengthPhone(e.target.value);
              }}
            />
              {minLengthPhoneState === "has-danger" ? (
                <label className="error">
                  Nomor Telepon Minimal 8 Karakter dan Maksimal 13 Karakter.
                </label>
            ) : null}
            <SelectBasic
                key={"11"}
                label="Lokasi Router"
                name="singleSelect"
                value = {router}
                option={routerSelectOption}
                onChange={ async (e) => {
                    setRouter(e)
                    await GetpppoeProfile(e.value)
                    await CityLoad(e?.province)
                  }
                }
              />
              {!router || !router.status ? (
                <label className="error">
                  Router Offline
                </label>
              ) : <label className="success">
                  Router Online
                </label>}
            </div>
            <SelectBasic
              key={"15"}
              label = {'pppoe Profile'}
              value = {pppoeProfileId}
              onChange = {(e) => {
                setPppoeProfileId(e)
              }}
              option = {pppoeProfileOption}
            />
            <SelectBasic
              key={"12"}
              label="Kota"
              name="singleSelect"
              value = {city}
              option={cityOption}
              onChange={async (e) => {
                subDistrictOnChange(null)
                urbanOnChange(null)
                await subDistrictLoad(e.value)
                urbanOptionOnChange([])
                cityOnChange(e)
              }}
            />
            <SelectBasic
              key={"13"}
              label="Kecamatan"
              name="singleSelect"
              value = {subDistrict}
              option={subDistrictOption}
              onChange={async (e) => {
                urbanOnChange(null)
                subDistrictOnChange(e)
                await UrbanLoad(e.value)
              }}
            />
            <SelectBasic
              key={"14"}
              label="Kelurahan"
              name="singleSelect"
              value = {urban}
              option={UrbanOption}
              onChange={(value) => urbanOnChange(value)}
            />
              <DefaultInput
                key={"16"}
                label = {'PPPOE Username'}
                value = {pppoeUsername}
                onChange = {(e) => setPppoeUsername(e.target.value)}
                type
              />
              <DefaultInput
                key={"17"}
                label = {'PPPOE Password'}
                value = {pppoePassword}
                onChange = {(e) => setPppoePassword(e.target.value)}
                type
              />
              <DefaultInput
                key={"18"}
                label = {'Presentase Potongan Harga Customer'}
                value = {discount}
                onChange = {(e) => {
                    if (e.target.value >= 0 && e.target.value <= 100) {
                      setDiscount(e.target.value)
                    }
                  }
                }
                type = {'number'}
              />
              <DefaultInput
                key={"19"}
                label = {'Title Alamat'}
                value = {addressTitle}
                onChange = {(e) => setAddressTitle(e.target.value)}
                type = {'text'}
              />
              <DefaultInput
                key={"21"}
                label = {'Isi Alamat'}
                value = {addressLine}
                onChange = {(e) => setAddressLine(e.target.value)}
                type = {'textarea'}
              />
              <DefaultInput
                key={"22"}
                label = {'Deskripsi'}
                value = {description}
                onChange = {(e) => setDescription(e.target.value)}
                type = {'textarea'}
              />
              {!mitraSuper ? null : (
                  <Checklist
                    key={"23"}
                    text = {"Aktivasi Customer"}
                    value = {enabled}
                    checked = {enabled}
                    onChange = {(e) => {
                      setEnabled(e.target.checked)
                    }}
                  />
                )
              }
              <div className="mt-6 flex flex-row gap-4">
                <PrimaryIconButton
                  title="Save"
                  onClick = {submit}
                />
              </div>
            {/* </form> */}
            </div>
          </div>
        </div>
    </>
  )

  return (
    <>
      <main className="h-full">
        <Navbar toggle={sidebarToggle} />
        <LoadingScreen
          // key = {RandomTextGenerator()}
          children={child}
          loading = {loading}
          text={"Saving..."}
        />
      </main>
    </>
  );
}

export default PppoeUserForm;
