import { getData, setData } from "../Common"

const identifier = ['hostpotUserData', 'hostpotUserList']
const [data, list] = identifier

export function addHostpotUserData(payload) {
  setData(data, payload)
}

export function getHostpotUserData() {
 return getData(data)
}

export function addHostpotUserList(payload) {
  setData(list, payload)
}

export function getHostpotUserList() {
 return getData(list)
}

export function payloadHostpotUser( mitra, hostpotProfileId, userResellerId, username, password, discount, isPaid, paidAmount, description) {
    return {
        mitra, hostpotProfileId, userResellerId, username, password, discount, isPaid, paidAmount, description
    }
}

export function payloadHostpotUserUpdate( hostpotProfileId, username, password, discount, isPaid, paidAmount, description ) {
    return {
        hostpotProfileId, username, password, discount, isPaid, paidAmount, description
    }
}

export function payloadGenerateHostpotUser (qty, prefix, autogenUsername, autogenPass, maxDigitUsername, maxdigitPass, mitra, hostpotProfileId, userResellerId, username, password, discount, isPaid, paidAmount, description) {
    return {
        qty, prefix, autogenUsername, autogenPass, maxDigitUsername, maxdigitPass, mitra, hostpotProfileId, userResellerId, username, password, discount, isPaid, paidAmount, description
    }
}

export function payloadHostpotUserTrash(trash) {
    return {
        trash
    }
}

export function payloadHostpotUserPaid(isPaid, paidAmount) {
    return {
        isPaid, paidAmount
    }
}
