import React, { useState } from "react";
import Navbar from "../../components/Navbar/Index";
import { useNavigate, useOutletContext } from "react-router-dom";
import DefaultInput from "../../components/Input/DefaultInput";
import InputCoordinate from "components/Input/InputCoordinate";
import Checklist from "components/Button/Checklist";
import { getOwner } from "data/Variable/UserVariable";
import RouterNasService from "data/Handler/RouterNasService";
import ResourceService from "data/Handler/ResourceService";
import { getUserData } from "data/Variable/UserVariable";
import { getProvince } from "data/Variable/Common";
import { verifySuperAdmin } from "data/Variable/Utils";
import { addRouterList } from "data/Variable/Common";
import { routerAdd } from "data/Variable/Common";
import swal from "sweetalert";
import { CatchError } from "data/Handler/Exceptions/exceptions";
import { addCity } from "data/Variable/Common";
import { addSubDistrict } from "data/Variable/Common";
import { addUrban } from "data/Variable/Common";
import SelectBasic from "components/Input/SelectBasic";
import PrimaryIconButton from "components/Button/PrimaryIconButton";
import LoadingScreen from "components/Loader/LoadingScreen";
import FreeRadiusDatabaseForm from "components/Other/FreeRadiusForm/FreeRadiusDatabaseForm";
import FreeRadiusCredentialForm from "components/Other/FreeRadiusForm/FreeRadiusCredentialForm";
import { verifyNetworkPortRange } from "data/Variable/Utils";
import { GlobalHeader } from "components/Datatables/GlobalHeader";

function RouterForm(props) {
  const [sidebarToggle] = useOutletContext();
  const routerService = new RouterNasService()
  const resourceService = new ResourceService()
  var ownerData = getOwner()
  var selectProvinceOption = getProvince()
  const userData = getUserData()
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [merk, setMerk] = useState('')
  const [type, setType] = useState('')
  const [sn, setSn] = useState('')
  const [description, setDescription] = useState('')
  const [province, provinceOnChange] = useState('')
  const [city , cityOnChange] = useState('')
  const [subDistrict , subDistrictOnChange] = useState('')
  const [urban , urbanOnChange] = useState('')
  const [cityOption, cityOptionOnChange] = useState([])
  const [subDistrictOption, subDistrictOptionOnChange] = useState([])
  const [UrbanOption, urbanOptionOnChange] = useState([])
  const [ipaddress, ipaddressOnChange] = useState('')
  const [port, portOnChange] = useState(8728)
  const [owner, ownerOnChange] = useState(ownerData.find(data => data.value === userData.organization))
  const [passwordValue, passwordValueOnChange] = useState('')
  const [passwordShow, setPasswordShow] = useState(false);
  const [loading, loadingOnChange] = useState(false)
  const { userSuper } = verifySuperAdmin()
  const [latitude, setLatitude] = useState(0.0)
  const [longitude, setLongitude] = useState(0.0)
  const [mysqlHost, setMysqlHost] = useState('')
  const [mysqlPort, setMysqlPort] = useState(3306)
  const [mysqlDatabase, setMysqlDatabase] = useState('')
  const [mysqlUsername, setMysqlUsername] = useState('')
  const [mysqlPassword, setMysqlPassword] = useState('')
  const [radiusServerSecret, setRadiusServerSecret] = useState('')
  const [radiusServerAuthPort, setRadiusServerAuthPort] = useState(8712)
  const [radiusServerAccountingPort, setRadiusServerAccountingPort] = useState(8713)
  const [passwordDbShow, setPasswordDbShow] = useState(false)
  const [passwordRadiusShow, setPasswordRadiusShow] = useState(false)
  const [dynamicIp, setDynamicIp] = useState(false)
  // useEffect(() => {
  //   getRouter()
  // }, [])

  const getRouter =  async() => {
    const len =  'ALL'
    const result =  await routerService.getAllRouter(len)
    addRouterList(result.data)
  }

  const togglePassword = () => {
      // When the handler is invoked
      // inverse the boolean state of passwordShow
      setPasswordShow(!passwordShow);
  };

  const addRouterData = async() => {
      try {
          loadingOnChange(true)
          const payload = routerAdd(name,
            ipaddress,
            passwordValue,
            port,
            province.label,
            city.label,
            subDistrict.label,
            urban.label,
            merk,
            type,
            sn,
            description,
            owner.value,
            latitude,
            longitude,
            mysqlHost,
            mysqlDatabase,
            mysqlPort,
            mysqlUsername,
            mysqlPassword,
            radiusServerSecret,
            radiusServerAuthPort,
            radiusServerAccountingPort)
          const result = await routerService.addRouter(payload)
          await getRouter()
          loadingOnChange(false)
          await swal(result.status, result.message, "success")
          navigate('/router')
        } catch (error) {
          loadingOnChange(false)
          return await CatchError(error, props)
        }
      }
      //const payload = customer('Servia1', 'Servia11','main', 'XX.YY.dd','082122782522', 'Individual', 'Kepulauan Bangka Belitung', true, true, true, true, '083239923823', 'rebu sungailiat bangka', 'en', false, 1.0, ['Finacial'], ['10', '20'], ['team1'], 'main')}
    const CityLoad = async(city) => {
      const dat = await resourceService.getCity(city.value)
      const result = dat.data
      // console.log(result)
      addCity(result)
      return result
    }

    const subDistrictLoad = async(subDistrict) => {
      const dat = await resourceService.getSubDistrict(subDistrict.value)
      const result = dat.data
      // console.log(result)
      addSubDistrict(result)
      return result
    }

    const UrbanLoad = async(urban) => {
      // sessionStorage.removeItem('urban')
      const dat = await resourceService.getUrban(urban.value)
      const result = dat.data
      // console.log(result)
      addUrban(result)
      return result
    }

  const child = (
    <>
      {/* Main Content */}
        <div className="mainCard">
          <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
            {/* <form> */}
              <GlobalHeader
                header = {'Tambah Router NAS'}
              />
                 <InputCoordinate
                    latitude = {latitude}
                    setLatitude = {setLatitude}
                    longitude = {longitude}
                    setLongitude = {setLongitude}
                  />
                  <DefaultInput
                    value = {name}
                    onChange={(e) => setName(e.target.value)}
                    label="Username"
                    type="text"
                  />
                  <SelectBasic
                    // className="react-select"
                    // classNamePrefix="react-select"
                    label="Mitra"
                    name="singleSelect"
                    value = {owner}
                    option = {ownerData}
                    isDisabled = {!userSuper}
                    onChange={(value) => ownerOnChange(value)}
                  />
                  <DefaultInput
                    label="IP Address"
                    value = {ipaddress}
                    disabled = {dynamicIp}
                    onChange = {(e) => {
                      if (e.target.value.length <= 15) {
                        ipaddressOnChange(e.target.value)
                      }
                    }}
                    type="text"
                  />
                  <Checklist
                    text = 'Gunakan IP Dinamis'
                    value={dynamicIp}
                    onChange={(e) => {
                      setDynamicIp(e.target.checked)
                      ipaddressOnChange('0.0.0.0/0')
                    }}
                  />
                  <DefaultInput
                    label="Mikrotik API Port"
                    value = {port}
                    onChange = {(e) => {
                      if (e.target.value.length <= 4) {
                        portOnChange(e.target.value)
                      }
                    }}
                    type="number"
                  />
                  <DefaultInput
                    label="Mikrotik API Password"
                    value = {passwordValue}
                    onChange = { (e) => {
                      if (e.target.value.length <= 40) {
                        passwordValueOnChange(e.target.value)
                      }
                    }
                    }
                    type={passwordShow ? "text" : "password"}
                  />
                  <Checklist
                    text = 'Show Password'
                    value={passwordShow}
                    onChange={(e) => togglePassword(e.target.checked)}
                  />
                  <FreeRadiusCredentialForm
                    header = {'FreeRadius Server Credential'}
                    radiusServerSecretLabel = {'FreeRadius Secret'}
                    radiusServerAuthPortLabel = {'FreeRadius Authentication Port'}
                    radiusServerAccountingPortLabel = {'FreeRadius Accounting Port'}
                    radiusServerSecret = {radiusServerSecret}
                    setRadiusServerSecret = {(e) => setRadiusServerSecret(e.target.value)}
                    radiusServerAuthPort = {radiusServerAuthPort}
                    setRadiusServerAuthPort = {(e) => {
                      if (verifyNetworkPortRange(e)) {
                        setRadiusServerAuthPort(e.target.value)
                      }
                    }}
                    radiusServerAccountingPort = {radiusServerAccountingPort}
                    setRadiusServerAccountingPort = {(e) => {
                      if (verifyNetworkPortRange(e)) {
                        setRadiusServerAccountingPort(e.target.value)
                      }
                    }}
                    passwordShow = {passwordRadiusShow}
                    setPasswordShow = {setPasswordRadiusShow}
                  />
                  <SelectBasic
                    label="Provinsi"
                    name="singleSelect"
                    value = {province}
                    option = {selectProvinceOption}
                    onChange={async(value) => {
                      // sessionStorage.removeItem('city')
                      cityOnChange(null)
                      subDistrictOnChange(null)
                      urbanOnChange(null)
                      provinceOnChange(value)
                      const data = await CityLoad(value)
                      cityOptionOnChange(data)
                      subDistrictOptionOnChange([])
                      urbanOptionOnChange([])
                    }
                      }
                    />
                    <div>
                    <SelectBasic
                      label="Kota"
                      name="singleSelect"
                      value = {city}
                      option={cityOption}
                      onChange={async (value) => {
                        // sessionStorage.removeItem('subDistrict')
                        subDistrictOnChange(null)
                        urbanOnChange(null)
                        const data = await subDistrictLoad(value)
                        subDistrictOptionOnChange(data)
                        urbanOptionOnChange([])
                        cityOnChange(value)
                      }}
                    />
                    {!city || !city.status ? (
                      <label className="error">
                        Phone Code Not Available, Set Default code (000)
                      </label>
                    ) : <label className="success">
                        Phone Code Available
                      </label>}
                    </div>
                    <div>
                    <SelectBasic
                      label="Kecamatan"
                      name="singleSelect"
                      value = {subDistrict}
                      option={subDistrictOption}
                      onChange={async (value) => {
                        // sessionStorage.removeItem('urban')
                        urbanOnChange(null)
                        subDistrictOnChange(value)
                        const data = await UrbanLoad(value)
                        urbanOptionOnChange(data)
                      }}
                    />
                    {!subDistrict || !subDistrict.status ? (
                      <label className="error">
                        Phone Code Not Available, Set Default code (000)
                      </label>
                    ) : <label className="success">
                        Phone Code Available
                      </label>}
                    </div>
                    <SelectBasic
                      label="Kelurahan"
                      name="singleSelect"
                      value = {urban}
                      option={UrbanOption}
                      onChange={(value) => urbanOnChange(value)}
                    />
                    <FreeRadiusDatabaseForm
                      header = {'Router Radius Mysql Connection'}
                      mysqlHostLabel = {'Radius MSQL Database Host'}
                      mysqlPortLabel = {'Radius MSQL Database Port'}
                      mysqlDatabaseNameLabel = {'Radius MSQL Database Name'}
                      mysqlDatabaseUsernameLabel = {'Radius MSQL Database Username'}
                      mysqlDatabasePasswordLabel = {'Radius MSQL Database Password'}
                      mysqlHost = {mysqlHost}
                      setMysqlHost = {(e) => {
                        setMysqlHost(e.target.value)
                      }}
                      mysqlPort = {mysqlPort}
                      setMysqlPort = {(e) => {
                        if (verifyNetworkPortRange(e)) {
                          setMysqlPort(e.target.value)
                        }
                      }}
                      mysqlDatabase = {mysqlDatabase}
                      setMysqlDatabase = {(e) => {
                        setMysqlDatabase(e.target.value)
                      }}
                      mysqlUsername = {mysqlUsername}
                      setMysqlUsername = {(e) => {
                        setMysqlUsername(e.target.value)
                      }}
                      mysqlPassword = {mysqlPassword}
                      setMysqlPassword = {(e) => {
                        setMysqlPassword(e.target.value)
                      }}
                      passwordShow = {passwordDbShow}
                      setPasswordShow = {setPasswordDbShow}
                      bgColor = {'bg-amber-200'}
                    />
                    <DefaultInput
                      value={merk}
                      onChange={(e) => setMerk(e.target.value)}
                      label="Merk"
                      type="text"
                    />
                    <DefaultInput
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                      label="Type"
                      type="text"
                    />
                    <DefaultInput
                      value={sn}
                      onChange={(e) => setSn(e.target.value)}
                      label="Serial Number"
                      type="text"
                    />
                    <DefaultInput
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      label="Deskripsi"
                      type="text"
                    />
              <div className="mt-6 flex flex-row gap-4">
                <PrimaryIconButton
                  title="Save"
                  onClick = {addRouterData}
                />
              </div>
            {/* </form> */}
          </div>
        </div>
    </>
  )
  return (
    <>
      <main className="h-full">
        <Navbar toggle={sidebarToggle} />
        <LoadingScreen
          // key = {RandomTextGenerator()}
          children={child}
          loading = {loading}
          text={"Saving..."}
        />
      </main>
    </>
  );
}

export default RouterForm;
