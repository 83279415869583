/* eslint-disable react-hooks/exhaustive-deps */
import React,{ useEffect, useState, useRef } from "react";
import Datatables from "../Datatables/Table";
import TableCell from "../Datatables/TableCell";
import LoadingScreen from "components/Loader/LoadingScreen";
import cloneDeep from "lodash/cloneDeep";
import throttle from "lodash/throttle";
import Pagination from "rc-pagination";
import { RandomTextGenerator } from "data/Variable/Utils";

function MainTable({ loading, dataHeader, dataState, menu, header, countPerPage = 10 }) {
  // console.log({
  //   loading, dataHeader, dataState, menu, header, countPerPage
  // })
  const idLen = 10
  const [value, setValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [collection, setCollection] = useState(
    cloneDeep(dataState.slice(0, countPerPage))
  );

  const searchData = useRef(
    throttle(val => {
      const query = val.toLowerCase();
      setCurrentPage(1);
      const data = cloneDeep(
        dataState
          .filter(item => item.name.toLowerCase().indexOf(query) > -1)
          .slice(0, countPerPage)
      );
      setCollection(data);
    }, 400)
  );

  useEffect(() => {
    if (!value) {
      updatePage(1);
    } else {
      searchData.current(value);
    }
  }, [value]);

  const updatePage = p => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setCollection(cloneDeep(dataState.slice(from, to)));
  };

  const rowRenderer = (dataHeader, row, index) => dataHeader?.map((head) => {
          if (head?.key === 'roles') {
            return (
              <TableCell key={RandomTextGenerator(idLen)} dataLabel={head?.label} showLabel={true}>
                <span className=" space-x-1">
                  {row?.roles?.map((role, index) => (
                    <span
                      key={`header-${index}`}
                      className="text-left rounded-full py-1 px-3 text-sm font-semibold bg-emerald-200 text-green-900"
                    >
                      {role?.name}
                    </span>
                  ))}
                </span>
              </TableCell>
            )
          }
          if (head?.key === 'actions') {
            // console.log('Action')
            return (
              <TableCell key={RandomTextGenerator(idLen)} dataLabel={head?.label}>
                {row?.actions || <p>No Element</p>}
              </TableCell>
            )
          }

          return (
            <TableCell key={RandomTextGenerator(idLen)} dataLabel={head?.label} showLabel={true}>
              <span className="text-left font-medium text-sm text-gray-900">
                {row[head?.key] || 'No Data'}
              </span>
            </TableCell>
          )
        })

  const tableData = collection?.map((row, index) => (
      <tr
          key={index}
          className="text-left bg-white border md:border-b block md:table-row rounded-md shadow-md md:rounded-none md:shadow-none mb-5"
        >
        {rowRenderer(dataHeader, row, index)}
      </tr>
  ));

  const child = (
    <>
      <Datatables loading={loading} dataHeader={dataHeader} header={header} menu={menu} searchValue = {value} searchOnChange = {setValue}>
        {tableData}
      </Datatables>
      <Pagination
        key={RandomTextGenerator(idLen)}
        pageSize={countPerPage}
        onChange={updatePage}
        current={currentPage}
        total={dataState.length}
      />
    </>
  );

  return (
    <LoadingScreen
      // key={RandomTextGenerator(idLen)}
      text = {'Load Data'}
      loading = {loading}
      children = {child}
    />
  )
}

export default MainTable;
