  export const ColumnsUser = [
    {
        label: "Nama",
        key: "name",
    },
    {
        label: "Mitra",
        key: "mitra",
    },
    {
        label: "Router",
        key: "router",
    },
    {
        label: "Harga Beli",
        key: "buyingPrice"
    },
    {
        label: "Harga Jual",
        key: "sellingPrice"
    },
    {
        label: "Rate Up",
        key: "rateUp"
    },
    {
        label: "Rate Down",
        key: "rateDown"
    },
    {
        label: "View",
        key: "actionsUser",
        sortable: false,
        filterable: false,
    }
]

export const ColumnsAdmin = [
    {
        label: "Nama",
        key: "name",
    },
    {
        label: "Mitra",
        key: "mitra",
    },
    {
        label: "Router",
        key: "router",
    },
    {
        label: "Harga Beli",
        key: "buyingPrice"
    },
    {
        label: "Harga Jual",
        key: "sellingPrice"
    },
    {
        label: "Rate Up",
        key: "rateUp"
    },
    {
        label: "Rate Down",
        key: "rateDown"
    },
    {
        label: "Action",
        key: "actions",
        sortable: false,
        filterable: false,
    },
]

export const ColumnsTrashAdmin = [
    {
        label: "Nama",
        key: "name",
    },
    {
        label: "Mitra",
        key: "mitra",
    },
    {
        label: "Router",
        key: "router",
    },
    {
        label: "Harga Beli",
        key: "buyingPrice"
    },
    {
        label: "Harga Jual",
        key: "sellingPrice"
    },
    {
        label: "Rate Up",
        key: "rateUp"
    },
    {
        label: "Rate Down",
        key: "rateDown"
    },
    {
        label: "Recover",
        key: "recover",
        sortable: false,
        filterable: false,
    },
    {
        label: "Hapus Permanen",
        key: "actionsTrash",
        sortable: false,
        filterable: false,
    },
]

export const ColumnsTrashUser = [
    {
        label: "Nama",
        key: "name",
    },
    {
        label: "Mitra",
        key: "mitra",
    },
    {
        label: "Router",
        key: "router",
    },
    {
        label: "Harga Beli",
        key: "buyingPrice"
    },
    {
        label: "Harga Jual",
        key: "sellingPrice"
    },
    {
        label: "Rate Up",
        key: "rateUp"
    },
    {
        label: "Rate Down",
        key: "rateDown"
    },
]