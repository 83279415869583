import React, { useEffect, useState } from "react";
import Navbar from "./../../../components/Navbar/Index";
import { useNavigate, useOutletContext } from "react-router-dom";
import DefaultInput from "../../../components/Input/DefaultInput";
import SelecttWithUserTier from "../../../components/Input/SelectWithUserTier";
import HostpotProfileService from "data/Handler/Pppoe/PppoeProfileService";
import RouterNasService from "data/Handler/RouterNasService";
import { getUserData } from "data/Variable/UserVariable";
import { getOwner } from "data/Variable/UserVariable";
import { payloadPppoeProfile } from "data/Variable/Pppoe/PppoeProfile";
import { gethostpotProfileInternetMeasurementData } from "data/Variable/HostpotVoucher/HostpotProfile";
import { getRouterSelect, PriceFormat } from "data/Variable/Common";
import SelectBasic from "components/Input/SelectBasic";
import Checklist from "components/Button/Checklist";
import swal from "sweetalert";
import { CatchError } from "data/Handler/Exceptions/exceptions";
import PrimaryIconButton from "components/Button/PrimaryIconButton";
import LoadingScreen from "components/Loader/LoadingScreen";
import { GlobalHeader } from "components/Datatables/GlobalHeader";

function PppoeProfileForm(props) {
  const [sidebarToggle] = useOutletContext();
  const navigate = useNavigate()
  const hostpotProfileService =  new HostpotProfileService()
  const routerService = new RouterNasService()
  const userData = getUserData()
  const ownerData = getOwner()
  const internetMeasurement = gethostpotProfileInternetMeasurementData()
  const [routerSelect, setRouterSelect] = useState(getRouterSelect())
  const [poolSelect, setPoolSelect] = useState([])
  const [mitra, mitraOnChange] = useState(ownerData.find(own => own.value === userData.organization))
  const [loading, loadingOnChange ] = useState(false)
  const [changeMitra, changeMitraOnChange] = useState(false)
  const [name, setName] = useState('')
  const [price, setPrice] = useState(0)
  const [discount, setDiscount] = useState(0)
  const [pool, setPool] = useState('')
  const [ip, setIp] = useState('0.0.0.0')
  const [subnet, setSubnet] = useState(24)
  const [rateUp, setRateUp] = useState(0)
  const [rateDown, setRateDown] = useState(0)
  const [rateUpMeasurement, setRateUpMeasurement] = useState(internetMeasurement[1])
  const [rateDownMeasurement, setRateDownMeasurement] = useState(internetMeasurement[1])
  const [description, setDescription ] = useState('')
  const [router, setRouter] = useState('')
  const [useProfilePPPlist, setUseProfilePPPlist] = useState(false)
  const [RouterpppProfile, SetRouterpppProfile] = useState({})

  const getRouterPPP =  async(routerId) => {
    const result =  await routerService.getpppProfileSelect(routerId)
    setPoolSelect(result.data)
    return result.data
  }

  const getRouter = async(mitraid) => {
    const dat = await routerService.getRouterSelect(mitraid)
    const result = dat.data
    setRouterSelect(result)
    return result
  }

  useEffect(() => {
    getRouter(mitra?.mitraid)
  }, [])

  const submit = async(e) => {
    e.preventDefault()
    try {
      loadingOnChange(true)
      const payload = payloadPppoeProfile(name,
      mitra.mitraId,
      router.value,
      price,
      discount,
      rateDown,
      rateUp,
      pool,
      ip,
      subnet,
      rateDownMeasurement.value,
      rateUpMeasurement.value,
      description)
      const result = await hostpotProfileService.post(payload)
      await swal(result.status, result.message, "success")
      loadingOnChange(false)
      return navigate('/pppoe/profile')
    } catch (error) {
      loadingOnChange(false)
      return await CatchError(error, props)
  }}

  const child = (
    <>
              {/* Main Content */}
        <div className="mainCard">
          <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
            <form>
              <GlobalHeader
                header = {'Tambah PPPOE Profile'}
              />
                  <SelecttWithUserTier
                    key={"1"}
                    label = {'Mitra'}
                    value = {mitra}
                    onChange = {async (e) => {
                      await getRouter(e.mitraid)
                      mitraOnChange(e)
                    }}
                    disabled = {changeMitra}
                    options = {ownerData}
                    checkText = {'Change Mitra'}
                    checkValue = {changeMitra}
                    checkValueOnChange = {changeMitraOnChange}
                  />
                  <DefaultInput
                    key={"2"}
                    label = {'nama'}
                    value = {name}
                    onChange = {(e) => setName(e.target.value)}
                    type
                  />
                    <div>
                      <SelectBasic
                        label="Lokasi Router"
                        name="singleSelect"
                        value = {router}
                        option={routerSelect}
                        onChange={async (e) => {
                          setRouter(e)
                          await getRouterPPP(e.value)
                        }}
                      />
                      {!router.status ? (
                          <label className="error">
                            Router Offline
                          </label>
                        ) : <label className="success">
                            Router Online
                      </label>}
                  </div>
                  <DefaultInput
                    key={"3"}
                    label = {'Harga'}
                    value = {price}
                    onChange = {(e) => {
                      if (e.target.value >= 0) {
                        setPrice(e.target.value)
                      }
                    }}
                    type = {'number'}
                  />
                  <DefaultInput
                    key={"4"}
                    label = {'Price Display'}
                    value = {PriceFormat((price - discount), 'idr')}
                    disabled
                    onChange = {() => {}}
                  />
                  <DefaultInput
                    key={"3"}
                    label = {'Diskon'}
                    value = {discount}
                    onChange = {(e) => {
                      if (e.target.value >= 0 && e.target.value <= price) {
                        setDiscount(e.target.value)
                      }
                    }}
                    type = {'number'}
                  />
                  {!useProfilePPPlist ? (
                      <>
                         <DefaultInput
                           key={"8"}
                           label = {'Nama Pool'}
                           value = {pool}
                           onChange = {(e) => setPool(e.target.value)}
                         />
                      </>
                    ) : (
                      <>
                      <SelectBasic
                        key={"9"}
                        label={" Select PPP From Router"}
                        value={RouterpppProfile}
                        option={poolSelect}
                        onChange={(e) => {
                          SetRouterpppProfile(e)
                          setPool(e.value)
                          setIp(e.ip)
                          setSubnet(Number(e.subnet))
                          }} />
                      </>
                    )
                  }
                  <DefaultInput
                    key={"8"}
                    label = {'IP Address'}
                    value = {ip}
                    onChange = {(e) => setIp(e.target.value)}
                  />
                  <DefaultInput
                    key={"9"}
                    label = {'Subnet'}
                    value = {subnet}
                    onChange = {(e) => {
                        if (e.target.value >= 0 && e.target.value <= 32) {
                          setSubnet(e.target.value)
                        }
                      }
                    }
                    type = {'number'}
                  />
                  <Checklist
                    key={"9"}
                    text = {"Memakai Pool Router"}
                    value = {useProfilePPPlist}
                    onChange = { async (e) => {
                      setUseProfilePPPlist(e.target.checked)
                      setPool('')
                      setIp('0.0.0.0')
                      setSubnet(24)
                      if (!e.target.checked) {
                        SetRouterpppProfile([])
                        return e
                      }
                      await getRouterPPP(router.value)
                    }}
                  />
                  <DefaultInput
                    key={"10"}
                    label = {'Kecepatan Upload'}
                    value = {rateUp}
                    onChange = {(e) => {
                      if (e.target.value >= 0) {
                        setRateUp(e.target.value)
                      }
                    }
                    }
                    type = {'number'}
                  />
                  <SelectBasic
                    key={"11"}
                    label = {'Upload Measurement'}
                    value = {rateUpMeasurement}
                    onChange = {setRateUpMeasurement}
                    option = {internetMeasurement}
                  />
                  <DefaultInput
                    key={"12"}
                    label = {'Kecepatan Download'}
                    value = {rateDown}
                    onChange = {(e) => {
                      if (e.target.value >= 0) {
                        setRateDown(e.target.value)
                      }
                    }}
                    type = {'number'}
                  />
                  <SelectBasic
                    key={"13"}
                    label = {'Download Measurement'}
                    value = {rateDownMeasurement}
                    onChange = {setRateDownMeasurement}
                    option = {internetMeasurement}
                  />
                  <DefaultInput
                    key={"21"}
                    label = {'Deskripsi'}
                    value = {description}
                    onChange = {(e) => setDescription(e.target.value)}
                    type = {'textarea'}
                  />
              <div className="mt-6 flex flex-row gap-4">
                <PrimaryIconButton
                  title="Save"
                  onClick = {submit}
                />
              </div>
            </form>
          </div>
        </div>
    </>
  )
  return (
    <>
      <main className="h-full">
        <Navbar toggle={sidebarToggle} />
        <LoadingScreen
          // key = {RandomTextGenerator()}
          children={child}
          loading = {loading}
          text={"Saving..."}
        />
      </main>
    </>
  );
}

export default PppoeProfileForm;
