export const ColumnsUser = [
    {
        label: "Name",
        key: "name",
    },
    {
        label: "Mitra",
        key: "mitra",
    },
    {
        label: "Router",
        key: "router",
    },
    {
        label: "PPPOE Username",
        key: "pppoeUsername"
    },
    {
        label: "PPPOE Password",
        key: "pppoePassword"
    },
    {
        label: "Status",
        key: "setPaidUser"
    },
    {
        label: "View",
        key: "actionsUser",
        sortable: false,
        filterable: false,
    }
]

export const ColumnsAdmin = [
    {
        label: "Name",
        key: "name",
    },
    {
        label: "Mitra",
        key: "mitra",
    },
    {
        label: "Router",
        key: "router",
    },
    {
        label: "PPPOE Username",
        key: "pppoeUsername"
    },
    {
        label: "PPPOE Password",
        key: "pppoePassword"
    },
    {
        label: "Status",
        key: "setPaidAdmin"
    },
    {
        label: "Action",
        key: "actions",
        sortable: false,
        filterable: false,
    },
]

export const ColumnsTrashAdmin = [
    {
        label: "Name",
        key: "name",
    },
    {
        label: "Mitra",
        key: "mitra",
    },
    {
        label: "Router",
        key: "router",
    },
    {
        label: "PPPOE Username",
        key: "pppoeUsername"
    },
    {
        label: "PPPOE Password",
        key: "pppoePassword"
    },
    {
        label: "Recover",
        key: "recover",
        sortable: false,
        filterable: false,
    },
    {
        label: "Hapus Permanen",
        key: "actionsTrash",
        sortable: false,
        filterable: false,
    },
]

export const ColumnsTrashUser = [
    {
        label: "Name",
        key: "name",
    },
    {
        label: "Mitra",
        key: "mitra",
    },
    {
        label: "Router",
        key: "router",
    },
    {
        label: "PPPOE Username",
        key: "pppoeUsername"
    },
    {
        label: "PPPOE Password",
        key: "pppoePassword"
    },
    {
        label: "Status",
        key: "approvalUser",
    },
    {
        label: "File",
        key: "filename",
    },
]