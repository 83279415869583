import { getData, setData } from "./Common";

const identifier = ['user', 'userData', 'userselect', 'userpermission', 'owner', 'userMitra', 'userlist', 'usertype', 'role', 'rolelist', 'roleManagerlist', 'userManagerData', 'mitra', 'mitraList']
const [ users, userData, userselect, userpermission, owner, userMitra, userlist, usertype, role, rolelist, roleManagerlist, userManagerData, mitra, mitraList ] = identifier

export const getUser = () => {
  const userStr = sessionStorage.getItem(users);
  if (!userStr) return null;
  return JSON.parse(userStr);
}

export const getUserData = () => {
  const data = sessionStorage.getItem(userData)
  if (!data) {
    return []
  }
    return JSON.parse(data)
}

export const addUserData = (payload) => {
  setData(userData, payload)
}

export const addUserSelect = (payload) => {
  setData(userselect, payload)
}
export const addUserPermission = (payload) => {
  setData(userpermission, payload)
}

export const getUserPermission = () => {
  return getData(userpermission)
}

export const getOwner = () => {
  return getData(owner)
}
export const addOwner = (payload) => {
  setData(owner, payload)
}
export const addMitra = (payload) => {
  setData(userMitra, payload)
}

export const getMitra = () => {
  return getData(userMitra)
}
export const addUserList = (payload) => {
  setData(userlist, payload)
}

export const getUserList = () => {
  return getData(userlist)
}

export const addUserType = (payload) => {
  setData(usertype, payload)
}

export const getUserType = () => {
  return getData(usertype)
}

export const addRole = (payload) => {
  setData(role, payload)
}

export const getRole = () => {
  return getData(role)
}

export const addRoleList = (payload) => {
  setData(rolelist, payload)
}

export const getRoleList = () => {
  return getData(rolelist)
}

export const addRoleManagerList = (payload) => {
  setData(roleManagerlist, payload)
}

export const getRoleManagerList = () => {
  return getData(roleManagerlist)
}

export const addUserManagerData = (payload) => {
  setData(userManagerData, payload)
}

export const getUserManagerData = () => {
  return getData(userManagerData)
}

export const addMitraData = (payload) => {
  setData(mitra, payload)
}
export const getMitraData = () => {
  const data = sessionStorage.getItem(mitra)
  return JSON.parse(data)
}

export const addMitraList = (payload) => {
  setData(mitraList, payload)
}

export const getMitraList = () => {
  return getData(mitraList)
}

export const permission = (
  username, role, create, read, update, deleted
  ) => ({
    userName: username,
    role: role,
    create: create,
    read: read,
    update: update,
    deleted: deleted
})

export const putPermission = (
  create, read, update, deleted
  ) => ({
    create: create,
    read: read,
    update: update,
    deleted: deleted
})

export const user = (username,
    email, password,
    firstname, lastname, enable, userType,
    organization
  ) => ({
    username: username,
    email: email,
    password: password,
    firstname: firstname,
    lastname: lastname,
    enabled: enable,
    userType: userType,
    organization: organization
})

export const Mitra = (name, email, firstname, lastname, mobileNo, ktp, npwp, enabled, type, dueDay, ppn, beforeDue, administrationFee, province, latitude, longitude, instalationFee, memberFee,   radiusDbUrl, radiusDbDatabase, radiusDbPort, radiusDbUsername, radiusDbPassword) =>({
  Name: name,
  Email: email,
  Firstname: firstname,
  Lastname: lastname,
  mobileNo: mobileNo,
  ktp: ktp,
  npwp: npwp,
  enabled: enabled,
  type: type,
  dueDay,
  ppn,
  beforeDue,
  administrationFee,
  province,
  latitude,
  longitude,
  instalationFee,
  memberFee,
  radiusDbUrl,
  radiusDbDatabase,
  radiusDbPort,
  radiusDbUsername,
  radiusDbPassword
})

export const MitraPut = (name, email, firstname, lastname, mobileNo, ktp, npwp, enabled, namingSeries, generateInvoice, generateTime, type, dueDay, ppn, beforeDue, administrationFee, maxMergeDate, maxMergePrice, latitude, longitude, instalationFee, memberFee, syncVa, syncHostpot, radiusDbUrl, radiusDbDatabase, radiusDbPort, radiusDbUsername, radiusDbPassword) =>({
  Name: name,
  Email: email,
  Firstname: firstname,
  Lastname: lastname,
  mobileNo: mobileNo,
  ktp: ktp,
  npwp: npwp,
  enabled: enabled,
  namingSeries: namingSeries,
  generateInvoice: generateInvoice,
  generateTime: generateTime,
  type: type,
  dueDay,
  ppn,
  beforeDue,
  administrationFee,
  maxMergeDate,
  maxMergePrice,
  latitude,
  longitude,
  instalationFee,
  memberFee,
  syncVa,
  syncHostpot,
  radiusDbUrl,
  radiusDbDatabase,
  radiusDbPort,
  radiusDbUsername,
  radiusDbPassword
})

export const MitraGenerate = (generateInvoice, generateTime, dueDay, ppn, beforeDue) =>({
  generateInvoice: generateInvoice,
  generateTime: generateTime, dueDay, ppn, beforeDue
})