import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Index";
import { useNavigate, useOutletContext } from "react-router-dom";
import MainTable from "../../components/Table/MainTable";
import PrimaryIconButton from "components/Button/PrimaryIconButton";
import { ResellerHeader } from "./ResellerHeader";
import { RandomTextGenerator } from "data/Variable/Utils";
import { getUserPermission } from "data/Variable/UserVariable";
import UserService from "data/Handler/UserService";
import { addMitraData } from "data/Variable/UserVariable";
import { CatchError } from "./../../data/Handler/Exceptions/exceptions";
import { customerOperation } from "data/Variable/Utils";
import swal from "sweetalert";
import { PriceFormat } from "data/Variable/Common";
import { EditButton } from "components/Button/EditButton";
import { DeleteButton } from "components/Button/DeleteButton";
import PrimaryButton from "components/Button/PrimaryButton";

function ResellerList(props) {
  const [sidebarToggle] = useOutletContext();
  const navigate = useNavigate()
  const pages = 'userManager'
  const currency = 'idr'
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role === pages) || ''
  const userService = new UserService()
  const [mitraLen] = useState('ALL')
  const [load, setDataLoad] = useState(true)
  const [Table, setDataTable] = useState([])

  const getMitra =  async() => {
    try {
      setDataLoad(true)
      const len = mitraLen.value
      const dat =  await userService.getAllMitra(len)
      const result = dat.data

      setDataState(mapping(result))
      setDataLoad(false)
      setDataTable(result)
      return result
    } catch (error) {
      return await CatchError(error, props)
    }
  }

  useEffect( () => {
    getMitra()
  }, [])

  const viewMitra = async(name)=> {
    try {
      const dat = await userService.getMitraData(name)
      const result = dat.data
      // console.log('customer', resultCustomer)
      addMitraData(result)
      navigate('/reseller/detail', { state: result })
    } catch (error) {
      return await CatchError(error, props)
    }
  }

  const setMitraStatus = async(id, Status) => {
    try {
      const payload = setMitraStatus(!Status)
      var operation = customerOperation(!Status)
      const dat = await userService.setMitraStatus(id, payload)
      await swal(operation, dat.message, dat.status)
      const result = dat.data
      const table = await getMitra()
      setDataState(mapping(table))
      return result
    } catch (error) {
      await swal('failed', 'Set Mitra status failed', 'warning')
    }
  }

  const deleteMitra = (name) => swal("Delete", "Data Tidak Dapat di Recover", "info", {
      buttons: {
        cancel: 'Cancel',
        delete: {text: "Delete", value: 'del' }
      }
    }
    ).then(async(value) => {
      switch (value) {
        case 'del':
          try {
            setDataLoad(true)
            const result = await userService.deleteMitra(name)
            await swal(result.status, result.message, 'success')
            const state = await getMitra()
            const stateRaw = mapping(state)
            const table = stateRaw.filter((dat) => dat.name !== name)
            setDataState(table)
            return true
          } catch (error) {
            return await CatchError(error, props)
          }

        default:
          getMitra()
          return false
      }
    })

  const mapping = (TableData) => TableData.map((Data, i) => {
        try {
          return {
            id: Data.mitraId,
            key: Data.refKey,
            name: Data.mitra_name,
            email: Data.mitraEmail,
            adminFee: PriceFormat(Data.administrationFee, currency),
            created: Data.created_by,
            modified: Data.modified_by,
            status: (
                <PrimaryButton
                  title = {Data.enabled ? 'Active' : 'Disable'}
                  status={Data.enabled}
                  disabled={Permission.Update || (TableData.length === 1)}
                  onClick={async(e) =>{
                      await setMitraStatus(Data.mitraId, Data.enabled)
                  }}
                />
            ),
            actions: (
              // we've added some custom button actions
              <div key={Data.id}>
                {/* use this button to add a edit kind of action */}
                <EditButton
                  disabled={!Permission.Read}
                  onClick={() => {
                    // let obj = dataState.find((o) => o.id === Data.mitraId);
                    viewMitra(Data.mitra_name)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.mitraId}${i}`}
                  color="warning"
                  size="sm"
                />
                <DeleteButton
                  disabled={!Permission.Delete}
                  onClick={() => {
                    deleteMitra(Data.mitra_name)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltips${Data.mitraId}${i}`}
                  color="danger"
                  size="sm"
                />
              </div>
            ),
        };
        } catch (error) {
          console.log(error)
          return []
        }
  })

  const [dataState, setDataState] = useState(mapping(Table))

  function onClickAdd() {
    return navigate('/reseller/form')
  }

  const menu = (
    <>
    <div className="flex flex-row my-3">
      <div className="flex md:flex-col mr-10">
        <PrimaryIconButton
          key={RandomTextGenerator()}
          title = {'Add'}
          onClick = {onClickAdd}
        />
      </div>
      {/* <div className="flex md:flex-col mr-10">
        <PrimaryIconButton
          key={RandomTextGenerator()}
          title = {'Export'}
        />
      </div> */}
    </div>
    </>
  )

  return (
    <>
      <main className="h-full">
        <Navbar key={RandomTextGenerator()} toggle={sidebarToggle} />
        {/* Main Content */}
        <div className="mainCard">
          <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
            <MainTable
              key={RandomTextGenerator()}
              header = {'Hostpot Reseller Manager'}
              menu={menu}
              loading={load}
              dataHeader={ResellerHeader}
              dataState={dataState}
            />
          </div>
        </div>
      </main>
    </>
  );
}

export default ResellerList;
