function Checklist ({value, checked, onChange, text, disabled, description}) {
    return (
        <>
        <div class="flex">
            <div class="flex items-center h-5">
                <input
                    id="helper-checkbox"
                    aria-describedby="helper-checkbox-text"
                    type="checkbox"
                    value={value}
                    checked = {checked}
                    onChange={onChange}
                    disabled = {disabled}
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
            </div>
            <div class="ml-2 text-sm">
                <label
                    for="helper-checkbox"
                    class="font-medium text-gray-900 dark:text-gray-800"
                >{text}</label>
                <p
                    id="helper-checkbox-text"
                    class="text-xs font-normal text-gray-500 dark:text-gray-300"
                >{description}</p>
            </div>
        </div>
        </>
    )
}

export default Checklist