import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Index";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { getProvince } from "data/Variable/Common";
// import { getOwner } from "data/Variable/UserVariable";
// import { getUserPermission } from "data/Variable/UserVariable";
import RouterNasService from "data/Handler/RouterNasService";
import ResourceService from "data/Handler/ResourceService";
// import { getUserData } from "data/Variable/UserVariable";
// import { getRefreshToken } from "data/Variable/Common";
// import { getGridPoleList } from "data/Variable/Common";
// import { getIndoorDeviceList } from "data/Variable/Common";
// import { getOutdoorDeviceList } from "data/Variable/Common";
// import { getItemRouterList } from "data/Variable/Common";
import { verifySuperAdmin } from "data/Variable/Utils";
import { addRouterData } from "data/Variable/Common";
import { addRouterList } from "data/Variable/Common";
import swal from "sweetalert";
import { router } from "data/Variable/Common";
import { CatchError } from "data/Handler/Exceptions/exceptions";
import { addCity } from "data/Variable/Common";
import { addSubDistrict } from "data/Variable/Common";
import { addUrban } from "data/Variable/Common";
import DefaultInput from "components/Input/DefaultInput";
import InputCoordinate from "components/Input/InputCoordinate";
import Checklist from "components/Button/Checklist";
// import IconInput from "components/Input/IconInput";
import SelectBasic from "components/Input/SelectBasic";
import { DateTimestr } from "data/Variable/Utils";
import PrimaryIconButton from "components/Button/PrimaryIconButton";
import LoadingScreen from "components/Loader/LoadingScreen";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import FreeRadiusCredentialForm from "components/Other/FreeRadiusForm/FreeRadiusCredentialForm";
import { verifyNetworkPortRange } from "data/Variable/Utils";
import FreeRadiusDatabaseForm from "components/Other/FreeRadiusForm/FreeRadiusDatabaseForm";
import { GlobalHeader } from "components/Datatables/GlobalHeader";

function RouterDetail(props) {
  const [sidebarToggle] = useOutletContext();
  // const pages = 'resource'
  var selectProvinceOption = getProvince()
  // var ownerData = getOwner()
  const navigate = useNavigate()
  const location = useLocation()
  const { state } = location
  // const permission = getUserPermission()
  // const Permission = permission.find(data => data.Role === pages) || ''
  const routerService = new RouterNasService()
  const resourceService = new ResourceService()
  const routerData = state
  const id = routerData.id
  const [name, setName] = useState(routerData.name)
  const [merk, setMerk] = useState(routerData.merk)
  const [type, setType] = useState(routerData.type)
  const [sn, setSn] = useState(routerData.sn)
  const [description, setDescription] = useState(routerData.description)
  const [province , provinceOnChange] = useState(selectProvinceOption.find((prov => prov.label === routerData.province_name)))
  const [city , cityOnChange] = useState({value: routerData.city, label: routerData.city})
  const [subDistrict , subDistrictOnChange] = useState({value: routerData.sub_district, label: routerData.sub_district})
  const [urban , urbanOnChange] = useState({value: routerData.urban, label: routerData.urban})
  const [cityOption, cityOptionOnChange] = useState([])
  const [subDistrictOption, subDistrictOptionOnChange] = useState([])
  const [UrbanOption, urbanOptionOnChange] = useState([])
  const [ipaddress, ipaddressOnChange] = useState(routerData.ip_address)
  const [port, portOnChange] = useState(routerData.port)
  const owner = routerData.owner // ownerData.find(data => data.value === routerData.owner)
  // const ownerFilter = ownerData.filter(data => data.value !== routerData.owner)
  const [passwordValue, passwordValueOnChange] = useState()
  const [passwordShown, setPasswordShown] = useState(false);
  const [loading, loadingOnChange] = useState(false)
  // const [loadingIndoorDevice, loadingIndoorDeviceOnChange] = useState(false)
  // const [loadingOutdoorDevice, loadingOutdoorDeviceOnChange] = useState(false)
  // const [loadingGridPole, loadingGridPoleOnChange] = useState(false)
  // const [gridPoleList, gridPoleListOnChange] = useState(getGridPoleList())
  // const [indoorDeviceList, indoorDeviceListOnChange] = useState(getIndoorDeviceList())
  // const [outdoorDeviceList, outdoorDeviceListOnChange] = useState(getOutdoorDeviceList())
  const { userSuper } = verifySuperAdmin()
  const [latitude, setLatitude] = useState(routerData.latitude)
  const [longitude, setLongitude] = useState(routerData.longitude)
  const [mysqlHost, setMysqlHost] = useState(routerData.radiusDbUrl)
  const [mysqlPort, setMysqlPort] = useState(routerData.radiusDbPort)
  const [mysqlDatabase, setMysqlDatabase] = useState(routerData.radiusDbDatabase)
  const [mysqlUsername, setMysqlUsername] = useState(routerData.radiusDbUsername)
  const [mysqlPassword, setMysqlPassword] = useState(routerData.radiusDbPassword)
  const [radiusServerSecret, setRadiusServerSecret] = useState(routerData.radiusServerSecret)
  const [radiusServerAuthPort, setRadiusServerAuthPort] = useState(routerData.radiusServerAuthPort)
  const [radiusServerAccountingPort, setRadiusServerAccountingPort] = useState(routerData.radiusServerAccountingPort)
  const [passwordDbShow, setPasswordDbShow] = useState(false)
  const [passwordRadiusShow, setPasswordRadiusShow] = useState(false)
  const [dynamicIp, setDynamicIp] = useState(routerData.ip_address === '0.0.0.0' || routerData.ip_address === '0.0.0.0/0')
  const { creation, modified, createdBy, modifiedBy } = routerData

  const getRouter =  async() => {
    const result =  await routerService.getRouter(id)
    const resultAll = await routerService.getAllRouter('ALL')
    addRouterData(result.data)
    addRouterList(resultAll.data)
  }

   const togglePassword = () => {
      setPasswordShown(!passwordShown);
  };

  const updateRouterData = async() => {
      try {
        loadingOnChange(true)
        const payload = router(
            name,
            ipaddress,
            passwordValue,
            port,
            province.label,
            city.label,
            subDistrict.label,
            urban.label,
            merk,
            type,
            sn,
            description,
            latitude,
            longitude,
            mysqlHost,
            mysqlDatabase,
            mysqlPort,
            mysqlUsername,
            mysqlPassword,
            radiusServerSecret,
            radiusServerAuthPort,
            radiusServerAccountingPort
          )
        const result = await routerService.putRouter(id, payload)
        await getRouter()
        loadingOnChange(false)
        await swal(result.status, result.message, "success")
        navigate('/router')
      } catch (error) {
        loadingOnChange(false)
        return await CatchError(error, props)
      }
    }

    const CityLoad = async(city) => {
      const dat = await resourceService.getCity(city.value)
      const result = dat.data
      addCity(result)
      return result
    }

    const subDistrictLoad = async(subDistrict) => {
      const dat = await resourceService.getSubDistrict(subDistrict.value)
      const result = dat.data
      addSubDistrict(result)
      return result
    }

    const UrbanLoad = async(urban) => {
      // sessionStorage.removeItem('urban')
      const dat = await resourceService.getUrban(urban.value)
      const result = dat.data
      addUrban(result)
      return result
    }

    // const gridPoleLoad = async() => {
    //   loadingGridPoleOnChange(true)
    //   const len = 'ALL'
    //   const dat = await routerService.getAllGridPole(id, len)
    //   const result = dat.data
    //   gridPoleListOnChange(result)
    //   // addGridPoleList(result)
    //   // const rawState = renderGridPoleTable(result, viewGridPole, deleteGridPole, Permission.Read, Permission.Delete)
    //   // setgridPoleDataState(rawState)
    //   loadingGridPoleOnChange(false)
    //   return result
    // }

    // const IndoorDeviceLoad = async() => {
    //   loadingIndoorDeviceOnChange(true)
    //   const len = 'ALL'
    //   const dat = await routerService.getAllIndoorDevice(id, len)
    //   const result = dat.data
    //   indoorDeviceListOnChange(result)
    //   // addIndoorDeviceList(result)
    //   // const rawState = renderMainIndoorDeviceTable(result, viewIndoorDevice, deleteIndoorDevice, Permission.Read, Permission.Delete)
    //   // setindoorDeviceDataState(rawState)
    //   loadingIndoorDeviceOnChange(false)
    //   return result
    // }

    // const OutdoorDeviceLoad = async() => {
    //   loadingOutdoorDeviceOnChange(true)
    //   const len = 'ALL'
    //   const dat = await routerService.getAllOutdoorDevice(id, len)
    //   const result = dat.data
    //   outdoorDeviceListOnChange(result)
    //   // addOutdoorDeviceList(result)
    //   // const rawState = renderMainOutdoorDeviceTable(result, viewOutdoorDevice, deleteOutdoorDevice, Permission.Read, Permission.Delete)
    //   // setoutdoorDeviceDataState(rawState)
    //   loadingOutdoorDeviceOnChange(false)
    //   return result
    // }

    useEffect(() => {
      CityLoad(province)
      subDistrictLoad(city)
      UrbanLoad(subDistrict)
      getRouter()
      // gridPoleLoad()
      // IndoorDeviceLoad()
      // OutdoorDeviceLoad()
    }, [])


  // const addGridPole = async() => {
  //   addRouterSelected(routerData)
  //   props.history.push('/admin/gridpole-page')
  // }

  // const addIndoorDevice = async() => {
  //   addRouterSelected(routerData)
  //   props.history.push('/admin/indoor-device-page')
  // }

  // const addOutdoorDevice = async() => {
  //   addRouterSelected(routerData)
  //   props.history.push('/admin/outdoor-device-page')
  // }

  // const viewGridPole = async (gridId) => {
  //   const data = await routerService.getGridPole(gridId)
  //   const gridPole = data.data
  //   addGridPoleData(gridPole)
  //   props.history.push('/admin/gridpole-update')
  // }

  // const viewIndoorDevice = async (indoorId) => {
  //   const data = await routerService.getIndoorDevice(indoorId)
  //   const indoorDevice = data.data
  //   addIndoorDeviceData(indoorDevice)
  //   props.history.push('/admin/indoor-device-update')
  // }

  // const viewOutdoorDevice = async (outdoorid) => {
  //   const data = await routerService.getOutdoorDevice(outdoorid)
  //   const outdoorDevice = data.data
  //   addIOutoorDeviceData(outdoorDevice)
  //   props.history.push('/admin/outdoor-device-update')
  // }

  // const deleteGridPole = async(Id) => {
  //  const value = await swal("Hapus", "Data Tidak Dapat di Recover", "info", {
  //       buttons: {
  //         cancel: 'Batal',
  //         delete: { text: "Hapus", value: 'del' }
  //       }
  //     }
  //     );
  //     switch (value) {
  //       case 'del':
  //         try {
  //           // setDataLoaded(true)
  //           const respomse = await routerService.deleteGridPole(Id);
  //           await swal(respomse.status, respomse.message, 'success')
  //           const rawData = await gridPoleLoad()
  //           const rawState = renderGridPoleTable(rawData, viewGridPole, deleteGridPole, Permission.Read, Permission.Delete)
  //           const newState = rawState.filter((data) => data.id !== Id)
  //           setgridPoleDataState(newState)
  //           return newState
  //         } catch (error) {
  //           // setDataLoaded(false)
  //           return await CatchError(error, props);
  //         }

  //       default:
  //         await gridPoleLoad();
  //         return false;
  //     }
  // }

  // const deleteIndoorDevice = async(Id) => {
  //     const value = await swal("Delete", "Indoor Device Data Can't Recover", "info", {
  //       buttons: {
  //         cancel: 'Cancel',
  //         delete: { text: "Delete", value: 'del' }
  //       }
  //     })

  //     switch (value) {
  //       case 'del':
  //         try {
  //           const respomse = await routerService.deleteIndoorDevice(Id);
  //           await swal(respomse.status, respomse.message, 'success')
  //           const rawData = await IndoorDeviceLoad()
  //           const newState = renderMainIndoorDeviceTable(rawData, viewIndoorDevice, deleteIndoorDevice, Permission.Read, Permission.Delete)
  //           setindoorDeviceDataState(newState)
  //           return newState
  //         } catch (error) {
  //           // setDataLoaded(false)
  //           return await CatchError(error, props);
  //         }

  //       default:
  //         await IndoorDeviceLoad();
  //         return false;
  //     }
  // }

  // const deleteOutdoorDevice = async(Id) => {
  //  const value = await swal("Delete", "Outdoor Device Data Can't Recover", "info", {
  //       buttons: {
  //         cancel: 'Cancel',
  //         delete: { text: "Delete", value: 'del' }
  //       }
  //     })

  //     switch (value) {
  //       case 'del':
  //         try {
  //           const respomse = await routerService.deleteOutdoorDevice(Id);
  //           await swal(respomse.status, respomse.message, 'success')
  //           const rawData = await OutdoorDeviceLoad()
  //           const rawState = renderMainOutdoorDeviceTable(rawData, viewOutdoorDevice, deleteOutdoorDevice, Permission.Read, Permission.Delete)
  //           setoutdoorDeviceDataState(rawState)
  //           return rawState
  //         } catch (error) {
  //           // setDataLoaded(false)
  //           return await CatchError(error, props);
  //         }
  //       default:
  //         await OutdoorDeviceLoad();
  //         return false;
  //     }
  //   }

  //   const ChangeOwner = async() => {
  //     try {
  //         loadingOnChange(true)
  //         const payload = {owner: ownerTransfer.value}
  //         const result = await routerService.changeRouterOwner(id, payload)
  //         await getRouter()
  //         loadingOnChange(false)
  //         swal(result.status, result.message, "success").then(() => {
  //             props.history.push('/admin/router-list')
  //         })
  //       } catch (error) {
  //         loadingOnChange(false)
  //         return await CatchError(error, props)
  //       }
  //   }

  //   function RouterChangeOwner ({transfer, transferOnChange, filterData, updateOwner}) {
  //   return (
  //     <>
  //       <Card>
  //         <CardHeader>
  //           <h5 className="title">Change Router Owner</h5>
  //         </CardHeader>
  //           <CardBody>
  //             <Form>
  //               <label>From Owner</label>
  //               <DefaultInput
  //                 className="react-select"
  //                 classNamePrefix="react-select"
  //                 label="Owner"
  //                 name="singleSelect"
  //                 value={owner.value} />
  //               <label>To</label>
  //               <Select
  //                 // className="react-select"
  //                 // classNamePrefix="react-select"
  //                 label="Transfer To"
  //                 name="singleSelect"
  //                 value={transfer}
  //                 option = {filterData}
  //                 onChange = {transferOnChange}
  //               />
  //               <Button onClick = {updateOwner} color="info">Transfer</Button>
  //           </Form>
  //         </CardBody>
  //       </Card>
  //     </>
  //   )}

    // const SuperAdminMenu = () => {
    //   if (usrType === 'SUPERADMIN') {
    //     return (
    //       <>
    //       <RouterChangeOwner
    //         transfer={ownerTransfer}
    //         transferOnChange={ownerTransferOnChange}
    //         filterData={ownerFilter}
    //         updateOwner = {ChangeOwner}
    //       />
    //       <CommonList
    //         key={1}
    //         header={'Profile'}
    //         columns={columns}
    //         dataState={dataState}
    //         // Add={Add}
    //         load={loaded}
    //         getData={getItem} /></>
    //     )}
    //     return (<p></p>)
    // }

    // const [gridPoleDataState, setgridPoleDataState] = useState(renderGridPoleTable(gridPoleList, viewGridPole, deleteGridPole, Permission.Read, Permission.Delete))
    // const [indoorDeviceDataState, setindoorDeviceDataState] = useState(renderMainIndoorDeviceTable(indoorDeviceList, viewIndoorDevice, deleteIndoorDevice, Permission.Read, Permission.Delete))
    // const [outdoorDeviceDataState, setoutdoorDeviceDataState] = useState(renderMainOutdoorDeviceTable(outdoorDeviceList, viewOutdoorDevice, deleteOutdoorDevice, Permission.Read, Permission.Delete))

  const child = (
    <>
        {/* Main Content */}
        <div className="mainCard">
          <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
            {/* <form> */}
              <GlobalHeader
                header = {'Detail Router NAS'}
              />
               <InputCoordinate
                  latitude = {latitude}
                  setLatitude = {setLatitude}
                  longitude = {longitude}
                  setLongitude = {setLongitude}
                />
                <DefaultInput
                  value = {name}
                  onChange = {(e) => setName(e.target.value)}
                  label="Username"
                  type={"text"}
                />
                <DefaultInput
                  label="Owner"
                  name="singleSelect"
                  value = {owner}
                  onChange = {() => {}}
                />
                <DefaultInput
                  label="IP Address"
                  value = {ipaddress}
                  disabled = {!userSuper || dynamicIp}
                  onChange = {(e) => {
                    if (e.target.value.length <= 15) {
                      ipaddressOnChange(e.target.value)
                    }
                  }}
                  type={"text"}
                />
                <Checklist
                    text = 'Gunakan IP Dinamis, *IP Dinamis akan menonaktifkan koneksi Mikrotik API'
                    value={dynamicIp}
                    checked = {dynamicIp}
                    onChange={(e) => {
                      setDynamicIp(e.target.checked)
                      ipaddressOnChange('0.0.0.0/0')
                    }}
                  />
                <DefaultInput
                  label="Mikrotik API Port"
                  value = {port}
                  disabled = {!userSuper}
                  onChange = {(e) => {
                    if (e.target.value.length <= 4) {
                      portOnChange(e.target.value)
                    }
                  }}
                  type={"number"}
                />
                <DefaultInput
                  label="password"
                  value = {passwordValue}
                  disabled = {!userSuper}
                  onChange = { (e) => {
                    if (e.target.value.length <= 40) {
                      passwordValueOnChange(e.target.value)
                    }
                  }
                  }
                  type={passwordShown ? "text" : "password"}
                />
                <Checklist
                  text = 'Tampilkan Password'
                  value={passwordShown}
                  onChange={togglePassword}
                />
                <FreeRadiusCredentialForm
                  header = {'FreeRadius Server Credential'}
                  radiusServerSecretLabel = {'FreeRadius Secret'}
                  radiusServerAuthPortLabel = {'FreeRadius Authentication Port'}
                  radiusServerAccountingPortLabel = {'FreeRadius Accounting Port'}
                  radiusServerSecret = {radiusServerSecret}
                  setRadiusServerSecret = {(e) => setRadiusServerSecret(e.target.value)}
                  radiusServerAuthPort = {radiusServerAuthPort}
                  setRadiusServerAuthPort = {(e) => {
                    if (verifyNetworkPortRange(e)) {
                      setRadiusServerAuthPort(e.target.value)
                    }
                  }}
                  radiusServerAccountingPort = {radiusServerAccountingPort}
                  setRadiusServerAccountingPort = {(e) => {
                    if (verifyNetworkPortRange(e)) {
                      setRadiusServerAccountingPort(e.target.value)
                    }
                  }}
                  passwordShow = {passwordRadiusShow}
                  setPasswordShow = {setPasswordRadiusShow}
                />
                <SelectBasic
                  label="Provinsi"
                  name="singleSelect"
                  value = {province}
                  option = {selectProvinceOption}
                  onChange={async(value) => {
                    // sessionStorage.removeItem('city')
                    const data = await CityLoad(value)
                    cityOnChange(null)
                    subDistrictOnChange(null)
                    urbanOnChange(null)
                    provinceOnChange(value)
                    cityOptionOnChange(data)
                    subDistrictOptionOnChange([])
                    urbanOptionOnChange([])
                  }
                    }
                  />
                  <div>
                  <SelectBasic
                    label="Kota"
                    name="singleSelect"
                    value = {city}
                    option={cityOption}
                    onChange={async (value) => {
                      subDistrictOnChange(null)
                      urbanOnChange(null)
                      const data = await subDistrictLoad(value)
                      subDistrictOptionOnChange(data)
                      urbanOptionOnChange([])
                      cityOnChange(value)
                    }}
                  />
                  {!city || !city.status ? (
                    <label className="error">
                      Phone Code Not Available, Set Default code (000)
                    </label>
                  ) : <label className="success">
                      Phone Code Available
                    </label>}
                  </div>
                  <div>
                  <SelectBasic
                    label="Kecamatan"
                    name="singleSelect"
                    value = {subDistrict}
                    option={subDistrictOption}
                    onChange={async (value) => {
                      urbanOnChange(null)
                      subDistrictOnChange(value)
                      const data = await UrbanLoad(value)
                      urbanOptionOnChange(data)
                    }}
                  />
                  {!subDistrict || !subDistrict.status ? (
                    <label className="error">
                      Phone Code Not Available, Set Default code (000)
                    </label>
                  ) : <label className="success">
                      Phone Code Available
                    </label>}
                  </div>
                  <SelectBasic
                    label="Kelurahan"
                    name="singleSelect"
                    value = {urban}
                    option={UrbanOption}
                    onChange={(value) => urbanOnChange(value)}
                  />
                  <DefaultInput
                    value = {merk}
                    onChange = {(e) => setMerk(e.target.value)}
                    label="Merk"
                    type={"text"}
                  />
                  <DefaultInput
                    value = {type}
                    onChange = {(e) => setType(e.target.value)}
                    label="Type"
                    type={"text"}
                  />
                  <DefaultInput
                    value = {sn}
                    onChange = {(e) => setSn(e.target.value)}
                    label="Serial Number"
                    type={"text"}
                  />
                  <DefaultInput
                    value = {description}
                    onChange = {(e) => setDescription(e.target.value)}
                    label="Deskripsi"
                    type={"text"}
                  />
                  <FreeRadiusDatabaseForm
                    header = {'Router Radius Mysql Connection'}
                    mysqlHostLabel = {'Radius MSQL Database Host'}
                    mysqlPortLabel = {'Radius MSQL Database Port'}
                    mysqlDatabaseNameLabel = {'Radius MSQL Database Name'}
                    mysqlDatabaseUsernameLabel = {'Radius MSQL Database Username'}
                    mysqlDatabasePasswordLabel = {'Radius MSQL Database Password'}
                    mysqlHost = {mysqlHost}
                    setMysqlHost = {(e) => {
                      setMysqlHost(e.target.value)
                    }}
                    mysqlPort = {mysqlPort}
                    setMysqlPort = {(e) => {
                      if (verifyNetworkPortRange(e)) {
                        setMysqlPort(e.target.value)
                      }
                    }}
                    mysqlDatabase = {mysqlDatabase}
                    setMysqlDatabase = {(e) => {
                      setMysqlDatabase(e.target.value)
                    }}
                    mysqlUsername = {mysqlUsername}
                    setMysqlUsername = {(e) => {
                      setMysqlUsername(e.target.value)
                    }}
                    mysqlPassword = {mysqlPassword}
                    setMysqlPassword = {(e) => {
                      setMysqlPassword(e.target.value)
                    }}
                    passwordShow = {passwordDbShow}
                    setPasswordShow = {setPasswordDbShow}
                    bgColor = {'bg-amber-200'}
                  />
                <DefaultInput
                  label="Created"
                  value = {DateTimestr(creation)}
                  onChange = {() => {}}
                  type={"text"}
                />
                <DefaultInput
                  label="Modified"
                  value = {DateTimestr(modified)}
                  onChange = {() => {}}
                  type={"text"}
                />
                <DefaultInput
                  label="Modified By"
                  value = {createdBy}
                  onChange = {() => {}}
                  type={"email"}
                />
                <DefaultInput
                  label="Modified By"
                  value = {modifiedBy}
                  onChange = {() => {}}
                  type={"email"}
                />
              <div className="mt-6 flex flex-row gap-4">
                <PrimaryIconButton
                  title="Update"
                  icon = {faRefresh}
                  onClick = {updateRouterData}
                />
              </div>
            {/* </form> */}
          </div>
        </div>
    </>
  )

  return (
    <>
      <main className="h-full">
        <Navbar toggle={sidebarToggle} />
        <LoadingScreen
          // key = {RandomTextGenerator()}
          children={child}
          loading = {loading}
          text={"Saving..."}
        />
      </main>
    </>
  );
}

export default RouterDetail;
