import Checklist from "components/Button/Checklist"
import { GlobalHeader } from "components/Datatables/GlobalHeader"
import DefaultInput from "components/Input/DefaultInput"

export default function FreeRadiusDatabaseForm({
    mysqlHost, setMysqlHost,
    mysqlPort, setMysqlPort,
    mysqlDatabase, setMysqlDatabase,
    mysqlUsername, setMysqlUsername,
    mysqlPassword, setMysqlPassword,
    passwordShow, setPasswordShow,
    header = 'Default Radius Mysql Connection',
    mysqlHostLabel = 'Default Radius MSQL Database Host',
    mysqlPortLabel = 'Radius MSQL Database Port',
    mysqlDatabaseNameLabel = 'Radius MSQL Database Name',
    mysqlDatabaseUsernameLabel = 'Radius MSQL Database Username',
    mysqlDatabasePasswordLabel = 'Radius MSQL Database Password',
    bgColor = 'bg-amber-200'
}) {
    return (
        <div className={`${bgColor} mt-10 mb-10 shadow-sm`}>
          <div className="title">
            <GlobalHeader
                textSize = "text-md text-center"
                header = {header}
            />
          </div>
          <div className="body m-5">
            <DefaultInput
              label = {mysqlHostLabel}
              placeHolder = {mysqlHostLabel}
              type="text"
              value = {mysqlHost}
              onChange={setMysqlHost}
            />
            <DefaultInput
                label= {mysqlPortLabel}
                placeHolder = {mysqlPortLabel}
                value = {mysqlPort}
                type="number"
                onChange={setMysqlPort}
            />
            <DefaultInput
                label= {mysqlDatabaseNameLabel}
                placeHolder = ''
                value = {mysqlDatabase}
                type="text"
                onChange={setMysqlDatabase}
            />
            <DefaultInput
                label= {mysqlDatabaseUsernameLabel}
                placeHolder = {mysqlDatabaseUsernameLabel}
                value = {mysqlUsername}
                type="text"
                onChange={setMysqlUsername}
            />
            <DefaultInput
                label= {mysqlDatabasePasswordLabel}
                placeHolder = {mysqlDatabasePasswordLabel}
                value = {mysqlPassword}
                onChange={setMysqlPassword}
                type={passwordShow ? "text" : "password"}
            />
            <Checklist
              text = 'Tampilkan Mysql Password'
              value={passwordShow}
              onChange={(e) => {setPasswordShow(e.target.checked)}}
            />
            <div className="py-3 px-5"></div>
          </div>
        </div>
    )
}