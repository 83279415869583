import AuthService from './AuthService'
import APIconsume from './ConsumeAPI'
import { getAccessToken } from 'data/Variable/Common'

class RouterNasService extends AuthService {
    constructor() {
        super()
        const accessToken = getAccessToken()
        this._consume = new APIconsume(accessToken)
        this._url = 'routerManager'
        this._indoorDeviceUrl = 'indoordevice'
        this._outdoorDeviceUrl = 'outdoordevice'
        this._gridPoleUrl = 'gridpole'
    }

    async addRouter (payload) {
        const result = await this._consume.postData(payload, this._url)
        return result.data
    }

    async getRouter (id) {
        const url = `${this._url}/${id}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async testRouter (id) {
        const url = `router-test/${id}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getRouterSelect (mitraid = '', urbanid = '') {
        const url = `routerManager-select?id=${urbanid}&mitraid=${mitraid}`
        // console.log(url, mitraid, urbanid)
        const result = await this._consume.getData(url)
        return result.data
    }

    async getAllRouter (len = 'ALL', page = '') {
        let url = `${this._url}/view?line=${len}&page=${page}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async putRouter (id, payload) {
        const url = `${this._url}/${id}`
        const result = await this._consume.putData(payload, url)
        return result.data
    }

    async changeRouterOwner (id, payload) {
        const url = `${this._url}/Ownership/${id}`
        const result = await this._consume.putData(payload, url)
        return result.data
    }

    async deleteRouter (id) {
        const url = `${this._url}/${id}`
        const result = await this._consume.deleteData(url)
        return result.data
    }

     async addGridPole (payload) {
        const result = await this._consume.postData(payload, this._gridPoleUrl)
        return result.data
    }

    async getGridPole (id) {
        const url = `${this._gridPoleUrl}/${id}`
        const result = await this._consume.getData(url)
        return result.data
    }
  async getAllGridPole (routerid = '', len = 'ALL', page = '') {
        let url = `${this._gridPoleUrl}/view?routerid=${routerid}&line=${len}&page=${page}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async putGridPole (id, payload) {
        const url = `${this._gridPoleUrl}/${id}`
        const result = await this._consume.putData(payload, url)
        return result.data
    }

    async deleteGridPole (id) {
        const url = `${this._gridPoleUrl}/${id}`
        const result = await this._consume.deleteData(url)
        return result.data
    }

     async addIndoorDevice (payload) {
        const result = await this._consume.postData(payload, this._indoorDeviceUrl)
        return result.data
    }

    async getIndoorDevice (id) {
        const url = `${this._indoorDeviceUrl}/${id}`
        const result = await this._consume.getData(url)
        return result.data
    }

  async getAllIndoorDevice (routerid = '', len = 'ALL', page = '') {
        let url = `${this._indoorDeviceUrl}/view?routerid=${routerid}&line=${len}&page=${page}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async putIndoorDevice (id, payload) {
        const url = `${this._indoorDeviceUrl}/${id}`
        const result = await this._consume.putData(payload, url)
        return result.data
    }

    async deleteIndoorDevice (id) {
        const url = `${this._indoorDeviceUrl}/${id}`
        const result = await this._consume.deleteData(url)
        return result.data
    }

     async addOutdoorDevice (payload) {
        const result = await this._consume.postData(payload, this._outdoorDeviceUrl)
        return result.data
    }

    async getOutdoorDevice (id) {
        const url = `${this._outdoorDeviceUrl}/${id}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getAllOutdoorDevice (routerid = '', len = 'ALL', page = '') {
        let url = `${this._outdoorDeviceUrl}/view?routerid=${routerid}&line=${len}&page=${page}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async putOutdoorDevice (id, payload) {
        const url = `${this._outdoorDeviceUrl}/${id}`
        const result = await this._consume.putData(payload, url)
        return result.data
    }

    async deleteOutdoorDevice (id) {
        const url = `${this._outdoorDeviceUrl}/${id}`
        const result = await this._consume.deleteData(url)
        return result.data
    }

    async getAllItemByRouterid (rtrid = '', len = 'ALL', page = '') {
        let url = `Router/Item/view?routerid=${rtrid}&line=${len}&page=${page}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getRouterSecret (id) {
        const url = `${this._url}/pppSecret/${id}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getTopology (id) {
        const url = `${this._url}/map/topology?mitra=${id}`
        const result = await this._consume.getData(url)
        return result.data
    }

    async getpppProfileSelect(routerId = '') {
        try {
            const url = `routerManager/pppProfile/${routerId}`
            const result = await this._consume.getData(url)
            return result.data
        } catch (error) {
            return []
        }
    }

    async getpppSession(routerId = '23eh2id2nedi23en23ie') {
        try {
            const url = `${this._url}/ppp/session?routerId=${routerId}`
            const result = await this._consume.getData(url)
            return result.data
        } catch (error) {
            return []
        }
    }

    async getHostpotSession(routerId = '23eh2id2nedi23en23ie') {
        try {
            const url = `${this._url}/hostpot/session?routerId=${routerId}`
            const result = await this._consume.getData(url)
            return result.data
        } catch (error) {
            return []
        }
    }
}

export default RouterNasService