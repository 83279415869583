export const ResellerHeader = [
    {
      label: "Key",
      key: "key",
    },
    {
      label: "Nama",
      key: "name",
    },
    {
      label: "Email",
      key: "email",
    },
    {
      label: "Created By",
      key: "created",
    },
    {
      label: "Biaya Admin",
      key: "adminFee",
    },
    {
       label: "Status",
       key: "status",
       sortable: false,
       filterable: false,
    },
    // {
    //    label: "Active",
    //    key: "statustest",
    //    sortable: false,
    //    filterable: false,
    // },
    {
      label: "Action",
      key: "actions",
      sortable: false,
      filterable: false,
    },
]