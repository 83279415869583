import StatisticWidget from "./../../components/Widget/Statistic.jsx";
import AchievementWidget from "./../../components/Widget/Achievment.jsx";
const { RandomTextGenerator } = require("data/Variable/Utils");

export default function DashboardChart({
    chartData = [],
    date,
    profitability = 0,
    profit = 0
}) {
    return (
        <>
            <div className="px-2 mx-auto mainCard layoutAdmin">
              <div className="w-full overflow-hidden text-slate-700 md:grid gap-4 grid md:grid-cols-6">
                <StatisticWidget data = {chartData} key={RandomTextGenerator()} className="col-span-4 col-start-1 bg-white" />
                <AchievementWidget date = {date} profitability={profitability} profit = {profit} />
              </div>
            </div>
        </>
    )
}