import React, { useEffect, useState } from "react";
import Navbar from "./../../components/Navbar/Index";
import { useNavigate, useOutletContext } from "react-router-dom";
import MainTable from "./../../components/Table/MainTable";
import PrimaryIconButton from "components/Button/PrimaryIconButton";
import { UserHeader } from "./UserHeader";
import { RandomTextGenerator } from "data/Variable/Utils";
import UserService from "data/Handler/UserService";
import swal from "sweetalert";
import { EditButton } from "components/Button/EditButton";
import { DeleteButton } from "components/Button/DeleteButton";
import { addUserManagerData } from "data/Variable/UserVariable";
import { addRoleManagerList } from "data/Variable/UserVariable";
import { CatchError } from "data/Handler/Exceptions/exceptions";
import { customerOperation } from "data/Variable/Utils";
import { customTextFromBoolean } from "data/Variable/Utils";
import { userStatus } from "data/Variable/Utils";
import { validatePermission } from "data/Variable/Utils";
import { getUserPermission } from "data/Variable/UserVariable";
import { getUser } from "data/Variable/UserVariable";
import PrimaryButton from "components/Button/PrimaryButton";

function UserList(props) {
  const pages = 'userManager'
  const [sidebarToggle] = useOutletContext();
  const navigate = useNavigate()
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role === pages) || ''
  const userService =  new UserService()
  const [userLen] = useState('ALL')
  const [load, setDataLoad] = useState(true)
  const [Table, setDataTable] = useState([])

  const fetch =  async() => {
    try {
      setDataLoad(true)
      const len = userLen.value
      const dat =  await userService.getAllUser(len)
      const result = dat.data

      setDataState(mapping(result))
      setDataTable(result)
      setDataLoad(false)
      return result
    } catch (error) {
      setDataLoad(false)
      return await CatchError(error, props)
    }
  }

  useEffect( () => {
    validatePermission(props, 3, 1)
    fetch()
  }, [])

    const deleteUser = async (id) => {
      const option = await swal("Delete", "Data Tidak Dapat di Recover", "info", {
          buttons: {
            cancel: 'Batal',
            delete: {text: "Hapus", value: 'del' }
          }
        }
      )
      switch (option) {
        case 'del':
          try {
            setDataLoad(true)
            validatePermission(props, 3, 3)
            const response = await userService.deleteUser(id)
            await swal(response.status, response.message, 'success')
            const state = await getUser()
            const stateRaw = mapping(state)
            const table = stateRaw.filter((dat) => dat.id !== id)
            setDataState(table)
            setDataLoad(false)
            return true
          } catch (error) {
            return await await CatchError(error, props)
          }

        default:
          await getUser()
          return false
      }
    }

  const viewUser = async (id) => {
    try {
      const result = await userService.getUser(id)
      const userPermission = await userService.getAllPermission(id)
      addUserManagerData(result.data)
      addRoleManagerList(userPermission.data)
      return navigate('/user/detail', { state: result.data })
    } catch (error) {
      return await await CatchError(error, props)
    }
  }

  const setUserStatus = async(id, Status) => {
    try {
      const payload = setUserStatus(!Status)
      var operation = customerOperation(!Status)
      const dat = await userService.setUserStatus(id, payload)
      await swal(operation, dat.message, dat.status)
      const result = dat.data
      const table = await getUser()
      setDataState(mapping(table))
      return result
    } catch (error) {
      return await await CatchError(error, props)
    }
  }

  const mapping = (TableData) => TableData.map((Data) => {
    try {
      return {
        id: Data.id,
        name: Data.username,
        email: Data.email,
        type: Data.userType,
        login: customTextFromBoolean(Data.login),
        statusData: userStatus(Data.enabled),
        organization: Data.owner,
        status: (
            <div className="actions-center">
              <PrimaryButton
                title = {Data.enabled ? 'Active' : 'Disable'}
                disabled={Permission.Update || (TableData.length === 1)}
                key = {RandomTextGenerator()}
                onClick={ async(e) =>{
                  e.preventDefault()
                  // statusOnChange(e)
                  await setUserStatus(Data.id, Data.enabled)
                }}
              />
            </div>
        ),
        actions: (
          // we've added some custom button actions
          <div className="actions-right" key={Data.id}>
            {/* use this button to add a edit kind of action */}
            <EditButton
              key = {RandomTextGenerator()}
              disabled={!Permission.Read}
              onClick={async (e) => {
                e.preventDefault();
                await viewUser(Data.id)
              }}
            />
            <DeleteButton
              key = {RandomTextGenerator()}
              disabled={!Permission.Delete}
              onClick={async (e) => {
                e.preventDefault();
                await deleteUser(Data.id)
              }}
            />
          </div>
        ),
      };
    } catch (error) {
      return []
    }
    }
  )

  const dataTable = Table
  const [dataState, setDataState] = useState(mapping(dataTable));

  useEffect(() => {
    fetch()
  }, [])

  function onClickAdd() {
    return navigate('/user/form')
  }

  const menu = (
    <>
    <div className="flex flex-row my-3">
      <div className="flex md:flex-col mr-10">
        <PrimaryIconButton
          key = {RandomTextGenerator()}
          title = {'Add'}
          onClick = {onClickAdd}
        />
      </div>
      {/* <div className="flex md:flex-col mr-10">
        <PrimaryIconButton
          key = {RandomTextGenerator()}
          title = {'Export'}
        />
      </div> */}
    </div>
    </>
  )

  return (
    <>
      <main className="h-full">
        <Navbar key={RandomTextGenerator()} toggle={sidebarToggle} />

        {/* Main Content */}
        <div className="mainCard">
          <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
            <MainTable
              key={RandomTextGenerator()}
              header = {'User Manager'}
              menu={menu}
              loading={load}
              dataHeader={UserHeader}
              dataState={dataState}
            />
          </div>
        </div>
      </main>
    </>
  );
}

export default UserList;
