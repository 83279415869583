import React from "react";
import Header from "./Header";
// import { Card, CardBody, CardHeader } from "reactstrap";
import { RandomTextGenerator } from "data/Variable/Utils";
import { GlobalHeader } from "./GlobalHeader";

function Table({
  dataHeader,
  handleSort,
  direction,
  field,
  children,
  header = 'header',
  menu,
  searchValue,
  searchOnChange
} = {}) {
  return (
    <div className="card overflow-x-auto">
      {/* <CardHeader>

      </CardHeader>  */}
      {/* <CardBody> */}
        <div className="header">
          <GlobalHeader
             header = {header}
          />
          <div className="search">
            <input
              className="input-search"
              placeholder="Search..."
              value={searchValue}
              onChange={e => searchOnChange(e.target.value)}
            />
          </div>
        </div>
        <div className="body text-center">
          {menu}
          <table
            className={`block md:table w-full text-sm text-left text-gray-500`}
          >
            <Header
              key={RandomTextGenerator()}
              data={dataHeader}
              handleSort={handleSort}
              direction={direction}
              field={field}
            ></Header>
            <tbody className="block md:table-row-group">{children}</tbody>
          </table>
        </div>
      {/* </CardBody> */}
    </div>
  );
}

export default Table;
