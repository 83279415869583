import React, { useEffect, useState } from "react";
import Navbar from "./../../../components/Navbar/Index";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import DefaultInput from "../../../components/Input/DefaultInput";
import { payloadPppoeProfileUpdate } from "data/Variable/Pppoe/PppoeProfile";
import { gethostpotProfileInternetMeasurementData } from "data/Variable/HostpotVoucher/HostpotProfile";
import PppoeService from "data/Handler/Pppoe/PppoeProfileService";
import swal from "sweetalert";
import { CatchError } from "data/Handler/Exceptions/exceptions";
import RouterNasService from "data/Handler/RouterNasService";
import { PriceFormat } from "data/Variable/Common";
import Checklist from "components/Button/Checklist";
import SelectBasic from "components/Input/SelectBasic";
import { DateTimestr } from "data/Variable/Utils";
import PrimaryIconButton from "components/Button/PrimaryIconButton";
import LoadingScreen from "components/Loader/LoadingScreen";
import { GlobalHeader } from "components/Datatables/GlobalHeader";

function PppoeProfileDetail(props) {
  const [sidebarToggle] = useOutletContext();
  const navigate = useNavigate()
  const location = useLocation()
  const { state } = location
  const Pppoe = state // || getPppoeData()
  const internetMeasurement = gethostpotProfileInternetMeasurementData()
  const routerService = new RouterNasService()
  const pppoeService =  new PppoeService()
  const internetLimitMeasurementSelect = gethostpotProfileInternetMeasurementData()
  // const { userSuper } = verifySuperAdmin()
  const [poolSelect, setPoolSelect] = useState([])
  const [mitra, mitraOnChange] = useState(Pppoe.mitra)
  const [loading, loadingOnChange ] = useState(false)
  const [name, setName] = useState(Pppoe.name)
  const [pool, setPool] = useState(Pppoe.pool)
  const [ip, setIp] = useState(Pppoe.ip)
  const [subnet, setSubnet] = useState(Pppoe.subnet || 24)
  const [rateOut, setRateOut] = useState(Pppoe.rateOut)
  const [rateIn, setRateIn] = useState(Pppoe.rateIn)
  const [price, setPrice] = useState(Pppoe.price)
  const [discount, setDiscount] = useState(Pppoe.discount)
  const [rateOutMeasurement, setRateOutMeasurement] = useState(internetLimitMeasurementSelect.find(own => own.value === Pppoe.rateOutMeasurement))
  const [rateInMeasurement, setRateInMeasurement] = useState(internetLimitMeasurementSelect.find(own => own.value === Pppoe.rateInMeasurement))
  const [description, setDescription ] = useState(Pppoe.description)
  const [router] = useState(Pppoe.routerName)
  const [RouterpppProfile, SetRouterpppProfile] = useState()
  const [useProfilePPPlist, setUseProfilePPPlist] = useState(false)
  const [RouterPool, SetRouterPool] = useState({})
  const {id, creation, modified,
    createdBy, modifiedBy, routerId } = Pppoe

  const getRouterPPP =  async(routerId) => {
    const result =  await routerService.getpppProfileSelect(routerId)
    const poolData = result.data
    setPoolSelect(poolData)
    const poolSelected = poolData.find(e => e.value === Pppoe.pool)
    SetRouterPool(poolSelected)
    setPool((!poolSelected || !poolSelected.value) ? Pppoe.pool : poolSelected.value)
    return result.data
  }

  useEffect(() => {
    getRouterPPP(routerId)
  }, [])


  const update = async() => {
    const selection = await swal("Update", "Perubahan Data Tidak Dapat di Batalkan", "info", {
            buttons: {
            update: {text: "Update", value: 'up' },
            cancel: 'Batal'
          }
        }
    )
    switch (selection) {
        case 'up':
          try {
            loadingOnChange(true)
            const payload = payloadPppoeProfileUpdate(
              name,
              price,
              discount,
              rateIn,
              rateOut,
              pool || RouterPool.name,
              ip,
              subnet,
              rateInMeasurement.value,
              rateOutMeasurement.value,
              description)
            // console.log(payload)
            const result = await pppoeService.put(id, payload)

            await swal(result.status, result.message, 'success')
            loadingOnChange(false)
            return navigate('/pppoe/profile')
          } catch (error) {
            loadingOnChange(false)
            return await CatchError(error, props)
          }

        default:
          break;
      }
  }

  const child = (
    <>
      {/* Main Content */}
        <div className="mainCard">
          <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
              <GlobalHeader
                header = {'Detail PPPOE Hostpot'}
              />
              <DefaultInput
                key={"2"}
                label = {'Mitra'}
                value = {mitra}
                disabled
                onChange = {(e) => mitraOnChange(e.target.value)}
                type
              />
              <DefaultInput
                key={"2"}
                label = {'nama'}
                value = {name}
                onChange = {(e) => setName(e.target.value)}
                type
              />
              <DefaultInput
                key={"2"}
                label = {'Lokasi Router'}
                value = {router}
                onChange = {(e) => {}}
                type
              />
              <DefaultInput
                key={"3"}
                label = {'Harga'}
                value = {price}
                onChange = {(e) => {
                  if (e.target.value >= 0) {
                    setPrice(e.target.value)
                  }
                }}
                type = {'number'}
              />
              <DefaultInput
                key={"4"}
                label = {'Price Display'}
                value = {PriceFormat((price - discount), 'idr')}
                disabled
                onChange = {() => {}}
              />
              <DefaultInput
                key={"3"}
                label = {'Diskon'}
                value = {discount}
                onChange = {(e) => {
                  if (e.target.value >= 0 && e.target.value <= price) {
                    setDiscount(e.target.value)
                  }
                }}
                type = {'number'}
              />
              {!useProfilePPPlist ? (
                  <>
                     <DefaultInput
                       key={"8"}
                       label = {'Nama Pool'}
                       value = {pool}
                       onChange = {(e) => setPool(e.target.value)}
                     />
                  </>
                ) : (
                  <>
                  <SelectBasic
                    key={"9"}
                    label={" Select PPP From Router"}
                    value={RouterpppProfile}
                    option={poolSelect}
                    onChange={(e) => {
                      SetRouterpppProfile(e)
                      setPool(e.value)
                      setIp(e.ip)
                      setSubnet(Number(e.subnet))
                      }} />
                  </>
                )
              }
              <DefaultInput
                key={"8"}
                label = {'IP Address'}
                value = {ip}
                onChange = {(e) => setIp(e.target.value)}
              />
              <DefaultInput
                key={"9"}
                label = {'Subnet'}
                value = {subnet}
                onChange = {(e) => {
                    if (e.target.value >= 0 && e.target.value <= 32) {
                      setSubnet(e.target.value)
                    }
                  }
                }
                type = {'number'}
              />
              <Checklist
                key={"9"}
                text = {"Memakai Pool Router"}
                value = {useProfilePPPlist}
                onChange = { async (e) => {
                  setUseProfilePPPlist(e.target.checked)
                  setPool('')
                  setIp('0.0.0.0')
                  setSubnet(24)
                  if (!e.target.checked) {
                    SetRouterpppProfile([])
                    return e
                  }
                  await getRouterPPP(router.value)
                }}
              />
              <DefaultInput
                key={"10"}
                label = {'Kecepatan Upload'}
                value = {rateOut}
                onChange = {(e) => {
                  if (e.target.value >= 0) {
                    setRateOut(e.target.value)
                  }
                }
                }
                type = {'number'}
              />
              <SelectBasic
                key={"11"}
                label = {'Upload Measurement'}
                value = {rateOutMeasurement}
                onChange = {setRateOutMeasurement}
                option = {internetMeasurement}
              />
              <DefaultInput
                key={"12"}
                label = {'Kecepatan Download'}
                value = {rateIn}
                onChange = {(e) => {
                  if (e.target.value >= 0) {
                    setRateIn(e.target.value)
                  }
                }}
                type = {'number'}
              />
              <SelectBasic
                key={"13"}
                label = {'Download Measurement'}
                value = {rateInMeasurement}
                onChange = {setRateInMeasurement}
                option = {internetMeasurement}
              />
              <DefaultInput
                key={"21"}
                label = {'Deskripsi'}
                value = {description}
                onChange = {(e) => setDescription(e.target.value)}
                type = {'textarea'}
              />
              <DefaultInput
                key={"9"}
                label = {'Creation'}
                disabled
                value = {DateTimestr(creation)}
                onChange={() => {}}
              />
              <DefaultInput
                key={"10"}
                label = {'Modified'}
                disabled
                value = {DateTimestr(modified)}
                onChange={() => {}}
              />
              <DefaultInput
                key={"11"}
                label = {'CreatedBy'}
                disabled
                value = {createdBy}
                onChange={() => {}}
              />
              <DefaultInput
                key={"12"}
                label = {'ModifiedBy'}
                disabled
                type={"textarea"}
                value = {modifiedBy}
                onChange={() => {}}
              />
              <div className="mt-6 flex flex-row gap-4">
                <PrimaryIconButton
                  title="Save"
                  onClick = {update}
                />
              </div>
          </div>
        </div>
    </>
  )

  return (
    <>
      <main className="h-full">
        <Navbar toggle={sidebarToggle} />
        <LoadingScreen
          // key = {RandomTextGenerator()}
          children={child}
          loading = {loading}
          text={"Saving..."}
        />
      </main>
    </>
  );
}

export default PppoeProfileDetail;
