import { getData, setData } from "../Common"

const identifier = ['PppoeProfileData', 'PppoeProfileList', 'PppoeProfileInternetMeasurementSelect', 'PppoeProfileTimeMeasurementSelect']
const [
    data,
    list,
    PppoeProfileInternetMeasurement,
    PppoeProfileTimeMeasurement
] = identifier

export function addPppoeProfileData(payload) {
  setData(data, payload)
}

export function getPppoeProfileData() {
 return getData(data)
}

export function addPppoeProfileList(payload) {
  setData(list, payload)
}

export function getPppoeProfileList() {
 return getData(list)
}

export function addPppoeProfileInternetMeasurementData(payload) {
  setData(PppoeProfileInternetMeasurement, payload)
}

export function getPppoeProfileInternetMeasurementData() {
 return getData(PppoeProfileInternetMeasurement)
}

export function addPppoeProfileTimeMeasurementData(payload) {
  setData(PppoeProfileTimeMeasurement, payload)
}

export function getPppoeProfileTimeMeasurementData() {
 return getData(PppoeProfileTimeMeasurement)
}

export function payloadPppoeProfile(
      name,
      mitra,
      routerId,
      price,
      profileDiscount,
      rateIn,
      rateOut,
      pool,
      ip,
      subnet,
      rateInMeasurement,
      rateOutMeasurement,
      description) {
    return {
      name,
      mitra,
      routerId,
      price,
      profileDiscount,
      rateIn,
      rateOut,
      pool,
      ip,
      subnet,
      rateInMeasurement,
      rateOutMeasurement,
      description
    }
}

export function payloadPppoeProfileUpdate(name,
    price,
    profileDiscount,
    rateIn,
    rateOut,
    pool,
    ip,
    subnet,
    rateInMeasurement,
    rateOutMeasurement,
    description) {
    return {
    name,
    price,
    profileDiscount,
    rateIn,
    rateOut,
    pool,
    ip,
    subnet,
    rateInMeasurement,
    rateOutMeasurement,
    description
    }
}

export function payloadPppoeProfileTrash(trash) {
    return {
        trash
    }
}
